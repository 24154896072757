import { Field } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './Styles.module.scss';
import { DefaultCheckBox } from '../../../components';

const AssetForm = () => {
  return (
    <>
      <Row className="mt-2">
        <Col md={12}>
          <Field name="balance[taxable]">
            {({ field }) => (
              <DefaultCheckBox
                label="Imponible"
                {...field}
                field={field}
                customDivClass="mb-2"
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[tributable]">
            {({ field }) => (
              <DefaultCheckBox
                label="Tributable"
                {...field}
                field={field}
                customDivClass="mb-2"
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[contractual]">
            {({ field }) => (
              <DefaultCheckBox
                label="Contractual"
                {...field}
                field={field}
                customDivClass="mb-2"
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[proportional]">
            {({ field }) => (
              <DefaultCheckBox
                label="Proporcional"
                {...field}
                field={field}
                customDivClass="mb-2"
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[gratificationBase]">
            {({ field }) => (
              <DefaultCheckBox
                label="Base de Gratificación"
                {...field}
                field={field}
                customDivClass="mb-2"
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[overtimeBase]">
            {({ field }) => (
              <DefaultCheckBox
                label="Base de Sobretiempo"
                {...field}
                field={field}
                customDivClass="mb-2"
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[runningWeek]">
            {({ field }) => (
              <DefaultCheckBox
                label="Semana Corrida"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[printOnLiquidation]">
            {({ field }) => (
              <DefaultCheckBox
                label="Se imprime en liquidación"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[reliquidation]">
            {({ field }) => (
              <DefaultCheckBox
                label="Reliquidación"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[assetVariable]">
            {({ field }) => (
              <DefaultCheckBox
                label="Haber Variable"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[royalties]">
            {({ field }) => (
              <DefaultCheckBox
                label="Regalías en especie"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[settlementConsideration]">
            {({ field }) => (
              <DefaultCheckBox
                label="Aplica en finiquito"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default AssetForm;
