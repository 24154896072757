import React from 'react';
import memoize from 'memoize-one';
import { Icon, ButtonTooltip } from '../../../components';

const listActions = (item, clickHandler) => (
  <>
    <ButtonTooltip
      onClick={() => clickHandler(item, 'show')}
      variant="circle-info"
      className="btn-circle"
      size="sm"
      text="Ver"
    >
      <Icon icon="eye" />
    </ButtonTooltip>
    <ButtonTooltip
      onClick={() => clickHandler(item, 'destroy')}
      variant="circle-danger"
      className="btn-circle"
      size="sm"
      text="Eliminar"
    >
      <Icon icon="trash" />
    </ButtonTooltip>
  </>
);

const columns = memoize(clickHandler => [
  {
    name: 'Fecha de Solicitud',
    selector: row => row.request_date || '29/01/2025',
    sortable: true,
    center: true
  },
  {
    name: 'Fecha Inicio',
    selector: row => row.start_date || '02/02/2025',
    sortable: true,
    center: true
  },
  {
    name: 'Fecha Término',
    selector: row => row.end_date || '05/02/2025',
    sortable: true,
    center: true
  },
  {
    name: 'Fecha Retorno',
    selector: row => row.return_date || '06/02/2025',
    sortable: true,
    center: true
  },
  {
    name: 'Días',
    selector: row => row.days_taken || '3',
    sortable: true,
    center: true
  },
  {
    name: 'Horas Extras',
    selector: row => row.extra_hours_taken || '8',
    sortable: true,
    center: true
  },
  {
    name: 'Estado de la solicitud',
    selector: row => row.status || 'Pendiente',
    center: true
  },
  {
    name: 'Acciones',
    cell: item => listActions(item, clickHandler),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    minWidth: '200px'
  }
]);

export default columns;
