import * as Yup from 'yup';
import '../../../services/yupCustomMethods';

const validateCode = function validateCode(code) {
  if (this.parent?.preloaded) return true;
  const prefixLength = this?.parent?.prefix?.length || 0;
  const codeLength = code?.toString().length || 0;
  return codeLength + prefixLength <= 10;
};

const validationSchema = Yup.object().shape({
  balance: Yup.object().shape({
    balanceType: Yup.string().required('Debes ingresar Tipo de Concepto'),
    prefix: Yup.string().required('Debes seleccionar un prefijo para el concepto'),
    code: Yup.string()
      .when('preloaded', {
        is: true,
        then: Yup.string().required('Debes ingresar un código'),
        otherwise: Yup.string()
          .matches(/^[a0-9\s]+$/, 'Solo se permiten numeros')
          .required('Debes ingresar un código')
      })
      .test('lessThan10Digits', 'Debe ser menor a 10 dígitos', validateCode),
    name: Yup.string()
      .required('Debes ingresar una Descripción')
      .max(50, 'Deben ser como máximo 50 caracteres'),
    ineCode: Yup.string().when('balanceType', {
      is: val => ['asset'].includes(val),
      then: Yup.string().required('Debes seleccionar la categoria INE'),
      otherwise: Yup.string().nullable()
    }),
    observation: Yup.string()
      .max(100, 'Deben ser como máximo 100 caracteres')
      .nullable(true),
    calculationOrder: Yup.number().when('balanceType', {
      is: val => ['asset', 'discount'].includes(val),
      then: Yup.number()
        .min(1, 'Debe ser mayor o igual a 1')
        .max(999, 'Deben ser menor o igual a 999')
        .required('Debes ingresa Orden en la liquidación'),
      otherwise: Yup.number().nullable()
    })
  })
});

export default validationSchema;
