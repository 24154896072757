import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../../components';

const MassiveEmployeeActions = memoize(({ handleClick, onlyShow, disableDelete }) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push({
      icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'massive_export',
      props: {
        onClick: () => handleClick('massive_export')
      },
      title: 'Exportar'
    });

    if (!onlyShow) {
      authorizedActions.push({
        icon: <Icon icon="refresh" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'massive_process',
        props: {
          onClick: () => handleClick('massive_process')
        },
        title: 'Procesar Masivamente'
      });

      authorizedActions.push({
        icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'massive_delete',
        props: {
          onClick: () => handleClick('massive_delete'),
          disabled: disableDelete
        },
        title: 'Eliminar'
      });
    }

    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
});

export default MassiveEmployeeActions;
