import React from 'react';
import memoize from 'memoize-one';
import { Row } from 'react-bootstrap';
import { BasicDropdown, ButtonTooltip, Icon } from '../../components';

const massiveActions = memoize((ability, handleImportExportClick, handleClick, permissions, disabled) => {
  const actions = () => {
    const authorizedActions = [];

    if (permissions.destroy) {
      authorizedActions.push({
        icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'destroy',
        props: {
          onClick: () => handleClick('massive_destroy'),
          disabled
        },
        title: 'Eliminar'
      });
    }
    if (permissions.update) {
      authorizedActions.push({
        icon: <Icon icon="checkmark-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'massive_active',
        props: {
          onClick: () => handleClick('massive_active'),
          disabled
        },
        title: 'Activar'
      });
    }
    if (permissions.update) {
      authorizedActions.push({
        icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'massive_deactivate',
        props: {
          onClick: () => handleClick('massive_deactivate'),
          disabled
        },
        title: 'Desactivar'
      });
    }
    return authorizedActions;
  };

  return (
    <Row className="mb-4 ml-0">
      <div className="mr-2 d-flex" style={{ gap: '0.2rem' }}>
        <ButtonTooltip
          variant="circle-primary"
          className="mr-2 mb-3 btn-circle"
          text="Exportar"
          onClick={() => handleImportExportClick('balance_download')}
        >
          <Icon icon="download-2" />
        </ButtonTooltip>
        {ability.can('create_concept', 'Balance') && (
          <ButtonTooltip
            variant="circle-primary"
            className="mr-2 mb-3 btn-circle"
            text="Importar"
            onClick={() => handleImportExportClick('balance_import')}
          >
            <Icon icon="cloud-upload-outline" />
          </ButtonTooltip>
        )}
      </div>
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </Row>
  );
});

export default massiveActions;
