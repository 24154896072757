import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { approveAdvancePaymentRequest } from '../../../requests/advancePayments';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import AdvancePaymentForm from './AdvancePaymentForm';

const basicAdvancePayment = {
  id: '',
  payday: '',
  permanent: false,
  reason: ''
};

const AdvancePaymentEdit = ({ item, ...props }) => {
  const { cancelAction, employee, handleSuccessAction, reviewerSignature } = props;
  const [advancePayment, setAdvancePayment] = useState(basicAdvancePayment);
  const dispatch = useDispatch();

  const { id, endDate, parsedAmount, payday, permanent, reason, startDate } = camelCaseEmptyStringRecursive(item);

  const handleUpdateRequest = (params, setSubmitting) => {
    const sendParams = { advancePayment: { ...params.advancePayment, id } };
    approveAdvancePaymentRequest(id, {
      dispatch,
      params: snakeCaseKeys(sendParams),
      formData: true,
      successCallback: () => handleSuccessAction('Solicitud aprobada con éxito'),
      callback: () => {
        setSubmitting(false);
        cancelAction(false);
      }
    });
  };

  const updateAdvancePayment = () => {
    setAdvancePayment({ ...advancePayment, permanent, payday });
  };

  useEffect(updateAdvancePayment, [item]);

  return (
    <>
      <div className="info-box">
        <p className="info-md">
          <span>Monto:</span> {parsedAmount}
        </p>
        <p className="info-md">
          <span>Inicio:</span> {startDate}
        </p>
        <p className="info-md">
          <span>Término:</span> {endDate}
        </p>
        {reason && (
          <p className="info-md">
            <span>Motivo:</span> {reason}
          </p>
        )}
        {employee && (
          <>
            <p className="info-md">
              <span>Día de Pago:</span> {payday || 'Contacta a RRHH para solicitar un día de pago.'}
            </p>
            <p className="info-md">
              <span>Permanente:</span> {permanent ? 'Sí' : 'No'}
            </p>
          </>
        )}
      </div>
      asdfa sdfas dfas df
      <Row>
        <Col className="mt-3">
          <AdvancePaymentForm
            action="edit"
            employee={employee}
            advancePayment={advancePayment}
            reviewerSignature={reviewerSignature}
            formRequest={handleUpdateRequest}
            cancelAction={cancelAction}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdvancePaymentEdit;
