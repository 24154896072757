import React from 'react';
import DOMPurify from 'dompurify';
import './styles.scss';

const selectedStyle = {
  backgroundColor: '#FF0000',
  color: '#FFFFFF'
};

const styleArticle22 = {
  backgroundColor: '#FFA07A',
  color: '#515151'
};

const styleNotWorking = {
  backgroundColor: '#F3C342',
  color: '#515151'
};

const styleHasReplaced = {
  backgroundColor: '#B0E0E6',
  color: '#515151'
};

const tableHeader = () => {
  return [
    'Fecha',
    'Jornada Pactada',
    'Marcaciones Jornada',
    'Colación',
    'Marcaciones Colación',
    'Tiempo Faltante',
    'Tiempo Extra',
    'Otras Marcaciones',
    'Observaciones',
    'Autorización Jornada'
  ];
};

const appendValueIfPresent = (value, append) => {
  return value.length > 0 ? `${append} ${value}` : value;
};

const renderResult = (isArticle22, attendance, employeeId) => {
  let styles = null;
  if (attendance.non_working_day_assis) {
    styles = styleNotWorking;
  }
  if (attendance.attendance_incomplete) {
    styles = selectedStyle;
  }
  if (isArticle22) {
    styles = styleArticle22;
  }
  if (attendance.has_replaced || (employeeId === 2570 && attendance.date_formatted === '17/05/23')) {
    styles = styleHasReplaced;
  }

  const renderObservation = observation => (
    <div dangerouslySetInnerHTML={{ __html: observation.replace(/\n/g, '<br>') }} />
  );

  const renderAttendnaceActivities = activities => (
    <div dangerouslySetInnerHTML={{ __html: activities.replace(/\n/g, '<br>') }} />
  );

  return (
    <tr style={styles}>
      <td className="text-center align-middle">{attendance.date_formatted}</td>
      <td className="text-center align-middle">{attendance.shift}</td>
      <td className="text-center align-middle">{attendance.attendance_day}</td>
      <td className="text-center align-middle">{attendance.shift_break}</td>
      <td className="text-center align-middle">{attendance.attendance_break}</td>
      {
        <td
          className="text-center align-middle"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(attendance.anomalies) }}
        />
      }
      <td className="text-center align-middle">{appendValueIfPresent(attendance.hours_extra, '+')}</td>
      <td className="text-center align-middle">{renderAttendnaceActivities(attendance.attendnace_activities)}</td>
      <td className="text-center align-middle">
        {attendance?.document && <>Pacto de Horas Extra</>}
        {renderObservation(attendance.observation)}
      </td>
      <td className="text-center align-middle">{attendance.exeptional_workday_number}</td>
    </tr>
  );
};

const WorkdayReportToPrint = React.forwardRef(({ data }, ref) => {
  return (
    <div className="print_report" ref={ref}>
      {data.length > 0 && (
        <>
          <p>Reporte de Jornada Diaria</p>
          <p>Empresa: {data[0].company_name}</p>
          <p>RUT: {data[0].company_national_id}</p>
          <p>
            Rango seleccionado de fechas: {data[0].date_from} - {data[0].date_to}
          </p>
          {data.map(employee => (
            <>
              <p style={{ marginTop: '12px' }}>{employee.full_name}</p>
              <p>RUT: {employee.national_identification}</p>
              <p>Lugar de Prestación de Servicio: {employee.branch_offices_name}</p>
              <p>Banda Horaria: {employee.is_article_27}</p>
              <table className="table-print table-sm table-bordered">
                <tbody>
                  <tr style={{ backgroundColor: '#EBEBEB' }}>
                    {tableHeader().map(itemHeader => (
                      <th className="text-center align-middle" key={itemHeader}>
                        {itemHeader}
                      </th>
                    ))}
                  </tr>
                  {employee.attendances.map(attendance => (
                    <>
                      {attendance.attendances.map(attendances =>
                        renderResult(employee.is_article_22, attendances, employee.employee_id)
                      )}
                      <tr style={{ backgroundColor: '#EBEBEB' }}>
                        <td className="text-center align-middle"> Totales Semanales </td>
                        <td className="text-center align-middle"> {attendance.total_times[0].parsed_shift_time} </td>
                        <td className="text-center align-middle">
                          {attendance.total_times[0].parsed_attendance_day_t}
                        </td>
                        <td className="text-center align-middle">
                          {attendance.total_times[0].parsed_shift_break_time}
                        </td>
                        <td className="text-center align-middle"> {attendance.total_times[0].p_attendance_break_t} </td>
                        <td className="text-center align-middle">
                          {' '}
                          {appendValueIfPresent(attendance.total_times[0].p_anomalies_time, '-')}{' '}
                        </td>
                        <td className="text-center align-middle">
                          {' '}
                          {appendValueIfPresent(attendance.total_times[0].p_hours_extra_time, '+')}{' '}
                        </td>
                        <td className="text-center align-middle">&nbsp;</td>
                        <td className="text-center align-middle" colSpan={2}>
                          Compensación: {attendance.total_times[0].compensation}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </>
          ))}
        </>
      )}
    </div>
  );
});

export default WorkdayReportToPrint;
