import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DirectoryNodeIndex from '../../Shared/DirectoryNode/DirectoryNodeIndex';
import EmployeeFilters from './EmployeeFilters';
import { searchEmployeeByRutRequest } from '../../../requests/employees';

const DtDirectoryIndex = () => {
  const [employee, setEmployee] = useState();
  const dispatch = useDispatch();

  const handleEmployee = value => {
    setEmployee();
    if (value.employeeId) {
      setEmployee(value.employeeId);
    }

    if (value.nationalId) {
      searchEmployeeByRutRequest({
        dispatch,
        params: { national_identification: value.nationalId.replace(/[^0-9kK]/g, '') },
        successCallback: response => {
          const { data } = response;
          setEmployee(data.id);
        }
      });
    }
  };

  return (
    <>
      <Row className="mt-3 mb-3">
        <Col xs={12}>
          <EmployeeFilters formRequest={handleEmployee} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12} className="mb-3">
          {employee && <DirectoryNodeIndex employeeId={employee} rrhh={false} />}
        </Col>
      </Row>
    </>
  );
};

export default DtDirectoryIndex;
