import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { downloadFile } from '../../services/utils';
import { legalAttendanceProofReport } from '../../requests/legalreports';

const columnsHead = ['NOMBRE DEL TRABAJADOR', 'RUT', 'FECHA', 'HORA', 'TIPO', 'MODALIDAD'];

const cellStyle = {
  padding: '5px 15px',
  border: '2px solid black',
  fontSize: '13px',
  lineHeight: 'normal',
  verticalAlign: 'top',
  textAlign: 'left',
  width: '50%'
};

const generalStyle = {
  width: '100%',
  fontFamily: 'Roboto, sans-serif',
  color: 'rgb(48, 48, 48)',
  marginBottom: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'flex-start',
  alignItems: 'center'
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '15px',
  marginTop: '20px'
};

const AttendanceProof = ({ data }) => {
  const [onRequest, setOnRequest] = useState(false);
  const componentRef = useRef();
  const dispatch = useDispatch();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setOnRequest(false)
  });

  const isDownloableFile = format => {
    return ['.xlsx', '.docx', '.pdf'].includes(format);
  };

  const handleReportRequest = async (format, type = 'json') => {
    try {
      setOnRequest(true);
      const responseType = type;
      legalAttendanceProofReport({
        dispatch,
        params: { filter_by_hash: data.hash_code, format },
        format,
        responseType,
        successCallback: response => {
          if (isDownloableFile(format)) {
            downloadFile(response);
          }
        },
        callback: setOnRequest(false)
      });
    } catch (error) {
      console.error('Error al manejar el reporte:', error);
    } finally {
      setOnRequest(false);
    }
  };

  return (
    <div style={generalStyle}>
      {/* Contenido a imprimir */}
      <div ref={componentRef} style={{ padding: '20px', textAlign: 'center', width: '90%' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%', marginBottom: '20px' }}>
          <thead>
            <tr style={{ backgroundColor: '#20c997', color: 'white' }}>
              {columnsHead.map((name, index) => {
                let borderRadius = '0';

                if (index === 0) {
                  borderRadius = '10px 0 0 0';
                } else if (index === columnsHead.length - 1) {
                  borderRadius = '0 10px 0 0';
                }

                return (
                  <th
                    key={name}
                    style={{
                      padding: '10px 15px',
                      borderRadius,
                      textAlign: 'center'
                    }}
                  >
                    {name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center' }}>{data.full_name}</td>
              <td style={{ textAlign: 'center' }}>{data.employee_rut}</td>
              <td style={{ textAlign: 'center' }}>{data.attendance_date}</td>
              <td style={{ textAlign: 'center' }}>{data.attendance_time}</td>
              <td style={{ textAlign: 'center' }}>{data.time_type}</td>
              <td style={{ textAlign: 'center' }}>{data.parsed_marking_type || 'No especificado'}</td>
            </tr>
          </tbody>
        </table>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h1 style={{ fontSize: '17px' }}>
            <strong>Comprobante Marcación de Asistencia</strong>
          </h1>
          <h2 style={{ fontSize: '17px' }}>
            <strong>Registro: {data.time_type}</strong>
          </h2>
        </div>

        {/* Employee Details */}
        <p style={{ fontSize: '12px', marginBottom: '5px', textAlign: 'left', width: '100%' }}>
          Estimado(a) Sr(a). {data.full_name}, se ha registrado una marcación con los siguientes datos:
        </p>
        <hr />
        <div style={{ alignSelf: 'flex-start', textAlign: 'left', marginLeft: '78px' }}>
          <h3 style={{ fontSize: '12px', marginBottom: '5px' }}>
            <strong>Datos del Trabajador</strong>
          </h3>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <td style={cellStyle}>Fecha de Marcación</td>
              <td style={cellStyle}>{data.attendance_date}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Hora de Marcación</td>
              <td style={cellStyle}>{data.attendance_time}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Nombre del Trabajador</td>
              <td style={cellStyle}>{data.full_name}</td>
            </tr>
            <tr>
              <td style={cellStyle}>RUT</td>
              <td style={cellStyle}>{data.employee_rut}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Trabajador de empresas de servicios transitorios o subcontratado</td>
              <td style={cellStyle}>{data.employee_out_sourced}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Afecto sistema excepcional (Nro. Resolución)</td>
              <td style={cellStyle}>{data.out_source_exceptional_system}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Geolocalización o Geoposición</td>
              <td style={cellStyle}>
                {data.latitude && data.longitude ? (
                  <>
                    <p>Latitud: {data.latitude}</p>
                    <p>Longitud: {data.longitude}</p>
                    <p>Dirección: {data.address}</p>
                  </>
                ) : (
                  <p>No aplica</p>
                )}
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>Tipo de Marcación</td>
              <td style={cellStyle}>{data.parsed_marking_type || 'No especificado'}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Código Hash</td>
              <td style={cellStyle}>{data.hash_code}</td>
            </tr>
          </tbody>
        </table>

        {/* Employer Details */}
        <div style={{ alignSelf: 'flex-start', textAlign: 'left', marginLeft: '78px' }}>
          <h3 style={{ fontSize: '15px' }}>
            <strong>Datos del Empleador</strong>
          </h3>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <td style={cellStyle}>Razón Social / Nombre Completo</td>
              <td style={cellStyle}>{data.company_business_name}</td>
            </tr>
            <tr>
              <td style={cellStyle}>RUT</td>
              <td style={cellStyle}>{data.company_rut}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Ubicación</td>
              <td style={cellStyle}>{data.branch_office_full_addres}</td>
            </tr>
            {data.employee_out_sourced === 'SI' && (
              <>
                <tr>
                  <td style={cellStyle}>Nombre Razón Social Empresa Servicios Transitorios</td>
                  <td style={cellStyle}>{data.out_source_business_name}</td>
                </tr>
                <tr>
                  <td style={cellStyle}>RUT Empresa Servicios Transitorios</td>
                  <td style={cellStyle}>{data.out_source_rut}</td>
                </tr>
                <tr>
                  <td style={cellStyle}>Domicilio</td>
                  <td style={cellStyle}>
                    {data.latitude && data.longitude ? (
                      <>
                        <p>Latitud: {data.latitude}</p>
                        <p>Longitud: {data.longitude}</p>
                        <p>Dirección: {data.address}</p>
                      </>
                    ) : (
                      <p>No aplica</p>
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      {/* BOTONES DESCARGAR E IMPRIMIR */}
      <div style={buttonContainerStyle}>
        <Button
          variant="success"
          style={{ backgroundColor: 'rgb(32, 201, 151)', borderColor: 'rgb(32, 201, 151)' }}
          onClick={() => handleReportRequest('.pdf')}
          disabled={onRequest}
        >
          Descargar PDF
        </Button>
        <Button onClick={handlePrint} variant="default">
          Imprimir
        </Button>
      </div>
    </div>
  );
};

export default AttendanceProof;
