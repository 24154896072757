import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Row, Col, Image, Spinner, InputGroup, FormControl, Card } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';

import { validateToken, requestCurrentCompany } from '../../../actions/auth';
import { sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import { debounceCompaniesRequest, selectCompanyRequest } from '../../../requests/auth';
import { delayMethod } from '../../../services/utils';
import Icon from '../../Icons/Icon';
import LinkBtn from '../Button/LinkBtn';
import { SimpleCenteredModal } from '../Modal';
import CompanyChildrensModal from './CompanyChildrensModal';

const CompaniesModalDt = ({ fromSidebar, handleModalClose, handleModalSize }) => {
  const ability = useAbility(AbilityContext);
  const { user, currentCompany } = useSelector(state => state.auth);
  const [companies, setCompanies] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();
  const handleActiveModalClose = () => {
    if (handleModalClose) delayMethod(handleModalClose());
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessGetCompanies = response => {
    const { data } = response.data;
    setCompanies(camelCaseRecursive(data));
    setOnRequest(false);
  };

  const getCompanies = params => {
    const queryParams =
      user.role === 'dt'
        ? {
            ...params,
            sort_column: 'business_name',
            sort_direction: 'asc',
            filter_parent_ids: 'all'
          }
        : params;
    setOnRequest(true);
    debounceCompaniesRequest({
      dispatch,
      params: queryParams,
      successCallback: handleSuccessGetCompanies,
      failureCallback: handleFailureRequest
    });
  };

  const SelectCurrentCompanyChildrenIndex = company => {
    setModalTitle('Selecciona una empresa');
    setModalBody(<CompanyChildrensModal company={company} childrens={company.childrensInfo} />);
    setModalShow(true);
  };

  const handleSuccessSelectCompany = company => {
    handleActiveModalClose();
    if (!company && user.role === 'admin') {
      window.location.replace('/admin/companies');
    } else if (user.role === 'dt') {
      if (company.childrensInfo.length > 0) {
        SelectCurrentCompanyChildrenIndex(company);
      } else {
        window.location.replace('/dt/welcome');
      }
    } else {
      window.location.replace('/profile/dashboard');
    }
  };

  const selectCompany = company => {
    if (company && company.childrensInfo.length > 0 && user.role === 'dt') {
      SelectCurrentCompanyChildrenIndex(company);
      return;
    }
    selectCompanyRequest({
      dispatch,
      params: { current_company_id: company ? company.id : '' },
      successCallback: () => handleSuccessSelectCompany(company),
      failureCallback: handleFailureRequest
    });
  };

  const userData = () => {
    handleModalSize('lg');
    if (user.id === 0) dispatch(validateToken());
    if (currentCompany.id !== '') {
      dispatch(requestCurrentCompany({ id: currentCompany.id }));
    }

    if (user.role === 'dt') {
      getCompanies({
        sort_column: 'business_name',
        sort_direction: 'asc',
        filter_parent_ids: 'all'
      });
    } else {
      getCompanies({ sort_column: 'business_name', sort_direction: 'asc' });
    }
  };

  useEffect(userData, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="justify-content-center">
        <Col md={6}>
          <Card style={{ background: '#fff' }}>
            <Card.Body>
              <Card.Title>Buscar por Razón Social o Rut</Card.Title>
              <Card.Text>
                <InputGroup className="mt-3">
                  <FormControl
                    placeholder="Buscar por Razón Social o Rut"
                    name="companyName"
                    value={companyName}
                    onChange={e => {
                      setCompanyName(e.target.value);
                      getCompanies({ select_filtered: e.target.value });
                    }}
                  />
                </InputGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ background: '#fff' }}>
            <Card.Body>
              <Card.Title>Selecciona una empresa</Card.Title>
              <Card.Text>
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <ListGroup variant="flush" className="mb-3">
                    {companies.map(company => (
                      <ListGroup.Item
                        key={company.id}
                        className={company.id.toString() === currentCompany.id ? 'active' : ''}
                        onClick={() => selectCompany(company)}
                      >
                        <div className="d-flex align-items-center">
                          {company.businessLogo?.fileUrl ? (
                            <Image className="round-10" fluid width="38px" src={company.businessLogo.fileUrl} />
                          ) : (
                            <Icon icon="camera" width="38px" />
                          )}
                          <div className="d-flex flex-column ms-2 ml-1">
                            <span className="text-muted">
                              {company.businessName} - {company.nationalIdentification}
                            </span>
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        {user.role === 'admin' && (
          <Col md={4}>
            <LinkBtn block variant="primary" to="/admin/companies" onClick={() => selectCompany()}>
              Soporte
            </LinkBtn>
          </Col>
        )}
        {fromSidebar && currentCompany?.id && ability.can('create', 'Company') && (
          <Col md={4}>
            <LinkBtn block variant="success" to="/companies/new" onClick={handleActiveModalClose}>
              Nueva Empresa
            </LinkBtn>
          </Col>
        )}
      </Row>
      <SimpleCenteredModal
        title={modalTitle}
        size="md"
        closeButton={false}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default CompaniesModalDt;
