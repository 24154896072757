import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../../actions/utils';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../../components';
import {
  deleteOvertimeCompensationRequest,
  fetchOvertimeCompensationsRequest
} from '../../../requests/overtimeCompensations';

const OvertimeCompensationDatatable = ({
  columns,
  preName,
  moreData,
  setMoreData,
  defaultStartDate = null,
  defaultEndDate = null,
  withMassActions,
  currentEmployee
}) => {
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [compensationRequests, setCompensationRequests] = useState([]);

  const dispatch = useDispatch();

  const handleRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      filter_employee: currentEmployee
    };
    fetchOvertimeCompensationsRequest({
      dispatch,
      params: sendParams,
      successCallback: response => {
        const { data, metadata } = response.data;
        setCompensationRequests(data);
        setAmount(metadata.amount);
        setOnRequest(false);
      },
      failureCallback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
    setModalBody('');
    setModalTitle('');
    setOnRequest(false);
  };

  const removeCompensationRequest = id => {
    deleteOvertimeCompensationRequest({
      dispatch,
      id,
      successCallback: () => {
        sendAlert({ kind: 'success', message: 'Solicitud eliminada exitosamente' });
        setMoreData(!moreData);
      },
      failureCallback: error => {
        sendAlert({ kind: 'error', message: 'Error al eliminar la solicitud' });
        console.error(error);
      }
    });
  };

  const handleActions = (item, action) => {
    setModalItem(item);
    switch (action) {
      case 'show':
        setModalTitle('Detalle de la Solicitud');
        // setModalBody(<OvertimeCompensationShow item={item} />);
        setCenterModalShow(true);
        break;
      case 'destroy':
        setModalTitle('Eliminar Solicitud');
        setModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
        setModalShow(true);
        setModalAction(() => removeCompensationRequest);
        break;
      default:
        console.log('Acción no encontrada:', action);
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee_id':
        return { sort_column: 'employee_id' };
      case 'extra_hours_generated':
        return { sort_column: 'extra_hours_generated' };
      case 'generated_remuneration_period':
        return { sort_column: 'generated_remuneration_period' };
      case 'expiration_remuneration_period':
        return { sort_column: 'expiration_remuneration_period' };
      case 'remaining_extra_hours':
        return { sort_column: 'remaining_extra_hours' };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={columns(handleActions)}
        defaultEndDate={defaultEndDate}
        defaultStartDate={defaultStartDate}
        data={compensationRequests}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        onRequest={onRequest}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        preName={preName || 'compensation'}
        rangePicker
        resourceRequest={handleRequest}
        selectableRows={withMassActions}
        totalRows={amount}
        withStartDate
        withEndDate
        withMassActions={withMassActions}
      />
      <SimpleCenteredModal
        title={modalTitle}
        size="md"
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose
        withConfirm
      />
    </>
  );
};

export default OvertimeCompensationDatatable;
