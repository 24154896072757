const listedSettings = [
  {
    text: 'Habilitar solicitud de permisos con goce de sueldo',
    name: 'paid_leave',
    tooltipText: 'Se mantiene el sueldo durante el período de permiso',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de permisos sin goce de sueldo',
    name: 'without_paid_leave',
    tooltipText: 'Se descuenta del sueldo el proporcional al período del permiso',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de anticipo',
    name: 'advance_payment_request',
    tooltipText: 'Habilita la opción para la generación de solicitud de anticipo por parte de los trabajadores',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de préstamo',
    name: 'loan_request',
    tooltipText: 'Habilitar solicitud de préstamo',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de días administrativos',
    name: 'administrative_days',
    tooltipText: 'Habilita la opción para la generación de días administrativos por parte de los trabajadores',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de feriado legal',
    name: 'legal_holiday',
    tooltipText: 'Habilita la solicitud de vacaciones por parte de los trabajadores',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de feriado progresivo',
    name: 'progressive_holiday',
    tooltipText: 'Habilita la solicitud de feriado progresivo por parte de los trabajadores',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de días adicionales',
    name: 'additional_days',
    tooltipText: 'Habilitar solicitud de días adicionales',
    inputType: 'toggle'
  },
  {
    text: 'Notificar licencias médicas a jefatura',
    name: 'notify_medical_licenses',
    tooltipText:
      'Habilita el envío de una notificación a las jefaturas cuando un trabajador de su equipo presente una licencia médica',
    inputType: 'toggle'
  },
  {
    text: 'Notificar ausencias a jefatura',
    name: 'notify_absences',
    tooltipText:
      'Habilita el envío de una notificación a las jefaturas cuando un trabajador de su equipo registre ausencia',
    inputType: 'toggle'
  },
  {
    text: 'Notificar atrasos a jefatura',
    name: 'notify_arrears',
    tooltipText:
      'Habilita el envío de una notificación a las jefaturas cuando un trabajador de su equipo registre atrasos',
    inputType: 'toggle'
  },
  {
    text: 'Notificación con correo electrónico',
    name: 'notification_with_email',
    tooltipText:
      'Habilita el envío de un correo electrónico a la cuenta de e-mail laboral, adicional a la notificación en el sistema',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de cambio de turno',
    name: 'shift_change',
    tooltipText: 'Habilita la solicitud de cambio de turno por parte de los trabajadores',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar firma automática representante legal',
    name: 'representant_automatic_signature',
    tooltipText: 'Habilita la firma automática del representante legal',
    inputType: 'toggle'
  },

  {
    text: 'Metros de distancia aceptados para marcaje mobile',
    name: 'mobile_assistance_distance',
    tooltipText: 'Radio aceptado de marcaje en metros para la aplicación mobile',
    inputType: 'input'
  },
  {
    text: 'Días previos de aviso al vencimiento de contrato',
    name: 'expire_days',
    tooltipText: 'Cantidad de días previos para que el dashboard de recursos humanos muestre contratos por vencer.',
    inputType: 'input'
  },
  {
    text: 'Habilitar Envío de notificación de desconexión de teletrabajo',
    name: 'telecommuting',
    tooltipText:
      'Se enviará notificación al colaborador al momento de desconectarse estando en la modalidad de teletrabajo.',
    inputType: 'toggle'
  },
  {
    text: 'Notificacion de sobretiempo y salida anticipada - trabajador',
    name: 'notify_overtime_anticipated',
    tooltipText: 'Se enviará notificación al colaborador indicando si su salida es anticipada o con sobretiempo.',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar envío de correo al inicio y fin de jornada',
    name: 'mailing_late_arrival',
    tooltipText: 'Se enviará un correo cuando el colaborador no haya marcado a su hora pactada',
    inputType: 'toggle'
  },
  {
    text: 'Mostrar Recordatorio de firma en dashboard trabajador',
    name: 'notify_unsigned_document',
    tooltipText:
      'Se mostrará una pantalla para recordarle al colaborador que tiene documentos pendientes de firma/aprobación',
    inputType: 'toggle'
  },
  {
    text: 'Reporte Jornada Diaria - Contabilizar Tiempo Extra al Inicio Jornada”',
    name: 'workday_report_early_overtime_effective_date',
    tooltipText: 'Habilitar la contabilización de tiempo extra al inicio de la jornada en el reporte de jornada diaria',
    inputType: 'input'
  },
  {
    text: 'Habilitar Marcación de Actividades',
    name: 'activity_attendances',
    tooltipText: 'Habilitar marcación de asistencia de actividades',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar Solicitud de horas extras',
    name: 'overtime_requests',
    tooltipText: 'Habilita la solicitud de horas extras por parte de los trabajadores',
    inputType: 'toggle'
  },
  {
    text: 'Enviar Comprobante de  Marcación a Empresa Transitoria',
    name: 'mailing_transitional_company',
    tooltipText: 'Habilitar el envío del comprobante de marcación a la empresa transitoria',
    inputType: 'toggle'
  },
  {
    text: 'Habilitar solicitud de días de vacaciones por horas extras',
    name: 'overtime_compensation_vacation_request',
    tooltipText: 'Permite a los trabajadores solicitar días de vacaciones en base a horas extras acumuladas.',
    inputType: 'toggle'
  }
];

const selectorOptions = {
  limit_day_advance_payment: Array.from(Array(28), (e, i) => ({ label: i + 1, value: i + 1 })),
  advance_payment_payday: Array.from(Array(31), (e, i) => ({ label: i + 1, value: i + 1 })),
  advance_payment_percentage_limit: Array.from(Array(101), (e, i) => ({ label: i, value: i }))
};

export { listedSettings, selectorOptions };
