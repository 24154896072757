const API_CONFIG = {
  domain:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_PRODUCTION_URL
      : process.env.REACT_APP_API_STAGING_URL,
  version: 'v1',
  url: () => API_CONFIG.domain + API_CONFIG.version,
  urlPayroll: () => process.env.REACT_APP_API_PAYROLL_URL + API_CONFIG.version,
  urlControlClock: () => process.env.REACT_APP_API_CONTROL_CLOCK_URL + API_CONFIG.version,
  wsControlClock: () => process.env.REACT_APP_API_CONTROL_CLOCK_WS,
  globalTimeout: 15 * 1000,
  timeoutMessage: 'Está tardando demasiado, verifica tu conexión a internet e intenta nuevamente',
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  tokenDt: process.env.REACT_APP_TOKEN_DT
};

export default API_CONFIG;
