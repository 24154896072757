import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const SimpleCenteredModal = ({
  title,
  titleBtn,
  subtitle,
  body,
  bodyClass,
  headerClass,
  size,
  closeButton,
  showHeader,
  ...props
}) => (
  <Modal {...props} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
    {showHeader && (
      <Modal.Header className={headerClass || ''} closeButton={closeButton}>
        <Modal.Title>
          {title} {titleBtn && titleBtn}
          {subtitle && <h6 className="subtitle-simple-modal mb-0">{subtitle}</h6>}
        </Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body className={bodyClass || ''}>{body}</Modal.Body>
  </Modal>
);

SimpleCenteredModal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  closeButton: PropTypes.bool,
  showHeader: PropTypes.bool
};

SimpleCenteredModal.defaultProps = {
  title: null,
  size: 'lg',
  closeButton: true,
  showHeader: true
};

export default SimpleCenteredModal;
