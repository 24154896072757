import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAuthorization } from '../../../services/hooks';
import CustomFieldsInfo from './CustomFieldsInfo';
import FamilyGroupInfo from './FamilyGroupInfo';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';

const yesNo = value => {
  return value ? 'Sí' : 'No';
};

const BalanceShow = ({ balance }) => {
  return (
    <div className="info">
      <span className="type">{balance.balanceName}:</span>
      <span className="line" />
      <span className="answer">{balance.parsedAmount}</span>
    </div>
  );
};

const ContractInfoContent = ({ admin, contract, contractCustomFields }) => {
  const [activeKey, setKey] = useState('info');
  const {
    additionalHolidays,
    administrativeDays,
    bank,
    bankAccount,
    bankAccountType,
    branchOffices,
    subsidiary,
    contractBalancesAttributes,
    contractType,
    costCenter,
    disability,
    disabilityFileInfo,
    disabilityNationalIdentification,
    documentTemplate,
    endDate,
    familyGroupsAttributes,
    fullAddressCompleted,
    gratificationType,
    jacketSize,
    jobManagement,
    jobTitle,
    journeyDistribution,
    markingType,
    outSourced,
    outSourceBusinessName,
    outSourceEstablishmentName,
    outSourceExceptionalSystem,
    outSourceExceptionalSystemNumber,
    outSourceNationalIdentification,
    pantsSize,
    parent,
    parsedSalary,
    paymentSchedule,
    paymentType,
    presenceCheckAssistance,
    privateRole,
    progressiveFileInfo,
    recognizedMonths,
    recognizedMonthsFrom,
    shirtSize,
    shoesSize,
    startDate,
    syndicate,
    telecommuting,
    vacationCalculationArticle67,
    workScheduleArt27,
    translatedBankAccountType,
    translatedContractType,
    translatedForeignTechnician,
    translatedGratificationType,
    translatedGratificationPeriod,
    translatedGratificationCondition,
    translatedIncomeTaxType,
    translatedMarkingType,
    translatedPaymentSchedule,
    translatedPaymentType,
    translatedWorkSchedule,
    translatedDisability,
    tShirtSize,
    weeklyHours,
    workEndTime,
    workSchedule,
    workStartTime,
    dailyWorkingHours
  } = contract;

  const branchOfficeSummary = branchOffices.map(branchOffice => branchOffice.label);
  const assets = [];
  const discounts = [];
  const dispatch = useDispatch();
  const [shiftSummary, setShiftSummary] = useState([]);

  if (contractBalancesAttributes) {
    contractBalancesAttributes.forEach((balance, index) => {
      if (balance.balanceType === 'asset') {
        assets.push(<BalanceShow key={`balance_${index.toString()}`} balance={balance} />);
        return;
      }
      discounts.push(<BalanceShow key={`balance_${index.toString()}`} balance={balance} />);
    });
  }

  const canReadAsset = useAuthorization('read_asset', 'ContractBalance');
  const canReadDiscount = useAuthorization('read_discount', 'ContractBalance');
  const fetchActiveContractShift = () => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 40,
        filter_active_contract_shifts: contract.id
      },
      successCallback: response => {
        const shifts = response.data.data;
        setShiftSummary(shifts.map(shift => shift.label));
      }
    });
  };

  useEffect(fetchActiveContractShift, []);

  return (
    <>
      {contract.status === 'finished' && (
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>Término de Contrato</Card.Title>
            <div className="info">
              <span className="type">Fecha de término:</span>
              <span className="line" />
              <span className="answer">{contract.terminationDate}</span>
            </div>
            <div className="info">
              <span className="type">Razón de término:</span>
              <span className="line" />
              <span className="answer">{contract.translatedTerminationReason}</span>
            </div>
            {contract.noticeDate && (
              <div className="info">
                <span className="type">Fecha de aviso:</span>
                <span className="line" />
                <span className="answer">{contract.noticeDate}</span>
              </div>
            )}
            {contract.paymentDate && (
              <div className="info">
                <span className="type">Fecha de pago:</span>
                <span className="line" />
                <span className="answer">{contract.paymentDate}</span>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      <Card className="mt-4">
        <Col className={`${activeKey === 'info' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
          <Accordion.Toggle
            as={Col}
            eventKey="0"
            className="card-header-title"
            onClick={() => setKey('info')}
            style={{ textAlign: 'right' }}
          >
            INFORMACIÓN
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="div-content">
            <Row>
              <Col md={6} xs={12}>
                <Card.Title>Información personal</Card.Title>
                <div className="info">
                  <span className="type">Área:</span>
                  <span className="line" />
                  <span className="answer">{jobManagement?.label}</span>
                </div>
                {branchOfficeSummary.length > 0 && (
                  <div className="info">
                    <span className="type">Lugar de Prestación de Servicios:</span>
                    <span className="line" />
                    <span className="answer">{branchOfficeSummary.join(', ')}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Sucursal:</span>
                  <span className="line" />
                  <span className="answer">{subsidiary?.label}</span>
                </div>
                <div className="info">
                  <span className="type">Centro de Costos:</span>
                  <span className="line" />
                  <span className="answer">{costCenter?.label}</span>
                </div>
                <div className="info">
                  <span className="type">Sindicato:</span>
                  <span className="line" />
                  <span className="answer">{syndicate?.label || 'No está sindicalizado'}</span>
                </div>
                {translatedIncomeTaxType && (
                  <div className="info">
                    <span className="type">Tipo de Impuesto a la Renta:</span>
                    <span className="line" />
                    <span className="answer">{translatedIncomeTaxType}</span>
                  </div>
                )}
                {translatedForeignTechnician && (
                  <div className="info">
                    <span className="type">Técnico Extranjero exención de cotizaciones previsionales:</span>
                    <span className="line" />
                    <span className="answer">{translatedForeignTechnician}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Cargo:</span>
                  <span className="line" />
                  <span className="answer">{jobTitle?.label}</span>
                </div>
                {parent?.label && (
                  <div className="info">
                    <span className="type">Jefe:</span>
                    <span className="line" />
                    <span className="answer">{parent?.label}</span>
                  </div>
                )}
                {contractType && (
                  <div className="info">
                    <span className="type">Tipo de Contrato:</span>
                    <span className="line" />
                    <span className="answer">{translatedContractType}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Plantilla Contrato:</span>
                  <span className="line" />
                  <span className="answer">{documentTemplate?.label}</span>
                </div>
                <div className="info">
                  <span className="type">Fecha de Inicio:</span>
                  <span className="line" />
                  <span className="answer">{startDate}</span>
                </div>
                {endDate && (
                  <div className="info">
                    <span className="type">Fecha de Término:</span>
                    <span className="line" />
                    <span className="answer">{endDate}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Discapacidad:</span>
                  <span className="line" />
                  <span className="answer">{translatedDisability}</span>
                </div>
                {disability && disability !== 'no_disability' && (
                  <div className="info">
                    <span className="type">Número de Credencial de Discapacidad:</span>
                    <span className="line" />
                    <span className="answer">{disabilityNationalIdentification}</span>
                  </div>
                )}
                {disability && disability !== 'no_disability' && disabilityFileInfo && (
                  <div className="info">
                    <span className="type">Certificado de Discapacidad:</span>
                    <span className="line" />
                    <span className="answer">
                      <a
                        target="_blank"
                        className="font-weight-bold underline"
                        rel="noopener noreferrer"
                        href={disabilityFileInfo?.fileUrl || ''}
                      >
                        {disabilityFileInfo?.filename}
                      </a>
                    </span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Rol Privado:</span>
                  <span className="line" />
                  <span className="answer">{privateRole ? 'Sí' : 'No'}</span>
                </div>

                <div className="info">
                  <span className="type">SubContratado:</span>
                  <span className="line" />
                  <span className="answer">{yesNo(outSourced)}</span>
                </div>
                {outSourced && (
                  <div className="info">
                    <span className="type">Rut de la empresa:</span>
                    <span className="line" />
                    <span className="answer">{outSourceNationalIdentification}</span>
                  </div>
                )}
                {outSourced && outSourceBusinessName && (
                  <div className="info">
                    <span className="type">Razón social de la empresa:</span>
                    <span className="line" />
                    <span className="answer">{outSourceBusinessName}</span>
                  </div>
                )}

                {outSourced && (
                  <div className="info">
                    <span className="type">Dirección:</span>
                    <span className="line" />
                    <span className="answer">{fullAddressCompleted}</span>
                  </div>
                )}

                {outSourced && outSourceEstablishmentName && (
                  <div className="info">
                    <span className="type">Nombre de establecimiento:</span>
                    <span className="line" />
                    <span className="answer">{outSourceEstablishmentName}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Afecto a un sistema excepcional:</span>
                  <span className="line" />
                  <span className="answer">{yesNo(outSourceExceptionalSystem)}</span>
                </div>
                {outSourceExceptionalSystem && (
                  <div className="info">
                    <span className="type">Número de sistema excepcional:</span>
                    <span className="line" />
                    <span className="answer">{outSourceExceptionalSystemNumber}</span>
                  </div>
                )}
              </Col>
              <Col>
                <Card.Title>Jornada</Card.Title>
                <div className="info">
                  <span className="type">Horas Semanales:</span>
                  <span className="line" />
                  <span className="answer">{weeklyHours}</span>
                </div>
                {workSchedule && (
                  <div className="info">
                    <span className="type">Jornada de Trabajo:</span>
                    <span className="line" />
                    <span className="answer">{translatedWorkSchedule}</span>
                  </div>
                )}
                {journeyDistribution && (
                  <div className="info">
                    <span className="type">Distribución de la Jornada:</span>
                    <span className="line" />
                    <span className="answer">{journeyDistribution}</span>
                  </div>
                )}
                {workStartTime && (
                  <div className="info">
                    <span className="type">Hora de Entrada:</span>
                    <span className="line" />
                    <span className="answer">{workStartTime}</span>
                  </div>
                )}
                {workEndTime && (
                  <div className="info">
                    <span className="type">Hora de Salida:</span>
                    <span className="line" />
                    <span className="answer">{workEndTime}</span>
                  </div>
                )}
                {markingType && (
                  <>
                    <div className="info">
                      <span className="type">Tipo de Marcaje:</span>
                      <span className="line" />
                      <span className="answer">{translatedMarkingType}</span>
                    </div>
                    <div className="info">
                      <span className="type">Marcaje Presencial</span>
                      <span className="line" />
                      <span className="answer">{presenceCheckAssistance ? 'Sí' : 'No'}</span>
                    </div>
                  </>
                )}
                {shiftSummary.length > 0 && (
                  <div className="info">
                    <span className="type">Turnos:</span>
                    <span className="line" />
                    <span className="answer">{shiftSummary.join(', ')}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Teletrabajo:</span>
                  <span className="line" />
                  <span className="answer">{telecommuting ? 'Sí' : 'No'}</span>
                </div>
                <div className="info">
                  <span className="type">Calculo de Vacaciones Art. 67:</span>
                  <span className="line" />
                  <span className="answer">{vacationCalculationArticle67 ? 'Sí' : 'No'}</span>
                </div>
                <div className="info">
                  <span className="type">Banda Horaria Art. 27:</span>
                  <span className="line" />
                  <span className="answer">{workScheduleArt27 ? 'Sí' : 'No'}</span>
                </div>
                {dailyWorkingHours && (
                  <div className="info">
                    <span className="type">Horas trabajadas al día:</span>
                    <span className="line" />
                    <span className="answer">{dailyWorkingHours}</span>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Col className={`${activeKey === 'salary' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
          <Accordion.Toggle
            as={Col}
            eventKey="1"
            className="card-header-title"
            onClick={() => setKey('salary')}
            style={{ textAlign: 'right' }}
          >
            SUELDO
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="div-content">
            <Row>
              <Col md={6} xs={12}>
                <Card.Title>SUELDO</Card.Title>
                {parsedSalary && (
                  <div className="info">
                    <span className="type">Sueldo Base:</span>
                    <span className="line" />
                    <span className="answer">{parsedSalary}</span>
                  </div>
                )}
                {paymentSchedule && (
                  <div className="info">
                    <span className="type">Tipo de Pago:</span>
                    <span className="line" />
                    <span className="answer">{translatedPaymentSchedule}</span>
                  </div>
                )}
                {paymentType && (
                  <div className="info">
                    <span className="type">Forma de Pago:</span>
                    <span className="line" />
                    <span className="answer">{translatedPaymentType}</span>
                  </div>
                )}
                {bank && (
                  <div className="info">
                    <span className="type">Banco para Pago:</span>
                    <span className="line" />
                    <span className="answer">{bank?.label}</span>
                  </div>
                )}
                {bankAccountType && (
                  <div className="info">
                    <span className="type">Tipo de Cuenta para depósito:</span>
                    <span className="line" />
                    <span className="answer">{translatedBankAccountType}</span>
                  </div>
                )}
                {bankAccount && (
                  <div className="info">
                    <span className="type">Cuenta para depósito:</span>
                    <span className="line" />
                    <span className="answer">{bankAccount}</span>
                  </div>
                )}
                {translatedGratificationType && (
                  <div className="info">
                    <span className="type">Tipo de Gratificación:</span>
                    <span className="line" />
                    <span className="answer">{translatedGratificationType}</span>
                  </div>
                )}
                {translatedGratificationPeriod && (
                  <div className="info">
                    <span className="type">Régimen de Gratificación:</span>
                    <span className="line" />
                    <span className="answer">{translatedGratificationPeriod}</span>
                  </div>
                )}
                {gratificationType === 'guaranteed' && translatedGratificationCondition && (
                  <div className="info">
                    <span className="type">Base de Cálculo:</span>
                    <span className="line" />
                    <span className="answer">{translatedGratificationCondition}</span>
                  </div>
                )}
              </Col>
              <Col>
                {canReadAsset && <Card.Title>Haberes</Card.Title>}
                {canReadAsset && assets}
                {canReadDiscount && <Card.Title>Descuentos</Card.Title>}
                {canReadAsset && discounts}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Col className={`${activeKey === 'benefits' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
          <Accordion.Toggle
            as={Col}
            eventKey="2"
            className="card-header-title"
            onClick={() => setKey('benefits')}
            style={{ textAlign: 'right' }}
          >
            BENEFICIOS
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="div-content">
            <Row>
              <Col md={6} xs={12}>
                <Card.Title>Beneficios</Card.Title>
                <div className="info">
                  <span className="type">Días Adicionales de Vacaciones:</span>
                  <span className="line" />
                  <span className="answer">{additionalHolidays}</span>
                </div>
                <div className="info">
                  <span className="type">Días Administrativos:</span>
                  <span className="line" />
                  <span className="answer">{administrativeDays}</span>
                </div>
                <div className="info">
                  <span className="type">Meses Reconocidos:</span>
                  <span className="line" />
                  <span className="answer">{recognizedMonths}</span>
                </div>
                <div className="info">
                  <span className="type">Meses Reconocidos Desde:</span>
                  <span className="line" />
                  <span className="answer">{recognizedMonthsFrom}</span>
                </div>
                {progressiveFileInfo && recognizedMonths && (
                  <div className="info">
                    <span className="type">Certificado de Vacaciones Progresivas:</span>
                    <span className="line" />
                    <span className="answer">
                      <a
                        target="_blank"
                        className="font-weight-bold underline"
                        rel="noopener noreferrer"
                        href={progressiveFileInfo?.fileUrl || ''}
                      >
                        {progressiveFileInfo?.filename}
                      </a>
                    </span>
                  </div>
                )}
              </Col>
              <Col>
                <Card.Title>Otros</Card.Title>
                <div className="info">
                  <span className="type">Talla Pantalón:</span>
                  <span className="line" />
                  <span className="answer">{pantsSize}</span>
                </div>
                <div className="info">
                  <span className="type">Talla Camisa:</span>
                  <span className="line" />
                  <span className="answer">{shirtSize}</span>
                </div>
                <div className="info">
                  <span className="type">Talla Polera:</span>
                  <span className="line" />
                  <span className="answer">{tShirtSize}</span>
                </div>
                <div className="info">
                  <span className="type">Talla Chaqueta:</span>
                  <span className="line" />
                  <span className="answer">{jacketSize}</span>
                </div>
                <div className="info">
                  <span className="type">Talla Zapatos:</span>
                  <span className="line" />
                  <span className="answer">{shoesSize}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <>
        {contractCustomFields && contractCustomFields.length > 0 && (
          <CustomFieldsInfo
            extraFieldsAttributes={contract.extraFieldsAttributes}
            customFields={contractCustomFields}
            contract={contract}
            activeAccordion={() => setKey('custom-fields')}
            activeKey={activeKey}
          />
        )}
      </>
      <>
        {(useAuthorization('read', 'FamilyGroup') || admin) && (
          <FamilyGroupInfo
            activeKey={activeKey}
            activeAccordion={() => setKey('family-group')}
            familyGroupsAttributes={familyGroupsAttributes}
          />
        )}
      </>
    </>
  );
};

export default ContractInfoContent;
