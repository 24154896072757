import React from 'react';
import '../PrintComponent/styles.scss';
import PrintFunctions from './PrintFunctions';

const AttendancesPrint = React.forwardRef(({ headers, rows = [], customStyles }, ref) => {
  const getCustomStyleByRow = (field, row) => {
    let objectStyle = {};
    if (field.conditionalCellStyles) {
      objectStyle = field.conditionalCellStyles.reduce(
        (acc, current) => (current.when(row) ? { ...current.style, ...acc } : acc),
        {}
      );
    }

    return {
      ...customStyles.cells.style,
      ...objectStyle
    };
  };

  const { getEmployeeInfo, getCompanyInfo } = PrintFunctions();

  return (
    <>
      <div className="print_report" ref={ref}>
        <p style={{ fontWeight: 'bold' }}>Reporte de Asistencias</p>
        {rows.length > 0 && (
          <>
            {getCompanyInfo(rows[0])}

            {rows.map(employee => (
              <>
                {getEmployeeInfo(employee)}
                <p>
                  <br />
                </p>

                <table className="table-print table-sm table-bordered">
                  <thead>
                    <tr style={{ backgroundColor: '#EBEBEB' }}>
                      {headers &&
                        headers.map(head => (
                          <th
                            width={`${head.grow * 100}px`}
                            style={{ ...head.style }}
                            className="text-center align-middle"
                            key={`${employee.employee_rut}${head.name}`}
                          >
                            {head.name}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {employee.records &&
                      employee.records.map(record => (
                        <tr style={customStyles.rows.style} key={`${employee.employee_name}${record.date}`}>
                          {headers.map(field => (
                            <td style={getCustomStyleByRow(field, record)}>{record[field.selector]}</td>
                          ))}
                        </tr>
                      ))}
                    {employee.records.length === 0 && (
                      <tr>
                        <td colSpan={4}>NO HAY TRABAJADORES QUE COINCIDAN CON LA SELECCIÓN.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            ))}
          </>
        )}
      </div>
    </>
  );
});

export default AttendancesPrint;
