import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { FormikTimePicker, FormikSelect } from '../../../components';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import {
  assetsDetailsReportRequest,
  discountDetailsReportRequest,
  employerContributionsRequest,
  generalSettlementReportRequest,
  processQuoteReportRequest,
  remunerationBookRequest,
  transactionsReportRequest
} from '../../../requests/payrollProcessesReports';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { payrollEmployeesRequest } from '../../../requests/payrolls';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const reports = [
  { label: 'Detalle de Haberes', value: 'assets_details', request: assetsDetailsReportRequest },
  { label: 'Detalle de Descuentos', value: 'discounts_details', request: discountDetailsReportRequest },
  { label: 'Reporte Aportes Empresa', value: 'employer_contributions', request: employerContributionsRequest },
  { label: 'Libro de Remuneraciones', value: 'remunerations_book', request: remunerationBookRequest },
  { label: 'Reporte Liquidacion General', value: 'accounting_accounts', request: generalSettlementReportRequest },
  { label: 'Reporte Cotizaciones', value: 'quote_details', request: processQuoteReportRequest },
  { label: 'Informe Transacciones', value: 'transactions', request: transactionsReportRequest }
  /* { label: 'Reporte Provision de Vacaciones', value: 'transactions', request: centralizationReportRequest }, */
  /* { label: 'Reporte Provision de IAS ', value: 'transactions', request: centralizationReportRequest }, */
  /* { label: 'Reporte de Finiquitos', value: 'settlements', request: centralizationReportRequest }, */
  /* { label: 'Centralizacion Contable', value: 'accounting_accounts', request: centralizationReportRequest } */
];

const reportTypes = [
  { label: 'Reporte Detallado', value: 'detailed_report' },
  { label: 'Reporte Totalizador', value: 'totalized_report' }
];

const Filters = props => {
  const { currentCompany } = useSelector(state => state.auth);
  const { errors, touched, setFieldValue, values, onHide, isLoading } = props;
  const [disabledRange, setDisabledRange] = useState(false);
  const [costCenters, setCostCenters] = useState([]);
  const [areas, setAreas] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { filterCostCenter, filterJobManagement, branchOfficeIds, filterEmployee } = values;
  const dispatch = useDispatch();

  const setFilterFields = (fieldName, value, allOptions) => {
    setFieldValue('filterName', '');
    setFieldValue('filterValue', '');
    const filter = {
      filterCostCenter: 'Centro de Costos: ',
      filterJobManagement: 'Área: ',
      branchOfficeIds: 'Lugar de Prestación de Servicios: ',
      filterEmployee: 'Trabajador: '
    };
    const filterName = filter[fieldName];
    if (!filterName || !value) return;
    const field = allOptions.find(option => option.value === value?.value);
    setFieldValue('filterName', filterName);
    setFieldValue('filterValue', field.label);
  };

  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const setName = (field, data) => {
    setFieldValue(field, data?.label);
  };

  const handleReportType = value => {
    const disabledValues = ['process_details', 'quote_details'].includes(value?.value);
    if (disabledValues) {
      setFieldValue('dateFrom', '');
      setFieldValue('dateTo', '');
      setFieldValue('format', '');
      setDisabledRange(true);
      return;
    }
    if (value?.value === 'accounting_accounts') {
      setFieldValue('dateFrom', '');
      setFieldValue('dateTo', '');
      setDisabledRange(true);
    }
    if (!disabledValues) {
      setDisabledRange(false);
    }
  };

  const successEmployees = data => {
    const parsedEmployees = camelCaseEmptyStringRecursive(data);
    return parsedEmployees.map(employee => ({
      label: employee.startDateContract ? `${employee.fullName} - (${employee.startDateContract})` : employee.fullName,
      value: employee.value
    }));
  };

  const employeeDateRange = () => {
    if (values.dateFrom && values.dateTo) {
      const dateFrom = moment(values.dateFrom);
      const dateTo = moment(values.dateTo);
      return { within_date_range: `${dateFrom},${dateTo}` };
    }
    if (values.filterMonth && values.year) {
      const month = moment(values.filterMonth).format('YYYY/MM/DD');
      const year = moment(values.year).format('YYYY/MM/DD');
      return {
        month_date: month,
        year_date: year
      };
    }
    return false;
  };

  const fetchEmployees = () => {
    if (!employeeDateRange()) return;
    payrollEmployeesRequest({
      dispatch,
      params: {
        paginate: false,
        advance: false,
        filter_by_company: currentCompany.id,
        ...employeeDateRange()
      },
      successCallback: ({ data }) => {
        const employeesData = successEmployees(data.data);
        const allEmployees = [{ id: 'Todos', label: 'Todos', value: 'all' }, ...employeesData];
        setEmployees(allEmployees);
      }
    });
  };

  const fetchCostCenters = () => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: data => {
        setCostCenters(resultFetchData(data));
      }
    });
  };

  const fetchJobManagments = () => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: data => {
        setAreas(resultFetchData(data));
      }
    });
  };

  const fetchBranchOffices = () => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        setBranchOffices(resultFetchData(data));
      }
    });
  };

  const disabledButton = filterEmployee;
  useEffect(fetchCostCenters, []);
  useEffect(fetchJobManagments, []);
  useEffect(fetchBranchOffices, []);
  useEffect(fetchEmployees, [values.dateFrom, values.dateTo, values.filterMonth, values.year]);
  return (
    <Form>
      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">REPORTES DEL PROCESO</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={3} xs={12}>
                    <Field name="filterMonth">
                      {({ field }) => (
                        <FormikTimePicker
                          abbr
                          label="Periodo del Proceso"
                          placeholder="Seleccionar periodo"
                          dateFormat="MM - yyyy"
                          showMonthYearPicker
                          setLocale
                          onChange={date => {
                            setFieldValue('dateFrom', '');
                            setFieldValue('dateTo', '');
                            setFieldValue(field.name, date);
                            setFieldValue('year', date);
                          }}
                          selected={field.value}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} md={3} xs={6}>
                    <Field name="dateFrom">
                      {({ field }) => (
                        <FormikTimePicker
                          {...field}
                          abbr
                          setLocale
                          label="Desde"
                          placeholder="mm/aaaa"
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          selected={field.value}
                          onChange={date => {
                            setFieldValue('filterMonth', '');
                            setFieldValue('year', '');
                            setFieldValue(field.name, date);
                          }}
                          disabled={disabledRange}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} md={3} xs={6}>
                    <Field name="dateTo">
                      {({ field }) => (
                        <FormikTimePicker
                          {...field}
                          abbr
                          setLocale
                          label="Hasta"
                          placeholder="mm/aaaa"
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          selected={field.value}
                          minDate={values?.dateFrom}
                          onChange={date => {
                            setFieldValue('filterMonth', '');
                            setFieldValue('year', '');
                            setFieldValue(field.name, date);
                          }}
                          disabled={disabledRange}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={3} xs={12}>
                    <Field name="filterEmployee">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Trabajadores"
                          menuPlacement="bottom"
                          placeholder="Seleccionar Trabajador"
                          options={employees}
                          isClearable
                          defaultValue={values.filterEmployee}
                          value={values.filterEmployee}
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            setName('employeNames', data);
                            setFilterFields(field.name, data, employees);
                          }}
                          isDisabled={filterCostCenter || filterJobManagement || branchOfficeIds}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="filterCostCenter">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Centro de Costos"
                          menuPlacement="top"
                          placeholder="Seleccionar Centro de Costos"
                          options={costCenters}
                          isClearable
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            setName('costCenterName', data);
                            setFilterFields(field.name, data, costCenters);
                          }}
                          isDisabled={disabledButton || filterJobManagement || branchOfficeIds}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="filterJobManagement">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Área"
                          menuPlacement="top"
                          placeholder="Seleccionar Área"
                          options={areas}
                          isClearable
                          onChange={data => {
                            setName('jobManagementName', data);
                            setFieldValue(field.name, data ? data.value : '');
                            setFilterFields(field.name, data, areas);
                          }}
                          isDisabled={disabledButton || filterCostCenter || branchOfficeIds}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="branchOfficeIds">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Lugar de Prestación de Servicios"
                          menuPlacement="top"
                          placeholder="Seleccionar lugar"
                          options={branchOffices}
                          isClearable
                          onChange={data => {
                            setName('branchOfficeName', data);
                            setFieldValue(field.name, data ? data.value : '');
                            setFilterFields(field.name, data, branchOffices);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isDisabled={disabledButton || filterCostCenter || filterJobManagement}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="reportType">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Tipo Reporte"
                          menuPlacement="bottom"
                          placeholder="Seleccionar reporte"
                          options={reportTypes}
                          isClearable
                          onChange={data => {
                            handleReportType(data);
                            setFieldValue(field.name, data ? data.value : '');
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="report">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Reporte del Proceso"
                          menuPlacement="bottom"
                          placeholder="Seleccionar reporte"
                          options={reports}
                          isClearable
                          onChange={data => {
                            handleReportType(data);
                            setFieldValue(field.name, data ? data.value : '');
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" onClick={onHide} disabled={isLoading}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  filterMonth: Yup.string()
    .test('filterMonth', 'Debes escoger el mes', function(value) {
      const { year } = this.parent;
      if (year && !value) return false;
      return true;
    })
    .test('selectAnyRange', 'Debes escoger un rango de fecha', function(value) {
      const { dateFrom, dateTo, year } = this.parent;
      if ([dateFrom, dateTo, year, value].every(item => !item)) return false;
      return true;
    }),
  year: Yup.string().test('yearDate', 'Debes escoger el Año', function(value) {
    const { filterMonth } = this.parent;
    if (filterMonth && !value) return false;
    return true;
  }),
  dateFrom: Yup.string().test('dateFrom', 'Debes escoger mes y año de inicio', function(value) {
    const { dateTo } = this.parent;
    if (dateTo && !value) return false;
    return true;
  }),
  dateTo: Yup.string().test('dateTo', 'Debes escoger el mes y año ', function(value) {
    const { dateFrom } = this.parent;
    if (dateFrom && !value) return false;
    return true;
  }),
  report: Yup.string().required('Debes escoger el reporte'),
  reportType: Yup.string().required('Debes escoger el tipo de reporte')
});

const setInitialValues = () => {
  return {
    filterEmployee: '',
    reportType: '',
    filterMonth: '',
    year: '',
    filterCostCenter: '',
    filterJobManagement: '',
    branchOfficeIds: '',
    dateFrom: '',
    dateTo: '',
    employeNames: '',
    costCenterName: '',
    jobManagementName: '',
    branchOfficeName: '',
    filterName: '',
    filterValue: '',
    report: ''
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  const newValues = { ...values };
  if (values.dateFrom && values.dateTo) {
    const dateFrom = moment(values.dateFrom).format('YYYY/MM/DD');
    const dateTo = moment(values.dateTo).format('YYYY/MM/DD');
    newValues.dateRange = `${dateFrom},${dateTo}`;
  }
  if (values.filterMonth && values.year) {
    const month = moment(values.filterMonth).format('MM') - 1;
    const year = moment(values.year).format('YYYY');
    newValues.filterMonth = month;
    newValues.year = year;
  }
  newValues.filterEmployee = newValues.filterEmployee === 'all' ? '' : [newValues.filterEmployee];
  if (!values.filterEmployee && !values.filterName && !values.filterValue) {
    newValues.filterName = 'Trabajador: ';
    newValues.filterValue = 'Todos';
  }
  const request = reports.find(reportType => reportType.value === values.report)?.request;
  formRequest(newValues, request);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(Filters);
