import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, getIn, withFormik } from 'formik';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { debounceIndexEmployeesRequest } from '../../../requests/employees';
import { FormikInput, FormikSelect, Icon, LinkBtn } from '../../../components';
import { rutFormat } from '../../../services/utils';

const EmployeeFilters = props => {
  const { errors, touched, setFieldValue } = props;
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const fetchEmployees = () => {
    setLoading(true);
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'name',
        is_dt: false,
        paginate: false
      },
      successCallback: response => {
        setEmployees(response.data.data);
        setLoading(false);
      }
    });
  };
  const handleNationalIdentificationFormat = e => {
    const formattedValue = rutFormat(e.target.value.replace(/k/g, 'K'));
    setFieldValue(e.target.name, formattedValue);
    setFieldValue('employeeId', '');
  };
  useEffect(fetchEmployees, []);
  return (
    <Form>

      <div className="d-flex align-items-center">

        <LinkBtn variant="circle-dark" className="btn-circle mt-2" block to="/dt/welcome">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <div className="d-flex flex-column ml-1">
          <h2 className="mt-3">Buscar Documentos</h2>

        </div>
      </div>
      <Row>
        <Col className="mt-2">
          <Card className="card-dt">
            <Col className="top-header-green-dt card-header-title-dt">Buscar colaboradores</Col>
            <Card.Body className="div-content card-body-padding">
              <Row>
                <Col sm={12} md={6} xs={12}>
                  <Field name="employeeId">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="Seleccionar Colaborador"
                        options={employees}
                        isClearable
                        onChange={data => {
                          setFieldValue(field.name, data ? data.value : '');
                          setFieldValue('nationalId', '');
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col sm={12} md={6} xs={12}>
                  <Field name="nationalId">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Rut Colaborador:"
                        onChange={handleNationalIdentificationFormat}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" disabled={loading}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    employeeId: '',
    nationalId: ''
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  enableReinitialize: false
})(EmployeeFilters);
