import React from 'react';
import { Table } from 'react-bootstrap';

const DailyAttendacesFilterDataTable = ({
  attendances
}) => {

  return (
    <>
      <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
        <thead className="rdt_TableHeadRow text-center" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
          <tr className="rdt_TableCol">
            <th className="text-center align-middle">FECHA</th>
            <th className="text-center align-middle">HORA MARCACIÓN</th>
            <th className="text-center align-middle">TIPO DE REGISTRO</th>
            <th className="text-center align-middle">MEDIO DE MARCACIÓN</th>
            <th className="text-center align-middle">HASH</th>
          </tr>
        </thead>
        <tbody>
          {attendances.length > 0 ? attendances.map(attendance => (
            <tr>
              <td>{attendance.date}</td>
              <td>{attendance.parsed_time}</td>
              <td>{attendance.translated_time_type}</td>
              <td>{attendance.translated_device_type}</td>
              <td>{attendance.hash_code}</td>
            </tr>
          )
          ) : <tr>
            <td colSpan="5" className="text-center py-3">No se encontraron asistencias</td>
          </tr>}
        </tbody>
      </Table>
    </>
  );

};

export default DailyAttendacesFilterDataTable;
