import React, { useEffect, useState } from 'react';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import AttendanceReportIndex from './AttendanceReportIndex';
import WorkdayReportIndex from './WorkdayReportIndex';
import OffdaysReportIndex from './OffdaysReportIndex';
import { Icon, LinkBtn, SimpleCenteredModal } from '../../components';
import { useSetTab } from '../../services/hooks';
import { AbilityContext } from '../../config/abilityContext';
import ShiftReportIndex from './ShiftReportIndex';
import abreviations from '../../assets/images/abbreviations.png';
import './Style.scss';
import DailyAttendanceIndex from './DailyAttendanceIndex';

const LegalReports = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const haveTab = params.get('tab');

  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [key, setKey] = useSetTab('attendance-report', location);
  const ability = useAbility(AbilityContext);
  const [titleName, setTitleName] = useState();

  const title = () => {
    switch (haveTab) {
      case 'attendance-report':
        return setTitleName('- Asistencia');
      case 'workday-report':
        return setTitleName('- Jornada Diaria');
      case 'offday-report':
        return setTitleName('- Días domingo y/o días festivos');
      case 'shift-report':
        return setTitleName('- Modificaciones y/o alteraciones de turnos');
      case 'daily_attendance':
        return setTitleName('- Marcaciones Diarias');
      default:
        return setTitleName('');
    }
  };

  useEffect(title, []);

  return (
    <div>
      <Row className="mt-2">
        <Col>
          <div className="d-flex align-items-center">
            {haveTab && (
              <LinkBtn variant="circle-dark" className="btn-circle mt-2" block to="/dt/welcome">
                <Icon icon="chevron-back" />
              </LinkBtn>
            )}
            <div className="d-flex flex-column ml-1">
              <h2 className="mt-3 h2-small">Reportes Legales {titleName}</h2>
            </div>
          </div>
        </Col>
        {haveTab !== 'daily_attendance' && (
          <div className="mt-4 mb-1 justify-content-end d-flex">
            <Button className="arial-dt" variant="warning" onClick={() => setModalShow(true)}>
              Glosario de Abreviaciones
            </Button>
          </div>
        )}
      </Row>

      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        {!haveTab && (
          <Nav variant="pills">
            {ability.can('index', 'AttendanceReport') && (
              <Nav.Item>
                <Nav.Link className="mb-1 arial-dt" eventKey="attendance-report">
                  <Icon icon="location" />
                  Asistencia
                </Nav.Link>
              </Nav.Item>
            )}
            {ability.can('index', 'WorkdayReport') && (
              <Nav.Item>
                <Nav.Link className="mb-1 arial-dt" eventKey="workday-report">
                  <Icon icon="location" />
                  Jornada Diaria
                </Nav.Link>
              </Nav.Item>
            )}
            {ability.can('index', 'HolidayReport') && (
              <Nav.Item>
                <Nav.Link className="mb-1 arial-dt" eventKey="offday-report">
                  <Icon icon="location" />
                  Días domingo y/o días festivos
                </Nav.Link>
              </Nav.Item>
            )}
            {ability.can('index', 'ShiftReport') && (
              <Nav.Item>
                <Nav.Link className="mb-1 arial-dt" eventKey="shift-report">
                  <Icon icon="location" />
                  Modificaciones y/o alteraciones de turnos
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        )}
        <Tab.Content>
          <Tab.Pane eventKey="attendance-report">
            <AttendanceReportIndex rrhh moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="workday-report">
            <WorkdayReportIndex rrhh moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="offday-report">
            <OffdaysReportIndex rrhh moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="shift-report">
            <ShiftReportIndex rrhh moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="daily_attendance">
            <DailyAttendanceIndex rrhh moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title="Glosario de Abreviaciones"
        titleClass="modal-title-dt"
        body={
          <Row className="ml-2">
            <img src={abreviations} alt="Abreviaciones" style={{ maxWidth: '97%', height: 'auto' }} />
          </Row>
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default LegalReports;
