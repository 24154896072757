import React, { useState, useEffect, useRef } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Card, Col, Row, Spinner } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { AbilityContext } from '../../../config/abilityContext';
import Icon from '../../Icons';
import LinkBtn from '../../Utils/Button/LinkBtn';
import { debounceIndexVacationsRequest } from '../../../requests/vacations';
import { debounceIndexPermissionsRequest } from '../../../requests/permissions';
import { debounceIndexLoanRequest } from '../../../requests/loans';
import { debounceIndexShiftChangeRequest } from '../../../requests/shiftChange';
import { debounceIndexAdvancePaymentRequest } from '../../../requests/advancePayments';
import { profilePermissions } from '../../../requests/dashboards';
import { debounceIndexOvertimeRequest } from '../../../requests/overtimes';

const MyRequests = ({ currentEmployee, topClass, setTotalItemsRequest }) => {
  const ability = useAbility(AbilityContext);
  const { advanceSettings } = useSelector(state => state.utils);
  const dispatch = useDispatch();
  const requestsAbility = [
    ability.can('index', 'AdvancePayment'),
    ability.can('index', 'Permission'),
    ability.can('index', 'Vacation'),
    ability.can('index', 'Loan'),
    ability.can('index', 'ShiftChangeRequest'),
    ability.can('index', 'Overtime'),
    ability.can('index', 'OvertimeCompensation')
  ];

  const [vacations, setVacations] = useState([]);
  const [administrative, setAdministrative] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const [loans, setLoans] = useState([]);
  const [shiftChange, setShiftChange] = useState([]);
  const [advancePayments, setAdvancePayments] = useState([]);
  const [overtimeRequest, setOvertimeRequest] = useState([]);
  const [showSpinner, setShowSpinner] = useState(1);
  const isMounted = useRef(true);

  const vacationsRole = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const loanRole = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue === 'true');

  const permissionsRole = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave', 'administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const shiftChangeRole = advanceSettings
    .filter(asItem => asItem.code === 'shift_change')
    .some(x => x.textValue === 'true');

  const advancePaymentsRole = advanceSettings
    .filter(asItem => asItem.code === 'advance_payment_request')
    .some(x => x.textValue === 'true');

  const administrativeDaysRole = advanceSettings
    .filter(asItem => ['administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const overtimeRequestRole = advanceSettings
    .filter(asItem => asItem.code === 'overtime_requests')
    .some(x => x.textValue === 'true');

  const overtimeCompensationVacationRole = advanceSettings
    .filter(asItem => asItem.code === 'overtime_compensation_vacation_request')
    .some(x => x.textValue === 'true');

  const cardRequest = advanceSettings
    .filter(asItem =>
      [
        'legal_holiday',
        'progressive_holiday',
        'additional_days',
        'paid_leave',
        'without_paid_leave',
        'administrative_days',
        'shift_change',
        'loan_request',
        'advance_payment_request',
        'overtime_requests'
      ].includes(asItem.code)
    )
    .some(x => x.textValue === 'true');

  useEffect(() => {
    const calculateTotalItemsRequest = () => {
      let total = 0;
      if (vacationsRole && requestsAbility[2]) total += 1;
      if (permissionsRole && requestsAbility[1]) total += 1;
      if (loanRole && requestsAbility[3]) total += 1;
      if (advancePaymentsRole && requestsAbility[0]) total += 1;
      if (shiftChangeRole && requestsAbility[4]) total += 1;
      if (overtimeRequestRole && requestsAbility[5]) total += 1;
      if (overtimeCompensationVacationRole) total += 1;
      if (administrativeDaysRole && requestsAbility[6]) total += 1;
      return total;
    };

    if (advanceSettings.length > 0) {
      setTotalItemsRequest(calculateTotalItemsRequest());
    }
  }, [
    advanceSettings,
    setTotalItemsRequest,
    vacationsRole,
    requestsAbility,
    permissionsRole,
    loanRole,
    advancePaymentsRole,
    shiftChangeRole,
    overtimeRequestRole,
    administrativeDaysRole,
    overtimeCompensationVacationRole
  ]);

  const handleIndexRequest = () => {
    const sendParams = { filter_employee: currentEmployee, status: 'pending' };
    if (currentEmployee) {
      debounceIndexVacationsRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          if (isMounted.current) {
            const { data } = response.data;
            const countVacations = data.filter(asItem => ['legal', 'additional_days'].includes(asItem.vacation_type));
            const countAdministrative = data.filter(asItem => asItem.vacation_type === 'administrative_days');
            setVacations(countVacations.length);
            setAdministrative(countAdministrative.length);
            setShowSpinner(0);
          }
        }
      });

      profilePermissions({
        dispatch,
        successCallback: response => {
          setAdministrative(camelCaseRecursive(response.data.administrative_vacation_card));
        }
      });

      debounceIndexPermissionsRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data } = response.data;
          setPermissions(data.length);
          setShowSpinner(0);
        }
      });

      debounceIndexLoanRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data } = response.data;
          setLoans(data.length);
          setShowSpinner(0);
        }
      });

      debounceIndexShiftChangeRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data } = response.data;
          setShiftChange(data.length);
          setShowSpinner(0);
        },
        failureCallback: () => null
      });

      debounceIndexAdvancePaymentRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data } = response.data;
          setAdvancePayments(data.length);
          setShowSpinner(0);
        }
      });

      debounceIndexOvertimeRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data } = response.data;
          setOvertimeRequest(data.length);
          setShowSpinner(0);
        }
      });
    }
  };
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(handleIndexRequest, [currentEmployee]);

  return (
    <Card className="mb-1">
      <Col className={topClass}>
        <Col className="card-header-title">GESTIONA TUS SOLICITUDES</Col>
      </Col>

      {showSpinner ? (
        <Col className="div-content mt-5 mb-5">
          <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row>
            {vacationsRole && requestsAbility[2] && (
              <Col sm={12} md={6} xs={12} className="mt-2">
                {vacations > 0 && (
                  <Badge variant="danger" className="offset-md-9 badge-circle">
                    {vacations}
                  </Badge>
                )}
                <LinkBtn variant="blue-1" size="sm" block to="/profile/requests?tab=vacations">
                  <Icon icon="sunny" className="icon-15" /> Vacaciones
                </LinkBtn>
              </Col>
            )}
            {administrativeDaysRole && (
              <Col sm={12} md={6} xs={12} className="mt-2">
                {administrative > 0 && (
                  <Badge variant="danger" className="offset-md-9 badge-circle">
                    {administrative}
                  </Badge>
                )}
                <LinkBtn variant="blue-2" size="sm" to="/profile/requests?tab=permissions" block>
                  <Icon icon="calendar" className="icon-15 font-size-responsive" /> Días Admin.
                </LinkBtn>
              </Col>
            )}
          </Row>
          <Row>
            {permissionsRole && requestsAbility[1] && (
              <Col sm={12} md={6} xs={12} className="mt-2">
                {permissions > 0 && (
                  <Badge variant="danger" className="offset-md-9 badge-circle">
                    {permissions}
                  </Badge>
                )}
                <LinkBtn variant="green" size="sm" to="/profile/requests?tab=permissions" block>
                  <Icon icon="clock2" className="icon-15" /> Permisos
                </LinkBtn>
              </Col>
            )}
            {loanRole && requestsAbility[3] && (
              <Col sm={12} md={6} xs={12} className="mt-2">
                {loans > 0 && (
                  <Badge variant="danger" className="offset-md-9 badge-circle">
                    {loans}
                  </Badge>
                )}
                <LinkBtn variant="orange" size="sm" to="/profile/requests?tab=loans" block>
                  <Icon icon="calendar" className="icon-15" /> Préstamos
                </LinkBtn>
              </Col>
            )}
          </Row>
          <Row>
            {advancePaymentsRole && requestsAbility[0] && (
              <Col sm={12} md={6} xs={12} className="mt-2">
                {advancePayments > 0 && (
                  <Badge variant="danger" className="offset-md-9 badge-circle">
                    {advancePayments}
                  </Badge>
                )}
                <LinkBtn variant="red" size="sm" to="/profile/requests" block>
                  <Icon icon="calendar" className="icon-15" /> Anticipos
                </LinkBtn>
              </Col>
            )}
            {overtimeCompensationVacationRole && requestsAbility[6] && (
              <Col sm={12} md={6} xs={12} className="mt-2">
                <LinkBtn variant="warning" size="sm" to="/profile/requests?tab=overtime_compensation" block>
                  <Icon icon="clock2" className="icon-15" /> Días por HH.EE
                </LinkBtn>
              </Col>
            )}
          </Row>
          <Row>
            {shiftChangeRole && requestsAbility[4] && (
              <Col sm={12} md={6} xs={12} className="mt-2">
                {shiftChange > 0 && (
                  <Badge variant="danger" className="offset-md-9 badge-circle">
                    {shiftChange}
                  </Badge>
                )}
                <LinkBtn variant="gray" size="sm" to="/profile/requests?tab=shift_change" block>
                  <Icon icon="pencil" className="icon-15" /> Turnos
                </LinkBtn>
              </Col>
            )}
            {overtimeRequestRole && requestsAbility[5] && (
              <Col sm={12} md={6} xs={12} className="mt-2 mb-2">
                {overtimeRequest > 0 && (
                  <Badge variant="danger" className="offset-md-9 badge-circle">
                    {overtimeRequest}
                  </Badge>
                )}
                <LinkBtn variant="orange" size="sm" to="/profile/requests?tab=overtimes" block>
                  <Icon icon="clock2" className="icon-15" /> Horas
                </LinkBtn>
              </Col>
            )}
          </Row>
          {!cardRequest && !showSpinner && (
            <Row>
              <Col>
                <p className="mt-2" style={{ height: 118, textAlign: 'center' }}>
                  No existen solicitudes asociadas...
                </p>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Card>
  );
};

export default MyRequests;
