import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Spinner } from 'react-bootstrap';
import { legalAttendanceReport } from '../../requests/legalreports';
import AttendanceProofRender from './AttendanceProofRender';

const AttendanceFilterDataTable = ({ customParams }) => {
  const [attendances, setAttendances] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();

  const styleWihtOutShift = {
    backgroundColor: 'rgba(255, 160, 122, 0.9)',
    color: 'white'
  };

  const styleOutShift = {
    backgroundColor: 'rgba(248, 148, 6, 0.9)',
    color: 'white'
  };

  const handleSuccessIndex = response => {
    setAttendances(response.data);
    setOnRequest(false);
  };

  const handleAttendanceReportRequest = () => {
    if (attendances.length > 0) {
      return;
    }
    const request = async () => setOnRequest(true);
    legalAttendanceReport({
      dispatch,
      params: { ...customParams },
      successCallback: handleSuccessIndex
    });
    request();
  };

  useEffect(handleAttendanceReportRequest, [customParams.filter_by_employee]);

  const renderResult = attendance => {
    let styles = null;
    if (attendance.justification && attendance.justification.includes('Marcación fuera de turno')) {
      styles = styleOutShift;
    }

    if (attendance.justification && attendance.justification.includes('No tiene turno asignado')) {
      styles = styleWihtOutShift;
    }

    return (
      <tr style={styles}>
        <td className="text-center align-middle">{attendance.date}</td>
        <td className="text-center align-middle">{attendance.attendance}</td>
        <td className="text-center align-middle">{attendance.absence}</td>
        <td className="text-center align-middle">
          {attendance.justification}
          {<AttendanceProofRender item={{ ...attendance, justification: '' }} />}
        </td>
      </tr>
    );
  };

  const tableHeader = () => {
    return ['FECHA', 'ASISTENCIA', 'AUSENCIA', 'OBSERVACIONES'];
  };

  const renderData = () => {
    if (onRequest) {
      return (
        <div className="containerSpinnerLoad position-absolute h-100 w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }

    if (attendances.length === 0) {
      return (
        <div className="text-center pt-3 pb-3">
          <p className="text-muted" style={{ fontFamily: 'Arial', fontSize: '8px' }}>
            No hay trabajadores que coincidan con la selección
          </p>
        </div>
      );
    }

    return (
      <>
        <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
          <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
            <tr className="rdt_TableCol">
              {tableHeader().map(itemHeader => (
                <th className="text-center align-middle" key={itemHeader}>
                  {itemHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{attendances.map(attendance => renderResult(attendance))}</tbody>
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default AttendanceFilterDataTable;
