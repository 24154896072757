import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { useDispatch } from 'react-redux';
import { ComponentDataTable, DefaultModal, ImportModal, SimpleCenteredModal } from '../../components';
import useConceptHook from './useConceptHook';
import { useAuthorization } from '../../services/hooks';
import massiveActions from './massiveActions';
import Errors from '../FileBroadcast/DTElectronic/Errors';
import { AbilityContext } from '../../config/abilityContext';
import {
  exportBalancesRequest,
  importBalanceRequest,
  importTemplateBalanceRequest,
  preImportBalanceRequest
} from '../../requests/balances';
import { downloadFile } from '../../services/utils';
import { sendAlert } from '../../actions/utils';

const ConceptsDataTable = ({ columns, moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [paramsModalAction, setParamsModalAction] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [disabled, setDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [defaultModalSize, setDefaultModalSize] = useState('md');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [concepts, setConcepts] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const history = useHistory();

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
  };

  const handleModalErrors = errors => {
    setDefaultModalTitle('Advertencia');
    setDefaultModalBody(
      <>
        <p className="mx-3 font-weight-normal">
          Los siguientes conceptos no pueden ser eliminados ya que estan asociado a un proceso de nómina:
        </p>
        <Errors errorLabels={errors} />
      </>
    );
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setConfirmModalShow(false);
    setOnRequest(false);
  };

  const {
    handleRemoveConcept,
    handleIndexConcept,
    handleMassiveActivateConcepts,
    handleMassiveDeactivateConcepts,
    handleMassiveDeleteConcepts,
    handleChangeStatusConcept
  } = useConceptHook();

  const handleConfirm = (action, item) => {
    switch (action) {
      case 'destroy':
        setParamsModalAction([item.id, handleModalClose, setMoreData]);
        setModalAction(() => handleRemoveConcept);
        break;
      case 'update': {
        const newBalance = { balance: { id: item.id, active: !item.active } };
        setParamsModalAction([newBalance, handleModalClose, setMoreData]);
        setModalAction(() => handleChangeStatusConcept);
        break;
      }
      case 'massive_active':
        setModalAction(() => handleMassiveActivateConcepts);
        setParamsModalAction([selectedRows, setOnRequest, handleModalClose, setMoreData, setClearSelectedRows]);
        break;
      case 'massive_deactivate':
        setModalAction(() => handleMassiveDeactivateConcepts);
        setParamsModalAction([selectedRows, setOnRequest, handleModalClose, setMoreData, setClearSelectedRows]);
        break;
      case 'massive_destroy':
        setModalAction(() => handleMassiveDeleteConcepts);
        setParamsModalAction([
          selectedRows,
          setOnRequest,
          handleModalClose,
          setMoreData,
          setClearSelectedRows,
          handleModalErrors
        ]);
        break;
      default:
        break;
    }
  };

  const permissions = {
    read: useAuthorization('read_concept', 'Balance'),
    update: useAuthorization('update_concept', 'Balance'),
    destroy: useAuthorization('destroy_concept', 'Balance')
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push({
          pathname: `/remunerations_settings/${item.id}/show`,
          state: { tab: 'concepts-tab' }
        });
        break;
      case 'edit':
        if (permissions.update) {
          history.push({
            pathname: `/remunerations_settings/${item.id}/edit`,
            state: { tab: 'concepts-tab' }
          });
        }
        break;
      case 'destroy':
        if (permissions.destroy) {
          setConfirmModalShow(true);
          setModalTitle(`Eliminar este concepto`);
          setModalBody(`¿Estás seguro que deseas eliminar este concepto?`);
          setModalAction(() => handleConfirm('destroy', item));
        }
        break;
      case 'change_status': {
        const newStatus = item.active ? 'desactivar' : 'activar';
        setConfirmModalShow(true);
        setModalTitle(`${newStatus} concepto`);
        setModalBody(`¿Estás seguro que deseas ${newStatus} este concepto?`);
        setModalAction(() => handleConfirm('update', item));
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'massive_destroy':
        setModalTitle('Eliminar Conceptos');
        setConfirmModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los conceptos seleccionados?');
        setModalAction(() => handleConfirm('massive_destroy'));
        break;
      case 'massive_active':
        setModalTitle('Activar Conceptos');
        setConfirmModalShow(true);
        setModalBody('¿Estás seguro que deseas activar los conceptos seleccionados?');
        setModalAction(() => handleConfirm('massive_active'));
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar Conceptos');
        setConfirmModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar los conceptos seleccionados?');
        setModalAction(() => handleConfirm('massive_deactivate'));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setConfirmModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
    setModalShow(false);
  };

  const handleDownload = () => {
    setDisabled(true);
    exportBalancesRequest({
      dispatch,
      params: { sort_export: true },
      successCallback: response => {
        downloadFile(response);
        setConfirmModalShow(false);
      },
      failureCallback: handleFailureRequest,
      callBack: () => setDisabled(false)
    });
  };

  const downloadModal = () => {
    setConfirmModalShow(true);
    /* setModalTitle(`Exportar ${assetDownload ? 'haberes' : 'descuentos'}`); */
    setModalTitle('Exportar Conceptos');
    setModalAction(() => handleDownload);
    setParamsModalAction([]);
    /* setModalBody(`¿Estás seguro que deseas descargar ${assetDownload ? 'haberes' : 'descuentos'}?`); */
    setModalBody('¿Estás seguro que deseas descargar conceptos?');
  };

  const handleImportExportClick = action => {
    setParamsModalAction({});
    switch (action) {
      case 'balance_import':
        setDefaultModalSize('md');
        setDefaultModalTitle('Importar Conceptos');
        setDefaultModalBody(
          <ImportModal
            onDropUploaded={preImportBalanceRequest}
            handleTemplate={importTemplateBalanceRequest}
            onHide={importBalanceRequest}
            hideModal={() => setModalShow(false)}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        setModalShow(true);
        break;
      case 'balance_download':
        downloadModal();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, permissions)}
        data={concepts}
        totalRows={amount}
        selectableRows
        withMassActions
        massActions={massiveActions(
          ability,
          handleImportExportClick,
          handleMassAction,
          permissions,
          selectedRows.length === 0
        )}
        moreData={moreData}
        resourceRequest={params =>
          handleIndexConcept({ ...params, preloaded: false }, setConcepts, setAmount, setOnRequest)
        }
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        preName="balance"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={confirmModalShow}
        handleClose={() => setConfirmModalShow(false)}
        handleConfirm={() => modalAction(...paramsModalAction)}
        disabled={disabled}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        size={defaultModalSize}
        title={defaultModalTitle}
        body={defaultModalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
export default ConceptsDataTable;
