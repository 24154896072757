import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Icon, LinkBtn, MainLogo } from '../../../components';
import './style.scss';

const Welcome = () => {
  return (
    <>
      <Row className="justify-content-center img-welcome">
        <Col className="mr-4 welcome-dt">
          <MainLogo style={{ maxWidth: '70%' }} className="mx-auto d-block" />
          <h1 className="d-flex justify-content-center mt-3 mb-4 text-center">Bienvenido Fiscalizador</h1>
          <Row className="text-center">
            <Col xs={12} className="mb-2">
              <LinkBtn variant="primary" className="mr-2 mb-1" to="/dt/legal_reports?tab=attendance-report">
                <Icon icon="menu" className="icon-15" /> Asistencia
              </LinkBtn>
              <LinkBtn variant="primary" className="mr-2 mb-1" to="/dt/legal_reports?tab=workday-report">
                <Icon icon="menu" className="icon-15" /> Jornada Diaria
              </LinkBtn>
              <LinkBtn variant="primary" className="mb-1" to="/dt/legal_reports?tab=offday-report">
                <Icon icon="menu" className="icon-15" /> Días domingo y/o días festivos
              </LinkBtn>
            </Col>
          </Row>
          <Row className="text-center">
            <Col xs={12} className="mb-2">
              <LinkBtn variant="primary" className="mr-2 mb-1" to="/dt/legal_reports?tab=shift-report">
                <Icon icon="menu" className="icon-15" /> Modificaciones y/o alteraciones de turnos
              </LinkBtn>
              <LinkBtn variant="primary" className="mr-2 mb-1" to="/dt/legal_reports?tab=daily_attendance">
                <Icon icon="book-outline" className="icon-15" /> Marcaciones Diarias
              </LinkBtn>
              <LinkBtn variant="primary" className="mr-2 mb-1" to="/dt/attendances_control_clock/true">
                <Icon icon="book-outline" className="icon-15" /> Incidentes Técnicos
              </LinkBtn>
              <LinkBtn variant="primary" className="mb-1" to="/dt/documents">
                <Icon icon="folder-open-outline" className="icon-15" /> Buscar Documentos
              </LinkBtn>
            </Col>
          </Row>
        </Col>

        <Col sm={3} md={2} xl={2} className="mr-4 mt-4">
          <LinkBtn
            variant="primary"
            style={{ position: 'fixed', top: '70px', right: '1%', width: 200 }}
            className="btn-login"
            size="lg"
            block
            to="#"
          >
            Fiscalización DT
          </LinkBtn>
        </Col>
      </Row>
    </>
  );
};

export default Welcome;
