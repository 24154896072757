import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AttendancesControlClockDataTable from './AttendancesControlClockDataTable';
import { Icon, LinkBtn } from '../../../components';

const AttendancesControlClockIndex = ({ match }) => {
  const isDt = match.params.is_dt;
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <div className="d-flex align-items-center">
            {isDt && (
              <LinkBtn variant="circle-dark" className="btn-circle mt-2" block to="/dt/welcome">
                <Icon icon="chevron-back" />
              </LinkBtn>
            )}
            <div className="d-flex flex-column ml-1">
              <h2 className="text-uppercase mt-3" style={isDt ? { fontSize: '12px' } : {}}>
                {isDt ? 'Incidentes Técnicos' : 'Log de Dispositivos'}
              </h2>
            </div>
          </div>
        </Col>
      </Row>
      <AttendancesControlClockDataTable isDt={isDt} />
    </>
  );
};

export default AttendancesControlClockIndex;
