import { Field /* , useFormikContext */ } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DefaultCheckBox } from '../../../components';
import styles from './Styles.module.scss';

const DiscountForm = () => {
  return (
    <>
      <Row className="mt-2">
        <Col md={12}>
          <Field name="balance[legalDiscount]">
            {({ field }) => (
              <DefaultCheckBox
                label="Descuento Legal"
                {...field}
                field={field}
                customDivClass="mb-2"
                inputClass={styles.checkboxInput}
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[settlementAdvance]">
            {({ field }) => (
              <DefaultCheckBox
                label="Anticipo Liquidación"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[judicialRetention]">
            {({ field }) => (
              <DefaultCheckBox
                label="Retención Judicial"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[contractual]">
            {({ field }) => (
              <DefaultCheckBox
                label="Contractual"
                {...field}
                field={field}
                customDivClass="mb-2"
                customLabelClass={styles.checkBoxLabel}
                activeClass={styles.activeCheckBox}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[printOnLiquidation]">
            {({ field }) => (
              <DefaultCheckBox
                label="Se imprime en liquidación"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="balance[settlementConsideration]">
            {({ field }) => (
              <DefaultCheckBox
                label="Aplica en finiquito"
                {...field}
                field={field}
                customDivClass="mb-2"
                activeClass={styles.activeCheckBox}
                customLabelClass={styles.checkBoxLabel}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default DiscountForm;
