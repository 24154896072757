import React from 'react';
import { Table } from 'react-bootstrap';
import AttendanceProofRender from './AttendanceProofRender';

const ShiftFilterDataTable = ({ shiftLogs }) => {
  const renderData = () => {
    if (shiftLogs.length === 0) {
      return (
        <div className="text-center pt-3 pb-3">
          <p className="text-muted" style={{ fontFamily: 'Arial', fontSize: '8px' }}>
            SIN CAMBIOS O MODIFICACIONES DE TURNOS EN EL PERPÍODO CONSULTADO
          </p>
        </div>
      );
    }
    return (
      <>
        <Table bordered size="sm">
          <thead style={{ backgroundColor: '#EBEBEB' }}>
            <tr>
              <th className="text-center align-middle">Fecha Asignación Turno</th>
              <th className="text-center align-middle">Turno Asignado</th>
              <th className="text-center align-middle">Extensión del Turno</th>
              <th className="text-center align-middle">Asignación de Nuevo Turno</th>
              <th className="text-center align-middle">Inicio del turno</th>
              <th className="text-center align-middle">Nuevo Turno</th>
              <th className="text-center align-middle">Extensión del Nuevo Turno</th>
              <th className="text-center align-middle">Solicitante</th>
              <th className="text-center align-middle">Observaciones</th>
            </tr>
          </thead>
          {shiftLogs.map(log => (
            <tbody style={{ fontWeight: 'normal' }}>
              <tr>
                <td className="text-center">{log.original_shift_date}</td>
                <td className="text-left">{log.original_turn_name}</td>
                <td className="text-center">{log.original_shift_extension}</td>
                <td className="text-center">{log.new_shift_date}</td>
                <td className="text-center">{log.new_shift_start_date}</td>
                <td className="text-left">{log.new_turn_name}</td>
                <td className="text-left">{log.new_shift_extension}</td>
                <td className="text-center">{log.solicitor}</td>
                <td className="text-center">
                  {<AttendanceProofRender item={{ ...log, justification: log.observation }} />}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default ShiftFilterDataTable;
