import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import moment from 'moment';
import {
  BasicTextArea,
  FormikTimePicker,
  FormikInput,
  FormikNumber,
  InputSelect,
  FormikSelect
} from '../../../components';
import { indexSelectEmployeesRequest } from '../../../requests/employees';
import { delayMethod } from '../../../services/utils';
import '../../../services/yupCustomMethods';
import { indexBalancesRequest } from '../../../requests/balances';

const LoanForm = ({
  action,
  errors,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  touched,
  loan,
  values,
  loanProcess,
  showWarning,
  ...props
}) => {
  const { rrhh, handleModalClose, onHide, btnMessage, balanceOptions } = props;
  const [grantDate, setGrantDate] = useState(
    values.loan.grantDate !== null ? moment(values.loan.grantDate).startOf('month') : ''
  );
  const [balances, setBalances] = useState([]);
  const dispatch = useDispatch();
  const { loan: vLoan } = values;
  const { amount, parsedAmount, installments } = vLoan;
  const isResolved = false;
  const minimumMonth = new Date(`${loanProcess.month} 1, ${loanProcess.year}`);

  const formatBalances = (balancesToSelect = []) => {
    if (balancesToSelect.length === 0) return;
    const defaultBalances = balancesToSelect.map(balance => ({
      value: balance.id,
      label: `${balance.code} - ${balance.name}`
    }));
    setBalances(defaultBalances);
  };

  const fetchBalances = () => {
    indexBalancesRequest({
      dispatch,
      params: { filter_loan_options: true },
      successCallback: ({ data }) => {
        formatBalances(data.data);
      }
    });
  };

  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        active_contracts: true,
        paginate: false,
        filter_identification_type: 'rut'
      },
      successCallback: response => callback(response.data.data)
    });
  };

  useEffect(fetchBalances, []);
  useEffect(formatBalances, []);

  const handleWarningString = () => {
    if (!showWarning) return '';
    if (!values.loan.balanceId) return '';
    if (!values.loan.startDate) return '';
    const currentBalance = balanceOptions.find(balance => balance.id === values.loan.balanceId);
    if (!currentBalance) return '';
    const isLaterMonth = moment(values.loan.startDate).isAfter(moment(minimumMonth));
    if (!isLaterMonth) return '';
    const loanType = currentBalance?.is_loan_credit ? 'crédito' : 'préstamo';
    const dateString = moment(values.loan.startDate).format('MM/yyyy');

    return `Ud está ingresando un ${loanType},  cuyo mes de proceso será ${dateString} y no el  mes actual de proceso ¿Está seguro que desea guardar?`;
  };

  return (
    <Form className="mx-3">
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row>
        {rrhh && (
          <Col md={12}>
            <Field name="loan[employeeId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Trabajador"
                  placeholder="Seleccionar Trabajador"
                  values={values.loan}
                  model={[loan, 'employee']}
                  request={fetchEmployees}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        <Col md={4}>
          <Field name="loan[parsedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                leftAddon="$"
                fieldName="loan[amount]"
                value={amount}
                setFieldValue={setFieldValue}
                label="Monto Préstamo"
                errors={errors}
                touched={touched}
                disabled={isResolved}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="loan[installments]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                leftAddon=""
                fieldName="loan[installments]"
                value={installments}
                setFieldValue={setFieldValue}
                label="Numero de cuotas"
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="loan[installmentAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                leftAddon="$"
                fieldName="loan[installmentAmount]"
                value={parseFloat(parsedAmount.replace(/[.$]/g, '')) / installments}
                setFieldValue={setFieldValue}
                label="Monto Cuota"
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="loan[grantDate]">
            {({ field }) => (
              <FormikTimePicker
                {...field}
                abbr
                setLocale
                minDate={new Date(minimumMonth.getFullYear(), minimumMonth.getMonth(), 1)}
                maxDate={new Date(minimumMonth.getFullYear(), 12, 0)}
                label="Fecha Otorgamiento Crédito"
                placeholder="mm/aaaa"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                selected={values.loan.grantDate}
                onChange={date => {
                  setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date);
                  setFieldValue('loan[startDate]', '');
                  setGrantDate(date !== null ? moment(date).startOf('month') : '');
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="loan[startDate]">
            {({ field }) => (
              <FormikTimePicker
                {...field}
                abbr
                setLocale
                label="Fecha Primer Descuento"
                minDate={new Date(moment(grantDate, 'D-MM-YYYY'))}
                placeholder="mm/aaaa"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                selected={values.loan.startDate}
                onChange={date => setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col md={6}>
          <Field name="loan[balanceId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Préstamo"
                placeholder="Seleccionar Tipo de Préstamo"
                options={balances}
                defaultValue={loan.balanceId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col md={6}>
          <Field name="loan[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                label="Nombre de Préstamo"
                placeholder="Préstamo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col md={12}>
          <Field name="loan[reason]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Motivo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={isResolved}
              />
            )}
          </Field>
        </Col>
        {showWarning && (
          <Col md={12} className="mt-2 mb-2">
            <small className="text-danger form-text">
              <b>{handleWarningString()}</b>
            </small>
          </Col>
        )}
      </Row>
      <Row className="d-flex justify-content-end mt-3 mb-2">
        <Col xs={6} sm={3}>
          <Button block variant="outline-info" onClick={() => delayMethod(handleModalClose)} disabled={isSubmitting}>
            Cancelar
          </Button>
        </Col>
        <Col xs={6} sm={3}>
          <Button block type="submit" variant="primary" onClick={onHide} disabled={isSubmitting}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const tranformDate = date => {
  if (date) {
    const dateList = date.split('/');
    return new Date(dateList[1], dateList[0] - 1, 1);
  }
  return '';
};

const setInitialValues = props => {
  const { loan, newLoan, signatureKey, reviewerSignature, reqAmountLimit, reqPercentageLimit } = props;
  return {
    loan: {
      ...loan,
      name: loan.name || '',
      employeeId: loan.employeeId || '',
      grantDate: tranformDate(loan.grantDate),
      startDate: tranformDate(loan.startDate),
      bookkeeperId: loan.bookkeeperId || '',
      directApprove: newLoan,
      reqAmountLimit,
      reqPercentageLimit,
      reviewerSignature,
      signatureKey,
      installments: loan.installments || '',
      balanceId: loan.balanceId || ''
    }
  };
};

const validationSchema = () => {
  return Yup.object().shape({
    loan: Yup.object().shape({
      amount: Yup.number()
        .required('Debes ingresar un monto')
        .positive('Debe ser mayor a 0'),
      employeeId: Yup.string().required('Debes seleccionar un trabajador'),
      grantDate: Yup.date()
        .formatdate()
        .required('Debes ingresar la fecha del otorgamiento del crédito'),
      installments: Yup.number().required('Debes ingresar cantidad de cuotas'),
      reason: Yup.string(),
      signatureKey: Yup.string().when('reviewerSignature', {
        is: val => val,
        then: Yup.string().required('Debes ingresar el pin'),
        otherwise: Yup.string().nullable()
      }),
      startDate: Yup.date()
        .formatdate()
        .required('Debes ingresar la fecha del primer descuento'),
      balanceId: Yup.number().required('Debes seleccionar un tipo de préstamo')
    })
  });
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, dataEntry } = props;
  const loanType = dataEntry ? { origin: 1 } : { origin: 0 };
  const formData = { loan: { ...values.loan, ...loanType } };
  formRequest(formData, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(LoanForm);
