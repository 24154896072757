import React from 'react'
import './print_styles.scss';

const AttendancesControlClockPrint = React.forwardRef(({ headers, rows = [] }, ref) => {

  return (
    <>
      <div className="print_report" ref={ref}>
        <h1>INCIDENTES TÉCNICOS</h1>
        <br />
        <table className='table-print'>
          <thead>
            <tr>
              {headers &&
                headers.map(head => (
                  <>
                    {head.name !== 'ACCIONES' && (
                      <td
                        width={`${head.grow * 100}px`}
                        style={{ ...head.style }}
                        key={`${head.name}`}
                      >
                        {head.name}
                      </td>)}
                  </>
                ))}
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map(row => (
                <tr>
                  <td>{row.device.company.business_name}</td>
                  <td>{row.device.branch_office.name}</td>
                  <td>{row.emp_code}</td>
                  <td>{row.terminal_sn}</td>
                  <td>{row.verify_type}</td>
                  <td>{row.punch_time}</td>
                  <td>{row.date_sync}</td>
                  <td>{row.punch_state}</td>
                  <td>{row.is_sync ? 'Sí' : 'No'}</td>
                  <td>{row.has_error ? 'Sí' : 'No'}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default AttendancesControlClockPrint
