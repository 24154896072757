import React, { useEffect, useState } from 'react';
import { Image, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LinkBtn from '../../Utils/Button/LinkBtn';
import Icon from '../../Icons/Icon';
import { ButtonTooltip } from '../../Utils/Tooltips';
import { useScrollPosition } from '../../../services/hooks';
import { SimpleCenteredModal } from '../../Utils/Modal';
import { renewContractRequest } from '../../../requests/contracts';
import WarningContractModal from './WarningContractModal';

const modalState = {
  title: '',
  body: '',
  show: false,
  size: 'md'
};

const EmployeeTopView = ({ employee, contract = {}, editTo, returnTo, canEdit, employeeEdit }) => {
  const { fileInfo = {} } = employee;
  const [canReactivateContract, setCanReactivateContract] = useState(false);
  const [modal, setModalState] = useState(modalState);
  const mobile = window.innerWidth <= 767;
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const viewEditEmployee = editTo && canEdit;
  const location = useLocation();
  const dispatch = useDispatch();
  const isAdmin = location.pathname
    .split('/')
    .slice(-1)
    .pop();
  const [scrollPos] = useScrollPosition('Y');
  const handleContractStatus = () => {
    setCanReactivateContract(contract?.status === 'finished');
  };

  const handleRenewContract = (renew = false) => {
    setDisabled(true);
    if (renew) {
      renewContractRequest(contract.id, {
        dispatch,
        successCallback: () => {
          history.push(`${editTo}/${employee.id}/edit`, { renewContractId: contract?.id });
        },
        callback: () => {
          setDisabled(false);
          setModalState({ ...modal, show: false });
        }
      });
      return;
    }

    history.push(`${editTo}/${employee.id}/edit`);
    setModalState({ ...modal, show: false });
  };
  const handleWarningContract = () => {
    setModalState({
      ...modal,
      show: true,
      title: 'Advertencia',
      onHide: () => setModalState({ ...modal, show: false }),
      body: <WarningContractModal contract={contract} handleRenewContract={handleRenewContract} disabled={disabled} />
    });
  };

  useEffect(handleContractStatus, [contract?.status]);

  return (
    <>
      <div
        className={`d-md-none sps sps--abv top-box ${isAdmin === 'edit' && 'admin'}
          ${employeeEdit || viewEditEmployee ? 'employee-edit-height' : 'employee'}`}
      />
      <div
        className={`title-box
          ${
            mobile
              ? 'sps--abv employee margin-employee employee-box'
              : `container-profile
          ${location.pathname !== '/profile' ? 'profile-edit' : ''}
          ${scrollPos >= 7 && 'shadow-bottom'}`
          }
      `}
      >
        <Row className="mb-3">
          {returnTo && (
            <LinkBtn variant="circle-dark" className="btn-circle mr-n2 mr-md-n0 mt-1 mt-md-3" block to={returnTo}>
              <Icon icon="chevron-back" />
            </LinkBtn>
          )}
          <Col className="mt-1">
            <Row className="d-flex align-items-center mt-profile-edit">
              <Col xs={12} md={2} xl={1}>
                <div className="ml-1 ml-md-3 ml-xl-4 avatar employee">
                  <div className={fileInfo.fileUrl ? 'content' : 'empty content'}>
                    {fileInfo.fileUrl ? <Image src={fileInfo.fileUrl} /> : <Icon icon="camera" />}
                  </div>
                  <h3 className="show-mobile">{employee.fullName}</h3>
                </div>
              </Col>
              <Col xs={12} md={viewEditEmployee ? 3 : 4} xl={5}>
                <div className="first-info hidden-mobile">
                  <h3 className="text-dark text-uppercase mb-0 font-size-responsive position-top-box employee-edit-top-box">
                    {employee.fullName}
                  </h3>
                  {employee.jobTitle && <p className="work position-top-box">{employee.jobTitle}</p>}
                  <p className="info represent">{employee.email}</p>
                  <p className="hb represent">{employee.parsedPhone}</p>
                </div>
              </Col>
              <Col xs={12} md={viewEditEmployee ? 4 : 5} className="second-info">
                {employee.active ? (
                  <h4 className="text-success text-uppercase mb-0 enrrolment mt-md-n1">Colaborador Activo</h4>
                ) : (
                  <h4 className="text-danger text-uppercase mb-0 enrrolment mt-md-n1">Colaborador Inactivo</h4>
                )}
                <p className="alert-p">
                  <span className={`dot ${employee.pendingSignature ? 'danger' : 'success'}`} />{' '}
                  {employee.pendingSignature ? 'Documentos' : 'Sin documentos'} pendientes de firma
                </p>
                <p className="alert-p">
                  <span className={`dot ${employee.pendingRequests ? 'danger' : 'success'}`} />{' '}
                  {employee.pendingRequests ? 'Solicitudes' : 'Sin solicitudes'} pendientes
                </p>
              </Col>
              {viewEditEmployee && (
                <Col
                  xs={12}
                  md={3}
                  xl={2}
                  className={`${mobile ? 'button-edit-employee margin-icon-edit' : ''} ${employeeEdit &&
                    'button-edit-title-box'}`}
                >
                  <>
                    {canReactivateContract ? (
                      <Button
                        variant="warning"
                        className="d-md-none d-md-block"
                        onClick={handleWarningContract}
                        style={{ width: '100%' }}
                      >
                        Editar Empleado
                      </Button>
                    ) : (
                      <LinkBtn
                        variant="warning"
                        disabled={!employee.id}
                        className={`d-none d-md-block ${employee.id ? '' : 'disabled'}`}
                        block
                        to={employee.id ? `${editTo}/${employee.id}/edit` : ''}
                      >
                        Editar Empleado
                      </LinkBtn>
                    )}
                  </>
                  <div className="d-md-none d-flex justify-content-end">
                    <ButtonTooltip
                      onClick={() => history.push(`${editTo}/${employee.id}/edit`)}
                      variant="circle-warning"
                      className="btn-circle"
                      size="sm"
                      text="Editar"
                    >
                      <Icon icon="pencil" />
                    </ButtonTooltip>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <SimpleCenteredModal
        title={modal.title}
        body={modal.body}
        onHide={modal.onHide}
        show={modal.show}
        size={modal.size}
      />
    </>
  );
};

EmployeeTopView.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  returnTo: PropTypes.string,
  editTo: PropTypes.string
};

EmployeeTopView.defaultProps = {
  returnTo: '',
  editTo: ''
};

export default EmployeeTopView;
