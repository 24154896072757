import React from 'react';
import memoize from 'memoize-one';
import { Button } from 'react-bootstrap';
import { ButtonTooltip, ActiveDot, Icon } from '../../components';

const columns = memoize((clickHandler, permissions) => [
  {
    name: 'Código',
    selector: 'code',
    sortable: true,
    minWidth: '90px',
    maxWidth: '90px'
  },
  {
    name: 'Descripción',
    selector: 'name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'Tipo de Concepto',
    selector: 'translated_balance_type',
    sortable: false,
    grow: '1'
  },
  {
    name: 'Concepto Lre',
    selector: 'translated_concept_lre',
    sortable: false,
    grow: '2'
  },

  {
    name: 'Estado',
    selector: 'active',
    cell: item => (
      <Button variant="circle-info" className="p-0" onClick={() => clickHandler(item, 'change_status')}>
        <ActiveDot item={item} />
      </Button>
    ),
    sortable: true,
    right: true
  },
  {
    name: 'Acciones',
    cell: item => (
      <>
        {permissions.read && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
        {permissions.update && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'edit')}
            variant="circle-warning"
            className="btn-circle"
            size="sm"
            text="Editar"
          >
            <Icon icon="pencil" />
          </ButtonTooltip>
        )}
        {permissions.destroy && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Eliminar"
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
