import React from 'react';
import { useDispatch } from 'react-redux';
import Filters from './Filters';
import { exportBankTransferRequest } from '../../../requests/bankTransfers';
import { downloadFile } from '../../../services/utils';

const WireTransfer = () => {
  const dispatch = useDispatch();

  const successCallback = response => {
    downloadFile(response);
  };

  const handleCreateBankTransfer = params => {
    const { financialInstitution, advancePaymentProcess, conceptType, processDate } = params;

    const bankTransferParams = {
      bank: financialInstitution.code,
      type: conceptType,
      ids: advancePaymentProcess,
      date: processDate,
      ...params
    };
    exportBankTransferRequest({
      dispatch,
      params: { ...bankTransferParams },
      format: financialInstitution.format,
      successCallback
    });
  };
  return (
    <>
      <Filters formRequest={handleCreateBankTransfer} />
    </>
  );
};

export default WireTransfer;
