import React from 'react';
import PropTypes from 'prop-types';
import { InputBase } from '@material-ui/core';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../Icons';

const formatTime = value => {
  const cleaned = value.replace(/[^0-9]/g, '');
  let formatted = '';

  if (cleaned.length > 0) {
    formatted += cleaned.substring(0, 2);
  }
  if (cleaned.length > 2) {
    formatted += `:${cleaned.substring(2, 4)}`;
  }

  return formatted;
};

const FormikTimeInput = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  helpText,
  label,
  margin,
  onChange,
  defaultValue,
  value,
  tooltipText,
  touched
}) => {
  const handleInputChange = event => {
    const formattedValue = formatTime(event.target.value);
    onChange(formattedValue);
  };

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon="help-circle" height="15px" width="15px" />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <InputBase
        type="text"
        value={value || defaultValue}
        className="mui-textfield"
        disabled={disabled}
        onChange={handleInputChange}
        placeholder="HH:MM"
        inputProps={{ maxLength: 5 }}
      />

      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikTimeInput.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  tooltipText: PropTypes.string,
  defaultValue: PropTypes.string
};

FormikTimeInput.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  tooltipText: '',
  defaultValue: ''
};

export default FormikTimeInput;
