import memoize from 'memoize-one';

const LegalReportsColumns = memoize(() => [
  {
    name: 'Razon Social',
    selector: 'company_name',
    sortable: false,
    grow: '2'
  },
  {
    name: 'Rut del Empleador',
    selector: 'company_national_id',
    sortable: false,
    grow: '2'
  },
  {
    name: 'Nombre',
    selector: 'full_name',
    sortable: false,
    grow: '2'
  },
  {
    name: 'Rut Colaborador',
    selector: 'national_identification',
    sortable: false,
    grow: '2'
  },
  {
    name: 'Lugar de Prestación de Servicio',
    selector: 'branch_offices_name',
    sortable: false,
    grow: '2'
  },
  {
    name: 'Banda Horaria',
    selector: 'is_article_27',
    sortable: false,
    grow: '2'
  }
]);

export default LegalReportsColumns;
