import React from 'react';
import { MainLogo } from '../../components';

const About = ({ version }) => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div style={{ paddingBottom: 25 }}>
        <MainLogo />
        <p className="text-center title">Versión {version}</p>
      </div>
      <p>Peoplework © {currentYear}. Todos los derechos reservados</p>
      <p className="mt-4">
        PeopleWork SpA 77.615.116-5
        <br />
        Los Productores 4436 Comuna: Huechuraba, Santiago
        <br />
        Fono: 227192790 Web:{' '}
        <a href="https://www.peoplework.cl" target="_blank" rel="noopener noreferrer">
          https://www.peoplework.cl
        </a>
      </p>
    </>
  );
};

export default About;
