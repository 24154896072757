import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const AttendancesControlClockShow = ({ data }) => {
  return (
    <>

      <Card>
        <Card.Body>
          <Row>
            <Col md={12}>
              <div className="info">
                <span className="type">Empresa</span>
                <span className="align-right" />
                <span className="answer"> {data.device.company.business_name}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">L. de P. de Servicios:</span>
                <span className="align-right" />
                <span className="answer"> {data.device.branch_office.name}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Cod. Empleado:</span>
                <span className="align-right" />
                <span className="answer"> {data.emp_code}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Serial del Terminal:</span>
                <span className="align-right" />
                <span className="answer"> {data.terminal_sn}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Tipo de Verificación:</span>
                <span className="align-right" />
                <span className="answer"> {data.verify_type}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Hora Marcación:</span>
                <span className="align-right" />
                <span className="answer"> {data.punch_time}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Tipo de Marcación:</span>
                <span className="align-right" />
                <span className="answer"> {data.punch_state}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Sincronizado:</span>
                <span className="align-right" />
                <span className={data.is_sync ? 'answer text-success' : 'answer text-danger'}> {
                  data.is_sync ? 'Sì' : 'No'}
                </span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">R. de PeopleWork:</span>
                <span className="align-right" />
                <span className="answer"> {data.api_response}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>


    </>
  )
}

export default AttendancesControlClockShow