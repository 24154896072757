import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormikInput, Icon, LinkBtn } from '../../components';
import AttendanceHashDataTable from './AttendanceHashDataTable';

const AttendanceHashIndex = ({ location }) => {
  const [hash, setHash] = useState('');
  const [moreData, setMoreData] = useState(false);
  const params = new URLSearchParams(location.search);
  const haveTab = params.get('tab');
  return (
    <>
      <Row className="m-top mb-4">
        <Col>

          <div className="d-flex align-items-center">
            {haveTab && (
              <LinkBtn variant="circle-dark" className="btn-circle mt-2" block to="/dt/welcome">
                <Icon icon="chevron-back" />
              </LinkBtn>
            )}
            <div className="d-flex flex-column ml-1">
              <h2 className="mt-3">Busqueda de Asistencia por Hash</h2>

            </div>
          </div>
        </Col>
        <Col md={3}>
          <FormikInput
            label="Hash"
            name="rut"
            placeholder="Ingresa un Hash"
            onChange={data => {
              setHash(data ? data.target.value : '');
              setMoreData(!moreData);
            }}
            value={hash}
          />
        </Col>
      </Row>
      <AttendanceHashDataTable moreData={moreData} customParams={{ filter_by_hash: hash }} />
    </>
  );
};

export default AttendanceHashIndex;
