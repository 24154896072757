import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import memoize from 'memoize-one';
import { useReactToPrint } from 'react-to-print';
import { ComponentDataTable, ButtonTooltip, Icon, SimpleCenteredModal, DefaultModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import {
  deactivateAdminAttendanceControlClockRequest,
  deactivateMassiveAdminAttendanceControlClockRequest,
  debounceIndexAdminAttendancesControlClockRequest,
  exportAdminAttendancesControlClockRequest
} from '../../../requests/adminAttendancesControlClock';
import AttendancesControlClockShow from './AttendancesControlClockShow';
import AttendancesControlClockFilter from './AttendancesControlClockFilter';
import { downloadFile } from '../../../services/utils';
import './styles.scss'
import AttendancesControlClockPrint from './AttendancesControlClockPrint';

const customStyles = {
  rows: {
    style: {
      minHeight: '45px'
    }
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      borderLeft: '1px solid rgb(212, 212, 212)',
      borderRight: '1px solid rgb(212, 212, 212)',
      borderTop: '1px solid rgb(212, 212, 212)',
      borderBottom: '1px solid rgb(212, 212, 212)'
    }
  }
};

const columns = memoize((clickHandler, isDt) => [
  {
    name: 'EMPRESA',
    selector: 'company',
    cell: item => item.device.company.business_name,

    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'L. DE P. DE SERVICIOS',
    selector: 'branch_office',
    cell: item => item.device.branch_office.name,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'COD EMPLEADO',
    selector: 'emp_code',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'SERIAL DEL TERMINAL',
    selector: 'terminal_sn',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TIPO DE VERIFICACIÓN',
    selector: 'verify_type',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'HORA DE MARCACIÓN',
    selector: 'punch_time',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TÉRMINO DE LA SINCRONIZACIÓN',
    selector: 'date_sync',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TIPO DE MARCACIÓN',
    selector: 'punch_state',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'SINCRONIZADO',
    selector: 'is_sync',
    cell: item => (item.is_sync ? 'Si' : 'No'),
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ERROR',
    selector: 'has_error',
    cell: item => (item.has_error ? 'Si' : 'No'),
    sortable: false,
    grow: '1',
    center: true
  },

  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        {!isDt && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'change_status')}
            variant={`circle-${item.is_sync ? 'danger' : 'success'}`}
            className="btn-circle"
            size="sm"
            text={item.is_sync ? 'Desactivar' : 'Activar'}
          >
            <Icon icon="archive" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '160px'
  }
]);

const AttendancesControlClockDataTable = ({ isDt }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [modalItem, setModalItem] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [modalSize, setModalSize] = useState('md');
  const [amount, setAmount] = useState(0);
  const [simpleModalTitle, setSimpleModalTitle] = useState();
  const [simpleModalBody, setSimpleModalBody] = useState(<></>);
  const [simpleModalShow, setSimpleModalShow] = useState();
  const [moreData, setMoreData] = useState(false);
  const [query, setQuery] = useState();
  const [rowsDataPrint, setRowsDataPrint] = useState([]);
  const componentRef = useRef();

  const [modalState, setModalState] = useState({
    show: false,
    type: '',
    text: '',
    actionType: ''
  });
  const { currentCompany } = useSelector(state => state.auth);
  const [searchFirstStart, setSearchFirstStart] = useState(!isDt);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setAmount(metadata.amount);
  };

  const handleRequest = async params => {
    const queryParams = {
      ...params,
      ...(isDt ? { ...query, filter_company: currentCompany.id } : query)
    };

    setOnRequest(true);
    debounceIndexAdminAttendancesControlClockRequest({
      dispatch,
      params: queryParams,
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  const handleShow = data => {
    setSimpleModalTitle(`Log Reloj Control`);
    setSimpleModalBody(<AttendancesControlClockShow data={data} />);
    setSimpleModalShow(true);
  };

  const handleChangeStatus = async item => {
    setOnRequest(true);
    deactivateAdminAttendanceControlClockRequest(item.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        setModalShow(false);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Error al realizar la acción' }));
        setModalShow(false);
      },
      callback: () => setMoreData(!moreData)
    });
  };


  const handleMassiveChangeStatus = async ids => {
    setOnRequest(true);
    deactivateMassiveAdminAttendanceControlClockRequest({
      dispatch,
      params: { ids },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        setModalShow(false);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Error al realizar la acción' }));
        setModalShow(false);
      },
      callback: () => setMoreData(!moreData)
    });
  };



  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        setModalSize('lg');
        handleShow(item);
        break;
      case 'change_status':
        setModalItem(item);
        setModalSize('md');
        setModalTitle('Cambiar Estado del Log');
        setModalBody('¿Esta seguro que desea cambiar el estado de este registro?');
        setModalShow(true);
        setModalAction(() => handleChangeStatus);
        break;

      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'company':
        return { sort_company: name };
      case 'branch_office':
        return { sort_branch_office: name };
      default:
        return { sort_column: name };
    }
  };

  const printTypeText = {
    print: '¿Estás seguro que quieres imprimir el reporte de Incidentes Técnicos?',
    all: '¿Estás seguro que quieres exportar el reporte de Incidentes Técnicos en formato '
  };

  const handleOptionSelection = (action, actionType) => {
    switch (true) {
      case ['download_excel', '.xlsx'].includes(action):
        setModalState({ text: `${printTypeText.all} Excel?`, type: '.xlsx', show: true, actionType });
        break;
      case ['download_docx', '.docx'].includes(action):
        setModalState({ text: `${printTypeText.all} Word?`, type: '.docx', show: true, actionType });
        break;
      case ['download_pdf', '.pdf'].includes(action):
        setModalState({ text: `${printTypeText.all} Pdf?`, type: '.pdf', show: true, actionType });
        break;
      case ['print', '.print'].includes(action):
        setModalState({ text: printTypeText.print, type: '.print', show: true, actionType });
        break;
      default:
    }
  };

  const formRequest = values => {
    setQuery({ range_filter: values.rangeDate });
    if (['.docx', '.xlsx', '.pdf', '.print'].includes(values.type)) {
      handleOptionSelection(values.type, 'download');
      return;
    }
    setSearchFirstStart(true);
    setMoreData(!moreData);
  };

  const handleChangeSync = () => {
    const ids = selectedRows.reduce((acc, current) => {
      acc.push(current.id);
      return acc;
    }, []);
    setModalItem(ids);
    setModalSize('md');
    setModalTitle('Cambiar Estado de Sincronización');
    setModalBody('¿Esta seguro que desea cambiar el estado de sincronización de estos registros?');
    setModalShow(true);
    setModalAction(() => handleMassiveChangeStatus);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };
  const isDownloableFile = format => {
    return ['.xlsx', '.docx', '.pdf'].includes(format);
  };

  const handleBeforePrint = () => {
    setOnRequest(false);
  };

  const printInfo = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: handleBeforePrint,
    pageStyle: `
    @media print {
      @page {
        size: A4 landscape !important;
        margin: 10mm !important;
      }

      body {
        margin: 0 !important;
        padding: 10mm !important;
      }
    }
  `,
  });


  const handleAttendanceReportRequest = async (format, requestType = 'json') => {
    setOnRequest(true);
    const responseType = format === '.docx' ? 'blob' : requestType;
    const newFormat = format === '.print' ? '' : format;
    exportAdminAttendancesControlClockRequest({
      dispatch,
      params: { ...query, paginate: false },
      format: newFormat,
      responseType,
      successCallback: response => {
        if (isDownloableFile(format)) {
          downloadFile(response);
        }
        if (format === '.print') {
          setRowsDataPrint(response.data);
          printInfo();
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  return (
    <>
      <div className={isDt ? 'attendances_control_clock' : ''} >
        {isDt && <AttendancesControlClockFilter formRequest={formRequest} />}

        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(handleActions, isDt)}
          data={employees}
          moreData={moreData}
          searchFirstStart={searchFirstStart}
          totalRows={amount}
          handleSortCase={sortColumnCase}
          resourceRequest={handleRequest}
          pointerOnHover
          withMassActions
          selectableRows={!isDt}
          onSelectedRowsChange={handleSelectedRows}
          massActions={
            <>
              {!isDt && (<ButtonTooltip
                variant="circle-primary"
                className="btn-circle"
                text="Desactivar Sincronización"
                disabled={!selectedCount || onRequest}
                onClick={() => handleChangeSync()}
              >
                <Icon icon="archive" />
              </ButtonTooltip>)}
            </>

          }
        />
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={() => setModalShow(false)}
          handleConfirm={() => modalAction(modalItem)}
          disabled={onRequest}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
        <SimpleCenteredModal
          title={simpleModalTitle}
          body={simpleModalBody}
          show={simpleModalShow}
          size={modalSize}
          onHide={() => setSimpleModalShow(false)}
        />

        <DefaultModal
          title={modalState.type === '.print' ? 'Imprimir Reporte de Incidentes Técnicos' : 'Exportar Reporte de Incidentes Técnicos'}
          body={modalState.text}
          show={modalState.show}
          handleClose={() => setModalState(oldState => ({ ...oldState, show: false }))}
          handleConfirm={() => {
            handleAttendanceReportRequest(modalState.type, 'text');
            setModalState(oldState => ({ ...oldState, show: false }));
          }}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />

        <div style={{ display: 'none' }}>
          <AttendancesControlClockPrint
            ref={componentRef}
            headers={columns(handleActions, isDt)}
            rows={rowsDataPrint}
            customStyles={customStyles}
          />
        </div>
      </div >
    </>
  );
};

export default AttendancesControlClockDataTable;
