import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CompaniesModal, SimpleCenteredModal } from '../../../components';
import CompaniesModalDt from '../../../components/Utils/Sidebar/CompaniesModalDt';
import './style.scss';

const SelectCurrentCompanyIndex = () => {
  const { user } = useSelector(state => state.auth);
  const [modalSize, setModalSize] = useState('md');
  const [modalBody, setModalBody] = useState(<CompaniesModal />);
  const [className, setClassName] = useState();
  const [bodyClass, setBodyClass] = useState('scroll-modal');

  const handleModalBody = () => {
    if (user?.role === 'dt') {
      setClassName('modal-content-dt');
      setBodyClass('');
      setModalBody(<CompaniesModalDt handleModalSize={setModalSize} />);
    }
  };

  useEffect(handleModalBody, [user]);

  return (
    <SimpleCenteredModal
      size={modalSize}
      backdrop="static"
      title="Selecciona una empresa"
      closeButton={false}
      body={modalBody}
      bodyClass={bodyClass}
      show
      showHeader={user?.role !== 'dt'}
      dialogClassName={className}
    />
  );
};

export default SelectCurrentCompanyIndex;
