import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import memoize from 'memoize-one';
import { createConsumer } from "@rails/actioncable";
import {
  ComponentDataTable,
  ActiveDot,
  ButtonTooltip,
  Icon,
  SimpleCenteredModal,
  DefaultModal
} from '../../../components';
import {
  deactivateAdminDeviceRequest,
  debounceIndexAdminDevicesRequest,
  deleteAdminDeviceRequest,
  showAdminDeviceRequest
} from '../../../requests/adminDevices';
import ControlClockShow from './ControlClockShow';
import { sendAlert } from '../../../actions/utils';
import SyncControlClockForm from './SyncControlClockForm';
import { syncAttendancesRequest } from '../../../requests/adminAttendancesControlClock';
import API_CONFIG from '../../../config/configurations';

const columns = memoize(clickHandler => [
  {
    name: 'SERIAL',
    selector: 'sn',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ALIAS',
    selector: 'alias',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'DIRECCIÓN IP',
    selector: 'ip_address',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'EMPRESA',
    selector: 'company',
    cell: item => item.company?.business_name,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'LUGAR DE PRESTACIÓN DE SERVICIO',
    selector: 'branch_office',
    cell: item => item.branch_office?.name,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'sync')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Sincronizar"
          disabled={!item.active}
        >
          <Icon icon="clock" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
        >
          <Icon icon="pencil" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'change_status')}
          variant={`circle-${item.active ? 'danger' : 'success'}`}
          className="btn-circle"
          size="sm"
          text={item.active ? 'Desactivar' : 'Activar'}
        >
          <Icon icon="archive" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text='Eliminar'
        >
          <Icon icon="trash" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '160px'
  }
]);

const ControlClockDataTable = () => {
  const jobIdStorage = sessionStorage.getItem('job_id');
  const [employees, setEmployees] = useState([]);
  const [modalItem, setModalItem] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [modalSize, setModalSize] = useState('md');
  const [amount, setAmount] = useState(0);
  const [simpleModalTitle, setSimpleModalTitle] = useState();
  const [simpleModalBody, setSimpleModalBody] = useState(<></>);
  const [simpleModalShow, setSimpleModalShow] = useState();
  const [moreData, setMoreData] = useState(false);
  const [jobId, setJobId] = useState(jobIdStorage === null ? '' : jobIdStorage);
  const [onRequest, setOnRequest] = useState(false);
  const { wsControlClock } = API_CONFIG;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setAmount(metadata.amount);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    debounceIndexAdminDevicesRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  const jobAttendancesNotifications = () => {
    if (!jobId) return;
    const cable = createConsumer(`${wsControlClock()}cable`);
    const subscription = cable.subscriptions.create("JobAttendancesSyncChannel", {
      received(data) {
        if (data.job_id === jobId) {
          sessionStorage.removeItem('job_id');
          setOnRequest(false)
          const kind = data.status === 'fail' ? 'error' : 'success'
          dispatch(sendAlert({ kind, message: data.message }));
        }
      }
    });
    // eslint-disable-next-line consistent-return
    return () => {
      subscription.unsubscribe();
      cable.disconnect();
    };
  }

  useEffect(jobAttendancesNotifications, [jobId]);


  const handleSync = async params => {
    setOnRequest(true);
    syncAttendancesRequest({
      dispatch,
      params,
      successCallback: response => {
        setJobId(response.data.job_id)
        sessionStorage.setItem('job_id', response.data.job_id);
      },
      callback: () => {
        setSimpleModalShow(false)
      }
    });
  };

  const handleSuccessShow = response => {
    setSimpleModalTitle(`Reloj Control`)
    setSimpleModalBody(<ControlClockShow data={response.data} />);
    setSimpleModalShow(true);
  }

  const handleShow = async id => {
    showAdminDeviceRequest(id, {
      params: {
        method: 'show'
      },
      dispatch,
      successCallback: handleSuccessShow,
    })
  }

  const handleChangeStatus = async item => {
    setOnRequest(true);
    deactivateAdminDeviceRequest(item.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        setModalShow(false)
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Error al realizar la acción' }));
        setModalShow(false)
      },
      callback: () => setMoreData(!moreData)

    })
  }

  const handleDestroy = async item => {
    setOnRequest(true);
    deleteAdminDeviceRequest(item.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        setModalShow(false)
      },
      failureCallback: error => {
        dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
        setModalShow(false)
      },
      callback: () => setMoreData(!moreData)

    })
  }

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        setModalSize('md')
        handleShow(item.id);
        break;
      case 'sync':
        setModalSize('md')
        setSimpleModalTitle('Sincronizar Reloj Control')
        setSimpleModalBody(<SyncControlClockForm formRequest={handleSync} deviceId={item.id} />)
        setSimpleModalShow(true)
        break;
      case 'edit':
        history.push(`/admin/control_clock/${item.id}/edit`);
        break;
      case 'change_status':
        setModalItem(item)
        setModalSize('md')
        setModalTitle('Cambiar Estado del Reloj Control')
        setModalBody('¿Esta seguro que desea cambiar el estado de este registro?')
        setModalShow(true)
        setModalAction(() => handleChangeStatus)
        break;
      case 'destroy':
        setModalItem(item)
        setModalSize('md')
        setModalTitle('Eliminar Reloj Control')
        setModalBody('¿Esta seguro que desea Eliminar el Reloj Control?')
        setModalShow(true)
        setModalAction(() => handleDestroy)
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'company':
        return { sort_company: name };
      case 'branch_office':
        return { sort_branch_office: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={jobIdStorage !== null ? true : onRequest}
        columns={columns(handleActions)}
        data={employees}
        moreData={moreData}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        resourceRequest={handleRequest}
        pointerOnHover
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        size={modalSize}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default ControlClockDataTable;
