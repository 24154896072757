import React from 'react';
import { Table } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { ButtonTooltip } from '../Utils/Tooltips';
import Icon from '../Icons';
import DocumentPreview from '../../screens/DocumentPreview/DocumentPreview';
import AttendanceProofRender from './AttendanceProofRender';

const WorkdaysFilterDataTable = ({
  attendances,
  isArticle22,
  setDocumentModalBody,
  setDocumentModalShow,
  employeeId
}) => {
  const selectedStyle = {
    backgroundColor: '#FF0000',
    color: '#FFFFFF'
  };

  const styleNotWorking = {
    backgroundColor: '#F3C342',
    color: '#515151'
  };

  const styleArticle22 = {
    backgroundColor: '#FFA07A',
    color: '#515151'
  };

  const styleHasReplaced = {
    backgroundColor: '#B0E0E6',
    color: '#515151'
  };

  const styleButtonTooltip = {
    width: 'auto',
    height: 'auto',
    borderRadius: '10%',
    display: 'initial'
  };

  const appendValueIfPresent = (value, append) => {
    return value && value.length > 0 ? `${append} ${value}` : value;
  };

  const renderResult = attendance => {
    let styles = null;
    if (attendance.non_working_day_assis) {
      styles = styleNotWorking;
    }
    if (attendance.attendance_incomplete) {
      styles = selectedStyle;
    }
    if (isArticle22) {
      styles = styleArticle22;
    }
    if (attendance.has_replaced || (employeeId === 2570 && attendance.date_formatted === '17/05/23')) {
      styles = styleHasReplaced;
    }

    const renderObservation = observation => (
      <div dangerouslySetInnerHTML={{ __html: observation.replace(/\n/g, '<br>') }} />
    );

    const renderAttendnaceActivities = activities => (
      <div dangerouslySetInnerHTML={{ __html: activities.replace(/\n/g, '<br>') }} />
    );

    const anomalies = appendValueIfPresent(attendance.anomalies, '-') || '';

    return (
      <tr style={styles}>
        <td className="text-center align-middle">{attendance.date_formatted}</td>
        <td className="text-center align-middle">{attendance.shift}</td>
        <td className="text-center align-middle">{attendance.attendance_day}</td>
        <td className="text-center align-middle">{attendance.shift_break}</td>
        <td className="text-center align-middle">{attendance.attendance_break}</td>
        {
          <td
            className="text-center align-middle"
            // eslint-disable-next-line react/no-danger

            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(anomalies) }}
          />
        }
        <td className="text-center align-middle">{appendValueIfPresent(attendance.hours_extra, '+')}</td>
        <td className="text-center align-middle">{renderAttendnaceActivities(attendance.attendnace_activities)}</td>
        <td className="text-center align-middle">
          <>
            {attendance?.document && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={attendance?.overtime_ag_document?.id}
                      employeeId={attendance?.overtime_ag_document?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="arial-dt"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Pacto H.E"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Pacto H.E
              </ButtonTooltip>
            )}

            {attendance?.permission_document && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={attendance?.permission_document?.id}
                      employeeId={attendance?.permission_document?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="arial-dt"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Permiso"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Permiso
              </ButtonTooltip>
            )}

            {attendance?.vacation_docuemnt && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={attendance?.vacation_docuemnt?.id}
                      employeeId={attendance?.vacation_docuemnt?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="arial-dt"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Permiso"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Vacación
              </ButtonTooltip>
            )}

            {<AttendanceProofRender item={{ ...attendance, justification: '' }} />}

            {renderObservation(attendance.observation)}
          </>
        </td>
        <td className="text-center align-middle">{attendance.exeptional_workday_number}</td>
      </tr>
    );
  };

  const tableHeader = () => {
    return [
      'Fecha',
      'Jornada Pactada',
      'Marcaciones Jornada',
      'Colación',
      'Marcaciones Colación',
      'Tiempo Faltante',
      'Tiempo Extra',
      'Otras Marcaciones',
      'Observaciones',
      'Autorización Jornada'
    ];
  };

  const renderData = () => {
    return (
      <>
        {attendances.map(workday => (
          <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
            <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
              <tr className="rdt_TableCol">
                {tableHeader().map(itemHeader => (
                  <th className="text-center align-middle" key={itemHeader}>
                    {itemHeader}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{workday.attendances.map(attendance => renderResult(attendance))}</tbody>
            <tfoot style={{ backgroundColor: '#EBEBEB' }}>
              <tr>
                <td className="text-center align-middle"> Totales Semanales </td>
                <td className="text-center align-middle"> {workday.total_times[0].parsed_shift_time} </td>
                <td className="text-center align-middle"> {workday.total_times[0].parsed_attendance_day_t} </td>
                <td className="text-center align-middle"> {workday.total_times[0].parsed_shift_break_time} </td>
                <td className="text-center align-middle"> {workday.total_times[0].p_attendance_break_t} </td>
                <td className="text-center align-middle">
                  {appendValueIfPresent(workday.total_times[0].p_anomalies_time, '-')}
                </td>
                <td className="text-center align-middle">
                  {appendValueIfPresent(workday.total_times[0].p_hours_extra_time, '+')}
                </td>
                <td className="text-center align-middle">&nbsp;</td>
                <td className="text-center align-middle" colSpan={2}>
                  Compensación: {workday.total_times[0].compensation}
                </td>
              </tr>
            </tfoot>
          </Table>
        ))}
      </>
    );
  };

  return <>{renderData()}</>;
};

export default WorkdaysFilterDataTable;
