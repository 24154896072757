import React from 'react';
import { FormCheck } from 'react-bootstrap';

const DefaultCheckBox = ({
  field,
  label,
  customDivClass,
  customLabelClass,
  inputClass = '',
  activeClass = '',
  inactiveClass = '',
  disabled
}) => (
  <div className={`d-flex align-items-center form-group ${customDivClass}`}>
    <div className="custom-control custom-checkbox1">
      <FormCheck.Input {...field} id={field.name} checked={field.value} disabled={disabled} className={inputClass} />
      <FormCheck.Label
        className={`button ${customLabelClass} ${field.value ? activeClass : inactiveClass}`}
        htmlFor={field.name}
      >
        {label}
      </FormCheck.Label>
    </div>
  </div>
);

export default DefaultCheckBox;
