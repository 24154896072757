import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import es from '../../../DataLoad/FormOptions';
import { DualList, FormikSelect, FormikTimePicker, Icon } from '../../../../components';
import { debounceIndexConceptRequest } from '../../../../requests/balances';
import PayrollBalancesEmployeesTable from './PayrollBalancesEmployeesTable';
import EmployeeFilterModal from './EmployeeFilterModal';
import BalanceTypeOptions from './BalanceTypeOptions';
import './custom.css';
import { getEmployeeBalances } from '../../../../requests/payrollProcessBalances';

const PayrollBalancesForm = ({
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  isSubmitting,
  payrollProcess,
  setActiveComponent,
  setPayrollProcess
}) => {
  const {
    payrollBalance: { balanceIds, employees }
  } = values;
  const { payrollBalance } = values;
  const [listBalances, setListBalances] = useState([]);
  const [balanceOptions, setbBalanceOptions] = useState([]);
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(0);
  const fetchBalances = () => {
    debounceIndexConceptRequest({
      dispatch,
      params: {
        actives: true,
        filter_type: payrollProcess.balanceType,
        payroll_params: true,
        filter_contractual: false
      },
      successCallback: response => {
        let optionsList = [];
        const { data } = response;
        setListBalances(data);
        data.forEach(item => {
          item.options.forEach(option => {
            optionsList = [...optionsList, { ...option, amount: '0' }];
          });
        });
        setbBalanceOptions(optionsList);
      }
    });
  };

  const getBalancesProps = balancesSelected => {
    return balanceOptions.filter(balance => balancesSelected.includes(balance.value));
  };

  const disableBalances = (balancesSelected, option) => {
    return balancesSelected.length === 5 ? !balancesSelected.includes(option.value) : false;
  };

  const existsBalances = () => {
    return payrollBalance?.balanceIds?.length > 0;
  };

  const balanceAmount = (balance, employee) => {
    const existingEmployee = payrollBalance.employees.find(item => item.id === employee.id);
    if (existingEmployee && existingEmployee.balances) {
      const existingBalance = existingEmployee.balances.find(item => item.value === balance.value);
      return existingBalance ? existingBalance.amount : 0;
    }
    return '0';
  };

  const handleBalances = (balancesSelected, updateEmployee = false) => {
    const balancesToUpdate = [];
    if (updateEmployee && existsBalances()) {
      balancesToUpdate.push(...payrollBalance.balanceIds);
    }

    if (!updateEmployee) {
      setFieldValue('payrollBalance[balanceIds]', balancesSelected);
      balancesToUpdate.push(...balancesSelected);
    }
    setShowSpinner(true);
    getEmployeeBalances({
      dispatch,
      params: {
        filter_payroll_process_ids: payrollProcess.id,
        filter_employee_ids: payrollBalance?.employeeIds.map(item => item.id) || [],
        filter_balance_ids: balancesToUpdate,
        not_work_contract: true,
        filter_active: true
      },
      successCallback: ({ data }) => {
        const currentEmployeeBalances = data.data;
        const employeeBalancesObject = getBalancesProps(balancesToUpdate);
        const employeesWithBalances = employees.map(employee => {
          const employeeBalances = employeeBalancesObject.map(balance => {
            const balanceObject = currentEmployeeBalances.find(
              item => item.balance.value === balance.value && item.employee.id === employee.id
            );
            return {
              ...balance,
              amount: balanceObject ? balanceObject.amount : balanceAmount(balance, employee)
            };
          });
          return {
            ...employee,
            balances: [...employeeBalances]
          };
        });
        setFieldValue('payrollBalance[employees]', employeesWithBalances);
        setShowSpinner(false);
        if (payrollBalance.employeeIds.length > 0 && payrollBalance.balanceIds.length > 0) {
          const limitedBalances = listBalances.map(balance => ({
            ...balance,
            options: balance.options.map(option => ({ ...option, disabled: disableBalances(balancesToUpdate, option) }))
          }));
          setListBalances(limitedBalances);
        }
      }
    });
  };

  const handleDisableDate = periodSelected => {
    if (periodSelected === 'permanent') {
      setFieldValue('payrollBalance[endDate]', null);
      setFieldTouched('payrollBalance[endDate]', false);
    }
  };

  useEffect(fetchBalances, []);
  useEffect(() => {
    handleBalances(payrollBalance.balanceIds, true);
    // eslint-disable-next-line
  }, [payrollBalance.employeeIds, payrollBalance.balanceIds]);
  return (
    <Form>
      <Row className="mt-2">
        <Col />
        <Col md={1}>
          <Button
            variant="circle-dark"
            className="btn-circle mt-3"
            block
            onClick={() => {
              setPayrollProcess(current => ({ ...current, balanceType: null }));
              setActiveComponent('BalancesTable');
            }}
          >
            <Icon icon="chevron-back" />
          </Button>
        </Col>
        <Col md={3}>
          <Button className="mt-3" variant="primary" block type="submit" disabled={isSubmitting}>
            Guardar
          </Button>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}>
          <EmployeeFilterModal
            payrollProcess={payrollProcess}
            customParams={{
              filter_payroll_process: payrollProcess.id
            }}
            payrollBalance={payrollBalance}
            balanceAmount={balanceAmount}
            showInput
            updateBalances={handleBalances}
          />
        </Col>
        <Col md={4}>
          <Field name="payrollBalance[periodicity]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Periodo"
                placeholder="Selecionar Periodo.."
                defaultValue={payrollBalance.periodicity}
                options={BalanceTypeOptions}
                setFieldTouched={() => setFieldTouched(field.name)}
                onChange={data => {
                  handleDisableDate(data.value || null);
                  setFieldValue(field.name, data ? data.value : '');
                }}
                isClearable
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="payrollBalance[startDate]">
            {({ field }) => (
              <FormikTimePicker
                {...field}
                abbr
                setLocale
                minDate={new Date(payrollProcess.year, payrollProcess.month_number, 1)}
                maxDate={new Date(payrollProcess.year, payrollProcess.month_number, 1)}
                label="Desde"
                placeholder="mm/aaaa"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                selected={payrollBalance.startDate || null}
                onChange={date => {
                  setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="payrollBalance[endDate]">
            {({ field }) => (
              <FormikTimePicker
                {...field}
                abbr
                setLocale
                minDate={new Date(payrollProcess.year, payrollProcess.month_number, 1)}
                label="Hasta"
                placeholder="mm/aaaa"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                selected={payrollBalance.endDate || null}
                onChange={date => {
                  setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={payrollBalance.periodicity === 'permanent'}
              />
            )}
          </Field>
        </Col>
        <Col className="mt-4" xs={12}>
          <DualList
            es={es}
            disabled={payrollBalance.employeeIds.length === 0}
            filterPlaceholder="Buscar Conceptos..."
            options={listBalances}
            selected={payrollBalance.employeeIds.length === 0 ? [] : balanceIds}
            name="payrollBalance[balanceIds]"
            onChange={selected => {
              setFieldValue('payrollBalance[balanceIds]', selected || []);
            }}
            className="hide-all-buttons"
            error={getIn(errors, 'payrollBalance[balanceIds]')}
            touched={getIn(touched, 'payrollBalance[balanceIds]')}
          />
          <small className="text-info form-text">
            <b>Atención!. Solo puedes seleccionar hasta 5 conceptos</b>
          </small>
        </Col>
        {showSpinner ? (
          <Col className="div-content mt-5 mb-5">
            <div className="containerSpinnerLoadDash" style={{ position: 'absolute', height: '100%' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          </Col>
        ) : (
          <Col className="mb-5 mt-5">
            {employees.length <= 0 ? (
              <div style={{ height: 177, textAlign: 'center' }} className="div-center">
                No existen registros...
              </div>
            ) : (
              <PayrollBalancesEmployeesTable
                employees={employees}
                payrollBalanc={payrollBalance}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Col>
        )}
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { payrollBalance } = props;
  return {
    payrollBalance
  };
};
const validationSchema = Yup.object().shape({
  payrollBalance: Yup.object().shape({
    employeeIds: Yup.array().min(1, 'Debes escoger al menos un colaborador.'),
    balanceIds: Yup.array()
      .min(1, 'Debes escoger al menos un concepto.')
      .max(5, 'No puedes escoger más de 5 conceptos.'),
    periodicity: Yup.string().required('Debes seleccionar un periodo.'),
    startDate: Yup.string('Debes seleccionar una fecha de inicio.').required('Debes seleccionar una fecha de inicio.'),
    endDate: Yup.string()
      .when('periodicity', {
        is: val => val !== 'permanent',
        then: Yup.string('Debes seleccionar una fecha fin').required('Debes seleccionar una fecha fin')
      })
      .nullable(true),
    employees: Yup.array()
      .of(
        Yup.object().shape({
          balances: Yup.array().of(
            Yup.object().shape({
              amount: Yup.string()
                .required('Debes ingresar un monto')
                .test('amount', 'Debes ingresar un monto valido.', value => {
                  return +value.replaceAll('.') > 0;
                })
            })
          )
        })
      )
      .min(1, 'Debes escoger al menos un colaborador.')
  })
});

const handleSubmit = (values, { props, setSubmitting, resetForm, setFieldValue }) => {
  const { formRequest, payrollProcess } = props;
  const data = {
    payrollBalanceEmployees: {
      balanceDetails: []
    }
  };
  values.payrollBalance.employees.forEach(employee => {
    employee.balances.forEach(balance => {
      data.payrollBalanceEmployees.balanceDetails.push({
        employeeId: employee.id,
        balanceId: balance.value,
        periodicity: values?.payrollBalance?.periodicity,
        startDate: values?.payrollBalance?.startDate,
        endDate: values?.payrollBalance?.endDate,
        amount: balance.amount,
        balance,
        employee: {
          ...employee,
          ...employee.employee,
          balances: null
        },
        payrollProcessId: payrollProcess.id
      });
    });
  });
  formRequest(data, setSubmitting, resetForm, setFieldValue);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false,
  validateOnChange: false,
  validateOnMount: false,
  validateOnBlur: false
})(PayrollBalancesForm);
