import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { indexSelectEmployeesRequest, showEmployeeRequest, updateEmployeeRequest } from '../../requests/employees';
import { sendAlert } from '../../actions/utils';
import { validateToken } from '../../actions/auth';
import EmployeeTopView from '../../components/Employee/EmployeeShow/EmployeeTopView';
import { AbilityContext } from '../../config/abilityContext';
import EmployeeForm from './EmployeeForm/EmployeeForm';
import basicEmployee from './employee';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { indexCustomFieldsRequest } from '../../requests/customFields';

const EmployeeEdit = ({ match, location }) => {
  const ability = useAbility(AbilityContext);
  const [employee, setEmployee] = useState(basicEmployee);
  const [signSubstitutes, setSignSubstitutes] = useState([]);
  const [contractCustomFields, setContractCustomFields] = useState([]);
  const [employeeCustomFields, setEmployeeCustomFields] = useState([]);
  const { currentEmployee, currentCompany: selectedCompany } = useSelector(state => state.auth);
  const { companyModules } = selectedCompany;
  const remunerations = companyModules.includes('remunerations');
  const currentCompany = localStorage.getItem('currentCompany');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const handleRequestAndSuccessCustomFields = async params => {
      const filterCondition = field => {
        return field.company_id === Number(currentCompany) && field.active;
      };

      indexCustomFieldsRequest({
        dispatch,
        params,
        successCallback: response => {
          const { data } = response.data;

          const contractFields = data.filter(
            field => filterCondition(field) && field.field_display === 'contract_display'
          );
          const employeeFields = data.filter(
            field => filterCondition(field) && field.field_display === 'card_employee_display'
          );

          setContractCustomFields(contractFields);
          setEmployeeCustomFields(employeeFields);
        }
      });
    };
    handleRequestAndSuccessCustomFields();
  }, [currentCompany, dispatch]);

  const handleSuccessShow = response => {
    const { contract } = employee;
    const { employeePrevisionAttributes } = contract;
    const employeeResponse = camelCaseEmptyStringRecursive(response.data);
    const empPrevAttr = employeeResponse.employeePrevisionAttributes;
    const empContract = employeeResponse.contract;
    if (empContract.id && empContract.status === 'active') {
      employeeResponse.contract = { ...contract, ...empContract, ...employeePrevisionAttributes, ...empPrevAttr };
    } else if (location.state?.renewContractId) {
      employeeResponse.contract = {
        ...contract,
        ...empContract,
        ...{ id: location.state?.renewContractId },
        ...employeePrevisionAttributes,
        ...empPrevAttr
      };
      employeeResponse.active = true;
    } else if (location.state?.contract) {
      const finishedContract = camelCaseEmptyStringRecursive(location.state.contract);
      employeeResponse.contract = {
        ...contract,
        ...finishedContract,
        ...{ status: undefined, id: undefined },
        ...employeePrevisionAttributes,
        ...empPrevAttr
      };
    } else {
      employeeResponse.contract = { ...contract, ...employeePrevisionAttributes, ...empPrevAttr };
    }
    setEmployee(employeeResponse);
  };

  const fetchEmployee = () => {
    const employeeId = match.params.id;

    showEmployeeRequest(employeeId, {
      dispatch,
      successCallback: handleSuccessShow
    });

    // Sing Substitutes
    indexSelectEmployeesRequest({
      dispatch,
      params: {
        exclude_id: employeeId,
        sort_column: 'name',
        is_dt: false,
        paginate: false
      },
      successCallback: response => {
        setSignSubstitutes(camelCaseEmptyStringRecursive(response.data.data));
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchEmployee, []);

  const handleSuccessUpdate = response => {
    const { data } = response;
    dispatch(sendAlert({ kind: 'success', message: 'Trabajador actualizado con éxito' }));
    if (data.id === currentEmployee) {
      dispatch(validateToken());
    }
    history.push(`/employees/${data.id}`);
  };

  const handleUpdateRequest = (employeeValues, setSubmitting) => {
    const { employee: copyEmployee } = employeeValues;
    const { employeeCertificationsAttributes, image, digitalSign } = copyEmployee;
    const { progressiveVacationsCertificate } = copyEmployee.contract;
    const { disabilityCertificate } = copyEmployee.contract;
    if (copyEmployee.contract !== employee.contract) {
      copyEmployee.contractsAttributes = [copyEmployee.contract];
    }
    let myParams = {
      employee: { ...copyEmployee }
    };

    myParams = snakeCaseKeys(myParams, { exclude: ['_destroy'] });
    myParams.employee.image = image;
    myParams.employee.digital_sign = digitalSign;
    if (digitalSign) {
      myParams.employee.send_email_udpated_employee_signature = true;
    }
    if (employeeCertificationsAttributes.length > 0) {
      employeeCertificationsAttributes.forEach((body, index) => {
        myParams.employee.employee_certifications_attributes[index].certificate = body.certificate;
      });
    } else {
      delete myParams.employee.employee_certifications_attributes;
    }
    if (progressiveVacationsCertificate && myParams.employee.contracts_attributes) {
      myParams.employee.contracts_attributes[0].progressive_vacations_certificate = progressiveVacationsCertificate;
    }
    if (disabilityCertificate && myParams.employee.contracts_attributes) {
      myParams.employee.contracts_attributes[0].disability_certificate = disabilityCertificate;
    }
    updateEmployeeRequest(employee.id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  const contractTabActive = location.state?.contract !== undefined;
  return (
    <>
      <EmployeeTopView employeeEdit employee={employee.employeeCard} returnTo="/employees" />
      <EmployeeForm
        employee={employee}
        signSubstitutes={signSubstitutes}
        action="edit"
        contractTabActive={contractTabActive}
        withContract={ability.can('update', 'Contract')}
        currentCompany={currentCompany}
        submitVariant="success"
        formRequest={handleUpdateRequest}
        canFamilyGroup={ability.can('create', 'FamilyGroup')}
        canCreateAsset={ability.can('update_asset', 'ContractBalance')}
        canCreateDiscount={ability.can('update_discount', 'ContractBalance')}
        canDestroyAsset={ability.can('destroy_asset', 'ContractBalance')}
        canDestroyDiscount={ability.can('destroy_discount', 'ContractBalance')}
        canPrevision={ability.can('create', 'EmployeePrevision')}
        contractCustomFields={contractCustomFields}
        employeeCustomFields={employeeCustomFields}
        remunerations={remunerations}
      />
    </>
  );
};

export default EmployeeEdit;
