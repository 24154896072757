import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card, Tooltip, OverlayTrigger, Table } from 'react-bootstrap';
import { FormikInput, FormikSelect, LinkBtn, FormikNumber, Icon } from '../../../components';
import { debounceIndexConceptLreRequest, indexBalancesRequest } from '../../../requests/balances';
import { conceptTypeEnum, ineCodeEnum, emptyConcept, conceptFE } from './FormOptions';
import validationSchema from './formValidationSchema';
import AssetForm from './AssetForm';
import DiscountForm from './DiscountForm';
import styles from './Styles.module.scss';

const prefixOptions = {
  asset: [
    { label: 'HI', value: 'HI' },
    { label: 'HNI', value: 'HNI' }
  ],
  discount: [
    { label: 'DL', value: 'DL' },
    { label: 'D0', value: 'D0' }
  ]
};

const ConceptForm = props => {
  const dispatch = useDispatch();
  const {
    balance,
    errors,
    onHide,
    setFieldValue,
    setFieldTouched,
    submitVariant,
    touched,
    values,
    isSubmitting,
    action
  } = props;

  const { preloaded } = balance;
  const [conceptsLre, setConceptsLre] = useState([]);
  const [orderedConcepts, setOrderedConcepts] = useState([]);
  const balanceType = getIn(values, 'balance[balanceType]');

  const resultfetchConceptLre = response => {
    const result = response.data.data;
    return result.map(element => ({
      label: `${element.code} - ${element.name}`,
      value: element.id
    }));
  };

  const fetchConceptLre = () => {
    if (!balanceType) return;
    debounceIndexConceptLreRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'code',
        sort_direction: 'asc',
        filter_type: balanceType,
        paginate: false
      },
      successCallback: response => {
        setConceptsLre(resultfetchConceptLre(response));
      }
    });
  };

  const onchangeconceptType = (field, data) => {
    if (action === 'new') {
      Object.keys(emptyConcept).forEach(key => {
        if (!['code', 'balanceType'].includes(key)) {
          setFieldValue(`balance[${key}]`, emptyConcept[key]);
        }
      });
    }
    setFieldValue(field.name, data ? data.value : '');
  };

  const handleOrderInfoClick = () => {
    if (!values?.balance?.balanceType) return;
    indexBalancesRequest({
      dispatch,
      params: {
        preloaded: false,
        paginate: false,
        last_balances: values?.balance?.balanceType,
        simple_serializer: true
      },
      successCallback: response => {
        if (response.data.data) {
          setOrderedConcepts(response.data.data);
          setTimeout(() => setOrderedConcepts([]), 5000);
        }
      }
    });
  };

  useEffect(fetchConceptLre, [balanceType]);

  return (
    <Form>
      <Row>
        <Col className="mt-2">
          <Card style={{ zIndex: 'unset' }}>
            <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">CARACTERISTICAS</Col>
            <Card.Body className="div-content card-body-padding">
              <Row>
                <Col md={8}>
                  <Row>
                    <Col md={3}>
                      <Field name="balance[balanceType]">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr
                            label="Tipo de Concepto"
                            placeholder="Seleccionar Tipo de Concepto"
                            options={conceptTypeEnum}
                            defaultValue={balance.balanceType}
                            onChange={data => onchangeconceptType(field, data)}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            isDisabled={preloaded && action === 'edit'}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={2}>
                      <Field name="balance[prefix]">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr
                            label="Prefijo"
                            placeholder="Prefijo"
                            tooltipText="El prefijo deberia ser 'HI' para Haberes Imponibles 'HNI' para Haberes No Imponibles 'DL' para Descuentos Legales 'D0' para Descuentos Otros"
                            options={prefixOptions[balanceType]}
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            defaultValue={balance.prefix}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            isDisabled={preloaded && action === 'edit'}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={2}>
                      <Field name="balance[code]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Código"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            defaultValue={balance.code}
                            disabled={preloaded && action === 'edit'}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={5}>
                      <Field name="balance[name]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Descripción"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            defaultValue={balance.name}
                            disabled={preloaded && action === 'edit'}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Field name="balance[ineCode]">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr
                            label="Agrupación INE"
                            placeholder="Seleccionar Agrupación INE"
                            tooltipText="Código de agrupación de cargos para las empresas que deben responder la
                               Encuesta INE Mensual de Remuneraciones y Costo de la Mano de Obra.
                               Si completa este campo podrá generar automáticamente dicho informe."
                            options={ineCodeEnum}
                            defaultValue={balance.ineCode}
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            isDisabled={(preloaded && action === 'edit') || balanceType !== 'asset'}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Field name="balance[conceptLreId]">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            label="Concepto LRE"
                            placeholder="Seleccionar Concepto LRE"
                            defaultValue={balance.conceptLreId}
                            options={conceptsLre}
                            onChange={data => setFieldValue(field.name, data.value)}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Field name="balance[electronicSettlement]">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            label="Finiquito Electrónico (FE)"
                            placeholder="Seleccionar concepto FE"
                            tooltipText="Para generar correctamente el archivo masivo de finiquitos electrónico, asocie el concepto con alguna alternativa de este selector."
                            options={conceptFE}
                            defaultValue={balance.electronicSettlement}
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            isDisabled={balanceType !== 'asset'}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Field name="balance[calculationOrder]">
                        {({ field }) => (
                          <>
                            <FormikNumber
                              {...field}
                              abbr
                              label="Orden en la liquidación"
                              tooltipText="Define el orden en que se mostrará en la liquidación."
                              fieldName="balance[calculationOrder]"
                              errors={errors}
                              touched={touched}
                              defaultValue={balance.calculationOrder}
                              thousandSeparator=""
                              disabled={preloaded && action === 'edit'}
                            />
                          </>
                        )}
                      </Field>
                    </Col>
                    <Col>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Ver Orden actual de conceptos</Tooltip>}
                      >
                        <Button variant="circle-dark" className="mt-4" onClick={handleOrderInfoClick}>
                          <Icon icon="help-circle" width="25" />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                    <Col md={8}>
                      <Field name="balance[observation]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            as="textarea"
                            rows={1}
                            label="Observación"
                            placeholder="Cualquier información adicional que desee agregar al concepto."
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            defaultValue={balance.observation}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  {orderedConcepts.length > 0 && (
                    <Row>
                      <Col md={4}>
                        <Table className={`${styles.simpleTable}`}>
                          <thead>
                            <tr>
                              <td>Orden</td>
                              <td>Concepto</td>
                            </tr>
                          </thead>
                          <tbody>
                            {orderedConcepts.map(concept => (
                              <tr key={concept.name}>
                                <td>{concept.calculation_order}</td>
                                <td>{concept.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col md={4}>
                  {balanceType === 'asset' && <AssetForm preloaded={preloaded} action={action} />}
                  {balanceType === 'discount' && <DiscountForm preloaded={preloaded} action={action} />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="d-flex justify-content-end mt-1 mb-3">
            <Col md={2}>
              <LinkBtn variant="dark" className="btn-secondary btn-block" block to="/remunerations_settings">
                Cancelar
              </LinkBtn>
            </Col>
            <Col md={2}>
              <Button
                type="submit"
                className="btn-block"
                variant={submitVariant}
                disabled={isSubmitting}
                onClick={onHide}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { balance } = props;
  return { balance };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  const formValues = { balance: { ...values.balance } };
  const { prefix } = formValues.balance;
  formValues.balance.code = `${prefix}${formValues.balance.code}`;
  formRequest(formValues, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: params => params.action !== 'new'
})(ConceptForm);
