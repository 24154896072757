import React from 'react';
import './styles.scss';

const DailyAttendanceToPrint = React.forwardRef(({ data }, ref) => {
  return (
    <div className="print_report" ref={ref}>
      {data.length > 0 && (
        <>
          <p className="text-center">Reporte de Marcaciones Diarias</p>
          {data.map((employee, index) => (
            <>  {index === 0 && (
              <>
                <p>Empresa: {employee.company_name}</p>
                {employee.date_range ? <p>Rut: {employee.company_national_id}</p> : <p className="mb-3">Rut: {employee.company_national_id}</p>}
                {employee.date_range && (<p className="mb-3">Rango seleccionado de fechas: {employee.date_range}</p>)}
              </>
            )}

              {employee?.attendances_attributes.length > 0 && (
                <>
                  <>
                    <p>{employee.full_name}</p>
                    <p>Rut: {employee.national_identification}</p>
                    <p>Lugar de Prestación de Servicios: {employee.branch_offices_name}</p>
                    <p>Banda Horaria: {employee.work_schedule_art27}</p>

                  </>
                  <table className="table-print table-sm table-bordered">
                    <tbody>
                      <tr style={{ backgroundColor: '#EBEBEB' }}>
                        <th className="text-center align-middle">Fecha</th>
                        <th className="text-center align-middle">Hora Marcación</th>
                        <th className="text-center align-middle">Tipo de Registro</th>
                        <th className="text-center align-middle">Medio de Marcación</th>
                        <th className="text-center align-middle">
                          Hash
                        </th>
                      </tr>
                      {employee?.attendances_attributes?.map(attendance => (
                        <tr>
                          <th className="text-center align-middle">{attendance.date}</th>
                          <th className="text-center align-middle">{attendance.parsed_time}</th>
                          <th className="text-center align-middle">{attendance.translated_time_type}</th>
                          <th className="text-center align-middle">{attendance.translated_device_type}</th>
                          <th className="text-center align-middle">
                            {attendance.hash_code}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}

            </>
          ))}
        </>
      )}
    </div>
  );
});

export default DailyAttendanceToPrint;
