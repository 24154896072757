import React from 'react';
import PrintFunctions from '../Print/PrintFunctions';
import "./styles.scss";

const OffdayReportToPrint = React.forwardRef(({ data }, ref) => {
  const arialStyle = {
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  const selectedStyle = {
    backgroundColor: '#F89406',
    color: '#000000',
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  const headerStyle = {
    backgroundColor: '#EBEBEB',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  const selectedEndStyle = {
    backgroundColor: '#D3D3D3',
    color: '#000000',
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  const { getEmployeeInfo, getCompanyInfo } = PrintFunctions();

  return (
    <>
      <div className="print_report" ref={ref}>
        <p>Reporte de días domingo y/o días festivos</p>
        {data.length > 0 && (
          <>
            {getCompanyInfo(data[0])}

            {data.map(employee => (
              <>
                {getEmployeeInfo(employee)}
                <p>
                  <br />
                </p>

                <table className="table-print table-sm table-bordered" style={arialStyle}>
                  <tbody>
                    <tr style={headerStyle}>
                      <th className="text-center align-middle">Descanso Dominical Adicional</th>
                      <th className="text-center align-middle">Fecha</th>
                      <th className="text-center align-middle">Asistencia</th>
                      <th className="text-center align-middle">Ausencia</th>
                      <th className="text-center align-middle">Observaciones</th>
                    </tr>
                    {employee.records
                      .filter(record => record.default_no !== 'Total Final: ')
                      .map(record => (
                        <>
                          <tr>
                            {!record.default_no.includes('Total') && (
                              <>
                                <td className="text-center align-middle">{record.default_no}</td>
                                <td className="text-center align-middle">{record.date}</td>
                                <td className="text-center align-middle">{record.attendance}</td>
                                <td className="text-center align-middle">{record.absence}</td>
                                <td
                                  className="text-center align-middle"
                                  style={record.observation === 'Marcación fuera de turno' ? selectedStyle : null}
                                >
                                  {record.observation}
                                </td>
                              </>
                            )}
                            {record.default_no.includes('Total') && (
                              <>
                                <td
                                  colSpan="2"
                                  className="align-middle"
                                  style={{ textAlign: 'right', fontWeight: 'bold' }}
                                >
                                  {record.default_no}
                                </td>
                                <td className="text-center align-middle">{record.attendance}</td>
                                <td className="text-center align-middle">{record.absence}</td>
                                <td className="text-center align-middle">&nbsp;</td>
                              </>
                            )}
                          </tr>
                        </>
                      ))}
                    {employee.records
                      .filter(record => record.default_no === 'Total Final: ')
                      .map(record => (
                        <>
                          <tr style={selectedEndStyle}>
                            <td colSpan="2" className="align-middle" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                              {record.default_no}
                            </td>
                            <td className="text-center align-middle">{record.attendance}</td>
                            <td className="text-center align-middle">{record.absence}</td>
                            <td className="text-center align-middle">&nbsp;</td>
                          </tr>
                        </>
                      ))}
                    {employee.records.length === 0 && (
                      <>
                        <tr>
                          <th className="text-center align-middle" colSpan={5}>
                            La jornada de este trabajador no incluye domingos y festivos
                          </th>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </>
            ))}
          </>
        )}
      </div>
    </>
  );
});

export default OffdayReportToPrint;
