import { Field, Form, getIn, withFormik } from 'formik'
import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import * as Yup from 'yup';
import { FormikRangePicker } from '../../../components'

const SyncControlClockForm = props => {
  const { errors, touched, values } = props;

  return (
    <Form>
      <Row>
        <Col>
          <Field name="range_date">
            {({ field }) => (
              <FormikRangePicker
                {...field}
                abbr
                startDateName="start_time"
                endDateName="end_time"
                startDate={values.start_time}
                endDate={values.end_time}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={4}>
          <Button className="btn-block" type="submit">
            Sincronizar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
const validationSchema = Yup.object().shape({
  range_date: Yup.array().rangedate(true)
});


const setInitialValues = props => {
  const { deviceId } = props
  return {
    start_time: '',
    end_time: '',
    range_date: [],
    device_id: deviceId
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(SyncControlClockForm);

