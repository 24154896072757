import React from 'react';
import { Table } from 'react-bootstrap';
import './style.scss';

const OvertimeSummaryCard = ({ overtimeData, totalRemainingBalance }) => {
  const sortedOvertimeData = overtimeData
    ? [...overtimeData].sort((a, b) => {
        const [monthA, yearA] = a.generatedRemunerationPeriod.split('/');
        const [monthB, yearB] = b.generatedRemunerationPeriod.split('/');
        const dateA = new Date(parseInt(yearA, 10), parseInt(monthA, 10) - 1);
        const dateB = new Date(parseInt(yearB, 10), parseInt(monthB, 10) - 1);
        return dateA - dateB;
      })
    : [];

  const totalHoursGenerated = sortedOvertimeData.reduce((sum, row) => sum + (row.extraHoursGenerated || 0), 0);

  if (!overtimeData || !Array.isArray(overtimeData)) return <p>Cargando...</p>;

  return (
    <div className="overtime-summary-card">
      <h5 className="text-center">Horas Acumuladas Últimos 6 Meses</h5>
      <Table striped bordered hover className="overtime-table">
        <thead>
          <tr className="overtime-table-header">
            <th className="text-center">Período Generación</th>
            <th className="text-center">Horas Generadas</th>
            <th className="text-center">Vencimiento de Horas</th>
            <th className="text-center">Saldo Restante</th>
          </tr>
        </thead>
        <tbody>
          {sortedOvertimeData.map(item => (
            <tr key={item.generatedRemunerationPeriod}>
              <td className="text-center">{item.generatedRemunerationPeriod}</td>
              <td className="text-center">{item.extraHoursGenerated}</td>
              <td className="text-center">{item.expirationRemunerationPeriod}</td>
              <td className="text-center">{item.remainingExtraHours}</td>
            </tr>
          ))}
          <tr className="overtime-table-bot">
            <td>Totales:</td>
            <td>{totalHoursGenerated.toFixed(1)} horas</td>
            <td />
            <td>{totalRemainingBalance.toFixed(1)} horas</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const OvertimeEquivalenceCard = ({ overtimeEquivalence }) => {
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const formattedStartDate = `${String(sixMonthsAgo.getMonth() + 1).padStart(2, '0')}/${sixMonthsAgo.getFullYear()}`;
  const formattedEndDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;

  if (!overtimeEquivalence.days) {
    return (
      <div className="card border-yellow my-3 p-5">
        <p className="text-center">
          Configurar el campo {'"Horas trabajadas al día"'} en la sección jornada del contrato del trabajador.
        </p>
      </div>
    );
  }

  return (
    <div className="card border-yellow p-5 d-flex align-items-center justify-content-center text-center">
      <div>
        <p className="text-center title">Días Disponibles</p>
        <p className="text-center">
          (Del {formattedStartDate} hasta el {formattedEndDate})
        </p>
        <h4 className="card-title text-center font-weight-bold">
          {overtimeEquivalence.totalOvertime.toFixed(1)} Horas = {overtimeEquivalence.days} Días,{' '}
          {overtimeEquivalence.hours.toFixed(1)} hora{overtimeEquivalence.hours !== 1 ? 's' : ''}
        </h4>
      </div>
    </div>
  );
};

export { OvertimeSummaryCard, OvertimeEquivalenceCard };
