import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const WarningContractModal = ({ contract, handleRenewContract, disabled }) => {
  return (
    <>
      <Modal.Body>
        <p>
          El contrato ({contract.correlativeIdentification}) del empleado se encuentra
          <span className="bold"> finiquitado</span>,
        </p>
        <p>¿Desea Actívarlo nuevamente o realizar un Nuevo Contrato?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={disabled} variant="primary" className="mr-3" onClick={() => handleRenewContract()}>
          Crear Nuevo Contrato
        </Button>
        <Button disabled={disabled} variant="info" className="mr-3" onClick={() => handleRenewContract(true)}>
          Renovar Contrato ({contract.correlativeIdentification})
        </Button>
      </Modal.Footer>
    </>
  );
};

export default WarningContractModal;
