import memoize from 'memoize-one';
import React from 'react';
import { DatatableEmployeeName } from '../../../components';
import MassiveActionsItem from './MassiveActionsItem';

export default memoize((clickHandler, attendanceManagement, onlyShow) => [
  {
    name: 'TRABAJADOR',
    cell: item => (
      <>
        <DatatableEmployeeName
          item={item.employee}
          withPhoto={false}
          name="full_name"
          startDateContract={item?.start_date_contract}
        />
      </>
    ),
    selector: 'full_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'HHEE 50',
    selector: 'total_overtime_50',
    grow: '1',
    sortable: true
  },
  {
    name: 'HHEE 100',
    selector: 'total_overtime_100',
    grow: '1',
    sortable: true
  },
  {
    name: 'HHEE 150',
    selector: 'total_overtime_150',
    grow: '1',
    sortable: true
  },
  {
    name: 'Total HHEE',
    selector: 'total_overtime',
    grow: '1',
    sortable: true
  },
  {
    name: 'T Extra',
    selector: 'extra_time',
    grow: '1',
    sortable: true
  },
  {
    name: 'Atrasos',
    selector: 'hours_late',
    grow: '1',
    sortable: true
  },
  {
    name: 'Salidas Anticipadas',
    selector: 'early_departure_hours',
    grow: '1',
    sortable: true
  },
  {
    name: 'Exceso de Colación',
    selector: 'excess_collation_hours',
    grow: '1',
    sortable: true
  },
  {
    name: 'Horas No Trabajadas',
    selector: 'non_worked_hours',
    grow: '1',
    sortable: true
  },
  {
    name: 'Horas a Acumular',
    selector: 'hours_to_acumulate',
    grow: '1',
    sortable: true
  },
  {
    name: 'ACCIONES',
    cell: row => <MassiveActionsItem item={row} handleClick={clickHandler} disabled={onlyShow} />,
    grow: '1',
    minWidth: '150px'
  }
]);
