import ApiService from '../services/apiService';

export const indexContractRequest = ({ dispatch, params, successCallback, failureCallback }) =>
  ApiService.request('get', `/contracts`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const onlyOutSourceContracts = ({ dispatch, params, successCallback, failureCallback }) =>
  ApiService.request('get', `/contracts/only_out_source_contracts`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const activeContractsRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/contracts/active_contracts`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showContractRequest = (id, { dispatch, params, successCallback, failureCallback }) =>
  ApiService.request('get', `/contracts/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const terminateContractRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback }
) =>
  ApiService.request('put', `/contracts/${id}/terminate_contract`, {
    dispatch,
    params,
    formData,
    responseType: 'json',
    successCallback,
    failureCallback,
    callback
  });

export const renewContractRequest = (id, { dispatch, params, formData, successCallback, failureCallback, callback }) =>
  ApiService.request('put', `/contracts/${id}/renew`, {
    dispatch,
    params,
    formData,
    responseType: 'json',
    successCallback,
    failureCallback,
    callback
  });

export const exportContractsRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', `/contracts/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportContractRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/contracts/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importContractRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/contracts/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateContractRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/contracts/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};
