import React, { useState, useEffect, useCallback } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import moment from 'moment';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikRutSuggest, FormikRangePicker, FormikSelect, InputSelect } from '../../../components';
import { debounceIndexSelectEmployeesRequest } from '../../../requests/employees';
import { indexJobTitleRequest } from '../../../requests/jobTitles';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';
import { workSchedules, workSchedulesTypes, twentyFourMonths } from './Options';
import FormikSelectClear from '../../../components/Utils/Select/FormikSelectClear';
import { onlyOutSourceContracts } from '../../../requests/contracts';
import { debounceIndexRegionsRequest } from '../../../requests/regions';

const OffdayReportFilters = props => {
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    values,
    filterRRHH,
    isSubmit,
    onRequest,
    isArial,
    onlyPreview = false
  } = props;
  const dispatch = useDispatch();
  const ALL_SELECTED_LABEL = 'Todos';
  const [jobTitles, setJobTitles] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [filterBranchOffices, setFilterBranchOffices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesSingle, setEmployeesSingle] = useState([]);
  const [ruts, setRuts] = useState(['']);
  const [suggestions, setSuggestions] = useState(['']);
  const [dataRange, setDataRange] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isFetchingBranchOffices, setIsFetchingBranchOffices] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectWorkSchedules, setSelectWorkSchedules] = useState([]);
  const [selectoutSourceContracts, setSelectOutSourceContracts] = useState([]);

  useEffect(() => {
    const filterWorkSchedulesTypes = workSchedulesTypes.filter(item => item.value !== 'all');
    setFieldValue(
      'all_work_schedules_values',
      filterWorkSchedulesTypes.flatMap(item => item.value)
    );
  }, [setFieldValue]);

  useEffect(() => {
    if (!values.filter_by_hash || values.filter_by_hash.length === 0) {
      const dateFromWorkday = moment()
        .subtract(1, 'years')
        .format('DD/MM/YYYY');
      const dateToWorkday = moment().format('DD/MM/YYYY');
      const rangeDateWorkday = [
        moment()
          .subtract(1, 'years')
          .format('DD/MM/YYYY'),
        moment().format('DD/MM/YYYY')
      ];
      setFieldValue('date_from_workday', dateFromWorkday);
      setFieldValue('date_to_workday', dateToWorkday);
      setFieldValue('range_date_workday', rangeDateWorkday);
      setDataRange([dateFromWorkday, dateToWorkday]);
    } else {
      setFieldValue('date_from_workday', '');
      setFieldValue('date_to_workday', '');
      setFieldValue('range_date_workday', '');
      setFieldValue('employees', []);
      setFieldValue('employee', '');
      setDataRange([]);
      setEmployees([]);
      setEmployeesSingle([]);
      setFieldValue('filter_employee_id', []);
      setFieldValue('filter_employee_ids', []);
    }
  }, [values.filter_by_hash, setFieldValue]);

  const getBranchOfficesIds = useCallback(() => {
    const validRegions = regions.filter(bo => bo);
    const validBaranOffices = branchOffices.filter(bo => bo);
    if (validBaranOffices.length === 0 && validRegions.length === 0) return [];

    return validBaranOffices.length > 0
      ? validBaranOffices
      : filterBranchOffices.filter(b => b.id !== '').map(b => b.id);
  }, [branchOffices, filterBranchOffices, regions]);

  const fetchEmployees = useCallback(() => {
    if (isFetchingBranchOffices || filterBranchOffices.filter(b => b.id !== '').length === 0) return;

    setFieldValue('filter_employee_ids', []);
    setFieldValue('filter_employee_id', '');
    setEmployees([]);
    setEmployeesSingle([]);
    setIsFiltering(true);

    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        sort_column: 'name',
        paginate: false,
        is_dt: false,
        filter_branch_offices_id_only: getBranchOfficesIds(),
        filter_active_shifts: shifts,
        filter_job_titles_id_only: jobTitles,
        filter_work_schedules: selectWorkSchedules,
        filter_by_contract_date_range: dataRange,
        filter_out_source_contracts: selectoutSourceContracts
      },
      successCallback: ({ data: { data: arrayEmployees } }) => {
        const dataToGroupSearch = [{ id: '', label: 'Todos', value: '' }, ...arrayEmployees];
        setEmployees(arrayEmployees.length > 0 ? dataToGroupSearch : []);
        setEmployeesSingle(arrayEmployees);
        const employeeIds = arrayEmployees.flatMap(employee => employee.id);
        setFieldValue('all_employee_ids', employeeIds);
        setIsFiltering(false);
        if (!ruts[0]) {
          setRuts(arrayEmployees.map(r => r.national_identification));
          setSuggestions(arrayEmployees.map(r => r.national_identification));
        }
      }
    });
  }, [
    isFetchingBranchOffices,
    filterBranchOffices,
    getBranchOfficesIds,
    shifts,
    jobTitles,
    selectWorkSchedules,
    dataRange,
    selectoutSourceContracts,
    setFieldValue,
    setEmployees,
    setEmployeesSingle,
    setIsFiltering,
    ruts,
    setRuts,
    setSuggestions,
    dispatch
  ]);

  const fetchJobTitles = (inputValue, callback) => {
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const fetchShifts = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const fetchBranchOffices = () => {
    setIsFetchingBranchOffices(true);
    setFilterBranchOffices([]);
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        filter_region_ids: regions,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => {
        setIsFetchingBranchOffices(false);
        const dataBranchOffices = response.data.data;
        const dataToGroupSearch = [{ id: '', label: 'Todos', value: '' }, ...dataBranchOffices];
        setFilterBranchOffices(dataBranchOffices.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const fetchOutSourceContracts = (inputValue, callback) => {
    onlyOutSourceContracts({
      dispatch,
      params: {
        filter_out_sourced_name: inputValue,
        filter_distinct_out_sourced: true
      },
      successCallback: response => {
        const { data } = response.data;
        const outSourceContracts = data.flatMap(item => item.value);
        setFieldValue('all_filter_out_source_contracts', outSourceContracts);
        callback([{ label: 'Todos', value: 'all' }, ...data]);
      }
    });
  };

  const fetchRegions = (inputValue, callback) => {
    debounceIndexRegionsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        with_branch_offices: true,
        display_length: 40
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const handleEmployeesSearch = (field, data) => {
    switch (field) {
      case 'filter_branch_offices_id_only':
        setBranchOffices(data);
        break;
      case 'filter_active_shifts':
        setShifts(data);
        break;
      case 'filter_job_titles_id_only':
        setJobTitles(data);
        break;
      case 'filter_region_ids':
        setRegions(data);
        break;
      case 'filter_work_schedules':
        setSelectWorkSchedules(data);
        break;
      case 'filter_out_source_contracts':
        setSelectOutSourceContracts(data);
        break;
      default:
        break;
    }
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
    handleEmployeesSearch(field, newData);
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  useEffect(() => {
    if (dataRange.length === 2) {
      fetchEmployees();
    }
  }, [
    fetchEmployees,
    getBranchOfficesIds,
    shifts,
    jobTitles,
    dataRange,
    selectWorkSchedules,
    selectoutSourceContracts
  ]);

  useEffect(fetchBranchOffices, [regions]);

  return (
    <Form>
      <Card>
        <Row>
          <Col>
            <Card className="card-dt">
              <Col className={`top-header-green-dt card-header-title-dt ${isArial ? 'arial-dt' : ''}`}>
                Rango de Fechas o Periodo Predeterminado
              </Col>
              <Card.Body className="div-content card-body-padding-legal">
                <Row>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="range_date_workday">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Periodo"
                          options={workSchedules.concat(twentyFourMonths)}
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            setFieldValue('date_from_workday', data?.value[0] || '');
                            setFieldValue('date_to_workday', data?.value[1] || '');
                            setDataRange(data ? data.value : '');
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="range_date_workday">
                      {({ field }) => (
                        <FormikRangePicker
                          {...field}
                          abbr
                          startDateName="date_from_workday"
                          endDateName="date_to_workday"
                          startDate={values.date_from_workday}
                          endDate={values.date_to_workday}
                          showClearDates
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial={isArial}
                          onDatesChange={({ startDate: startDateV, endDate: endDateV }) => {
                            const startValue = moment.isMoment(startDateV) ? startDateV.format('DD/MM/YYYY') : '';
                            const endValue = moment.isMoment(endDateV) ? endDateV.format('DD/MM/YYYY') : '';

                            setFieldValue('employee', '');
                            setFieldValue('employees', []);

                            if (startValue && endValue) setDataRange([startValue, endValue]);

                            setFieldValue('date_from_workday', startValue);
                            setFieldValue('date_to_workday', endValue);
                            setFieldTouched(field.name);
                            setFieldValue(field.name, [startValue, endValue]); // Important to be the last one
                          }}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {filterRRHH && (
          <>
            <Row>
              <Col md={6}>
                <Card className="card-dt">
                  <Col className={`top-header-green-dt card-header-title-dt ${isArial ? 'arial-dt' : ''}`}>
                    Búsqueda Individual
                  </Col>
                  <Card.Body className="div-content card-body-padding-legal">
                    <Row>
                      <Col sm={12} md={6} xs={12}>
                        <Field name="employee">
                          {({ field }) => (
                            <FormikSelect
                              {...field}
                              label="Nombre y Apellido"
                              placeholder="Seleccione colaborador"
                              value={values.employee}
                              isClearable
                              options={employeesSingle}
                              isLoading={isFiltering}
                              onChange={data => {
                                setFieldValue(field.name, data || '');
                                setFieldValue('filter_employee_id', data?.value || '');
                                setFieldValue('employees', []);
                                setFieldValue('filter_employee_ids', []);
                              }}
                              isOptionDisabled={handleDisabledSelection}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={6} xs={12}>
                        <Field name="filter_national_id">
                          {({ field }) => (
                            <FormikRutSuggest
                              {...field}
                              suggestions={suggestions}
                              setSuggestions={setSuggestions}
                              ruts={ruts}
                              label="RUT"
                              placeholder="Sin puntos (ej: 11111111-1)"
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              setFieldValue={setFieldValue}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="card-dt">
                  <Col className={`top-header-green-dt card-header-title ${isArial ? 'arial-dt' : ''}`}>
                    Búsqueda Grupal
                  </Col>
                  <Card.Body className="div-content card-body-padding-legal">
                    <Row>
                      <Col sm={12} md={12} xs={12}>
                        <Field name="employees">
                          {({ field }) => (
                            <FormikSelectClear
                              {...field}
                              label="Nombres y Apellidos"
                              isMulti
                              options={employees}
                              placeholder="Seleccione colaboradores"
                              isLoading={isFiltering}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_employee_ids', true);
                                setFieldValue(field.name, data || []);
                                setFieldValue('employee', '');
                                setFieldValue('filter_employee_id', '');
                              }}
                              isOptionDisabled={handleDisabledSelection}
                              isClearable
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-dt">
                  <Col className={`top-header-green-dt card-header-title-dt ${isArial ? 'arial-dt' : ''}`}>
                    Búsqueda Grupal Listado
                  </Col>
                  <Card.Body className="div-content card-body-padding-legal">
                    <Row>
                      <Col sm={12} md={4} xs={12}>
                        <Field name="regions">
                          {({ field }) => (
                            <InputSelect
                              {...field}
                              label="Región"
                              isMulti
                              menuPlacement="top"
                              isClearable
                              isOptionDisabled={handleDisabledSelection}
                              placeholder="Seleccionar Región"
                              values={values.filter_region_ids}
                              request={fetchRegions}
                              onChange={data => {
                                setFilterBranchOffices([]);
                                handleNewMulti(data || [], 'filter_region_ids', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={4} xs={12}>
                        <Field name="branch_offices">
                          {({ field }) => (
                            <FormikSelectClear
                              {...field}
                              label="Lugar de Prestación de Servicios"
                              isMulti
                              options={filterBranchOffices}
                              placeholder="Seleccione Lugar de Prestación de Servicios"
                              isLoading={isFiltering}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_branch_offices_id_only', true);
                                setFieldValue(field.name, data || []);
                              }}
                              isOptionDisabled={handleDisabledSelection}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                              menuPlacement="top"
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={4} xs={12}>
                        <Field name="shifts">
                          {({ field }) => (
                            <InputSelect
                              {...field}
                              label="Turnos"
                              isMulti
                              isOptionDisabled={handleDisabledSelection}
                              menuPlacement="top"
                              placeholder="Seleccione Turno"
                              values={values.filter_active_shifts}
                              request={fetchShifts}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_active_shifts', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={4} xs={12}>
                        <Field name="work_schedules">
                          {({ field }) => (
                            <FormikSelectClear
                              {...field}
                              isMulti
                              label="Jornada"
                              placeholder="Seleccione Jornada"
                              isOptionDisabled={handleDisabledSelection}
                              options={workSchedulesTypes}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_work_schedules', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={4} xs={12}>
                        <Field name="job_titles">
                          {({ field }) => (
                            <InputSelect
                              {...field}
                              isMulti
                              isOptionDisabled={handleDisabledSelection}
                              menuPlacement="top"
                              placeholder="Seleccione cargo"
                              label="Cargo o Función"
                              values={values.filter_job_titles_id_only}
                              request={fetchJobTitles}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_job_titles_id_only', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={4} xs={12}>
                        <Field name="out_source_contracts">
                          {({ field }) => (
                            <InputSelect
                              {...field}
                              isMulti
                              isOptionDisabled={handleDisabledSelection}
                              menuPlacement="top"
                              placeholder="Seleccione Est"
                              label="Est"
                              values={values.filter_out_source_contracts}
                              request={fetchOutSourceContracts}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_out_source_contracts', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-dt">
                  <Col className={`top-header-green-dt card-header-title-dt ${isArial ? 'arial-dt' : ''}`}>
                    Búsqueda por Hash
                  </Col>
                  <Card.Body className="div-content card-body-padding-legal">
                    <Row>
                      <Col sm={12} md={4} xs={12}>
                        <Field name="filter_by_hash">
                          {({ field }) => (
                            <FormikRutSuggest
                              {...field}
                              suggestions={suggestions}
                              setSuggestions={setSuggestions}
                              ruts={ruts}
                              label="Hash (Búsqueda Individual)"
                              placeholder="Introduzca el hash"
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              setFieldValue={setFieldValue}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <Row>
        <Col md={12}>{onRequest && <div className="text-center loader">Procesando...</div>}</Col>
      </Row>
      <Row className={`${onlyPreview ? 'd-flex justify-content-end' : 'd-flex'}`}>
        {onlyPreview === false && (
          <>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit || isFiltering}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.docx');
                }}
              >
                Descargar Word
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit || isFiltering}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.xlsx');
                }}
              >
                Descargar Excel
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit || isFiltering}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.pdf');
                }}
              >
                Descargar PDF
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit || isFiltering}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.print');
                }}
              >
                Imprimir
              </Button>
            </Col>
          </>
        )}
        <Col md={2}>
          <Button
            className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
            disabled={isSubmit || isFiltering}
            type="submit"
            onClick={() => {
              setFieldValue('type', null);
            }}
          >
            PreVisualizar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  range_date_workday: Yup.array()
    .when('filter_by_hash', (filterByHash, schema) => {
      // La validación solo se aplica cuando `filter_by_hash` está vacío o indefinido
      if (!filterByHash || filterByHash.length === 0) {
        return schema.test('bothDatesRequired', 'Seleccione la fecha de inicio y fin del rango', values => {
          const rangedateFilter = values && values.filter(key => key !== undefined);
          return rangedateFilter && rangedateFilter.length === 2;
        });
      }
      return schema.notRequired();
    })
    .required('El campo de fechas es requerido')
});

const setInitialValues = () => {
  return {
    date_from_workday: moment()
      .subtract(1, 'years')
      .format('DD/MM/YYYY'),
    date_to_workday: moment().format('DD/MM/YYYY'),
    range_date_workday: [
      moment()
        .subtract(1, 'years')
        .format('DD/MM/YYYY'),
      moment().format('DD/MM/YYYY')
    ],
    filter_employee_id: '',
    filter_employee_ids: [],
    filter_national_id: '',
    filter_active_job_managements: [],
    filter_job_titles_id_only: [],
    filter_active_shifts: [],
    filter_branch_offices_id_only: [],
    filter_work_schedules: [],
    filter_by_hash: '',
    type: null,
    all_employee_ids: [],
    all_work_schedules_values: [],
    all_filter_out_source_contracts: [],
    filter_out_source_contracts: []
  };
};

const evalSend = (selectItem, allItems) => {
  const handleSelect = selectItem.length > 0;
  const handleAll = handleSelect && selectItem[0] === 'all';
  if (handleSelect) {
    return handleAll ? allItems : selectItem;
  }
  return '';
};

const handleSubmit = (values, { props }) => {
  const { filter_national_id: filterNationalId } = values;
  const allEmployeeIds = filterNationalId === '' ? values.all_employee_ids.filter(id => id !== '') : [];
  const { formRequest } = props;
  const filterIsds = values.filter_employee_ids.filter(id => id !== '');
  const {
    all_employee_ids: allEmployeeIdsNoFilter,
    employee,
    employees,
    work_schedules: delWorkSchedules,
    all_work_schedules_values: allWorkSchedulesValues,
    all_filter_out_source_contracts: allOutSource,
    out_source_contracts: outSourceContracts,
    date_from: dateFrom,
    date_to: dateTo,
    ...valuesWithoutUnwantedFields
  } = values;

  const hasSelectEmployee = filterIsds.length > 0 || values.filter_employee_id;
  formRequest({
    ...valuesWithoutUnwantedFields,
    filter_employee_ids: hasSelectEmployee ? values.filter_employee_ids : allEmployeeIds,

    filter_work_schedules: evalSend(values.filter_work_schedules, values.all_work_schedules_values),
    filter_out_source_contracts: evalSend(values.filter_out_source_contracts, values.all_filter_out_source_contracts),
    filter_id: ''
  });
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false
})(OffdayReportFilters);
