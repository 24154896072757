import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import columns from './columns';
import { ComponentDataTable } from '../../components';
import useConceptHook from './useConceptHook';
import { useAuthorization } from '../../services/hooks';

const PreloadedConcepts = ({ moreData }) => {
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(true);
  const [concepts, setConcepts] = useState([]);
  const history = useHistory();

  const { handleIndexConcept } = useConceptHook();

  const permissions = {
    read: useAuthorization('read_concept', 'Balance'),
    update: useAuthorization('update_concept', 'Balance')
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push({
          pathname: `/remunerations_settings/${item.id}/show`,
          state: { tab: 'preloaded-concepts-tab' }
        });
        break;
      case 'edit':
        if (permissions.update) {
          history.push({
            pathname: `/remunerations_settings/${item.id}/edit`,
            state: { tab: 'preloaded-concepts-tab' }
          });
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleSortCase = column => {
    switch (column) {
      case 'created_at':
        return { sort_column: 'code', sort_direction: 'asc' };
      default:
        return { sort_column: column };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, permissions)}
        handleSortCase={handleSortCase}
        data={concepts}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={params =>
          handleIndexConcept({ ...params, preloaded: true }, setConcepts, setAmount, setOnRequest)
        }
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        preName="balance"
      />
    </>
  );
};
export default PreloadedConcepts;
