import React from 'react';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import { Icon, ApprovementStatus, DatatableEmployeeName, BasicDropdown } from '../../components';

import { AbilityContext } from '../../config/abilityContext';

export default memoize((clickHandler, allowedActions) => {
  const ability = useAbility(AbilityContext);

  const actions = item => {
    const authorizedActions = [];

    if (ability.can('resend_notification', 'WorkflowRequest') && allowedActions.find(e => e === 'resend')) {
      authorizedActions.push({
        icon: <Icon icon="notifications" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-resend`,
        props: {
          onClick: () => clickHandler(item, 'resend'),
          disabled: item.status !== 'pending'
        },
        title: 'Enviar Recordatorio'
      });
    }

    if (ability.can('observation', 'Document') && allowedActions.find(e => e === 'observation')) {
      authorizedActions.push({
        icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-observation`,
        props: {
          onClick: () => clickHandler(item, 'observation'),
          disabled: item.status !== 'pending'
        },
        title: 'Observación'
      });
    }

    if (ability.can('destroy_document', 'WorkflowRequest') && allowedActions.find(e => e === 'remove')) {
      authorizedActions.push({
        icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-remove-workflow`,
        props: {
          onClick: () => clickHandler(item, 'remove-workflow'),
          disabled: item.status !== 'pending'
        },
        title: 'Cancelar Flujo'
      });
    }

    if (ability.can('show', 'Document') && allowedActions.find(e => e === 'show')) {
      authorizedActions.push({
        icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-show`,
        props: {
          onClick: () => clickHandler(item, 'show')
        },
        title: 'Ver'
      });
    }

    if (ability.can('show', 'WorkflowRequest') && allowedActions.find(e => e === 'show')) {
      authorizedActions.push({
        icon: <Icon icon="git-branch-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-show-workflow`,
        props: {
          onClick: () => clickHandler(item, 'show-workflow'),
          disabled: item.status === 'not_signed'
        },
        title: 'Detalle del flujo'
      });
    }

    if (ability.can('show', 'Document') && allowedActions.find(e => e === 'download')) {
      authorizedActions.push({
        icon: <Icon icon="cloud-download" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-download`,
        props: {
          onClick: () => clickHandler(item, 'download')
        },
        title: 'Descargar'
      });
    }

    if (ability.can('create_document', 'WorkflowRequest') && allowedActions.find(e => e === 'request-sign')) {
      authorizedActions.push({
        icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-request-sign`,
        props: {
          onClick: () => clickHandler(item, 'request-sign'),
          disabled: item.status !== 'not_signed'
        },
        title: 'Enviar a Flujo'
      });
    }

    if (ability.can('destroy_file', 'DirectoryNode') && allowedActions.find(e => e === 'remove')) {
      authorizedActions.push({
        icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-remove`,
        props: {
          onClick: () => clickHandler(item, 'remove')
        },
        title: 'Eliminar'
      });
    }

    return authorizedActions;
  };

  return [
    {
      name: 'RUT Y NOMBRE',
      selector: 'employee_name',
      cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
      grow: '1'
    },
    {
      name: 'DOCUMENTO',
      selector: 'document_type',
      cell: item => (
        <div className="d-flex flex-column" onClick={() => clickHandler(item, 'show')} aria-hidden="true">
          <span className="text-muted" data-tag="allowRowEvents">
            {item.translated_document_type}
          </span>
          <span className="text-truncate" data-tag="allowRowEvents">
            {item.file_info?.filename}
          </span>
        </div>
      ),
      sortable: true,
      grow: '2'
    },
    {
      name: 'FECHA EMISIÓN',
      selector: 'created_at',
      sortable: true,
      center: true,
      grow: '1'
    },
    {
      name: 'ESTADO',
      selector: 'status',
      cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
      center: true,
      sortable: true,
      grow: '1'
    },
    {
      name: 'ACCIONES',
      cell: item => {
        if (!allowedActions) {
          return <></>;
        }

        return (
          <BasicDropdown
            block
            items={actions(item)}
            noArrow
            right
            titleDrop={
              <span className="align-middle">
                Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
              </span>
            }
            variant="outline-primary"
          />
        );
      },
      center: true,
      ignoreRowClick: true,
      sortable: false
    }
  ];
});
