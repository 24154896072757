import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAdminAttendancesControlClockRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/attendances`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'control_clock'
  });

export const deactivateAdminAttendanceControlClockRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback }
) =>
  ApiService.request('get', `/attendances/${id}/change_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    service: 'control_clock'
  });

export const deactivateMassiveAdminAttendanceControlClockRequest = (
  { dispatch, params, formData, successCallback, failureCallback, callback }
) =>
  ApiService.request('get', `/attendances/massive_change_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    service: 'control_clock'
  });

export const exportAdminAttendancesControlClockRequest = ({
  dispatch,
  params = {},
  format,
  failureCallback,
  successCallback,
  callback,
  responseType = 'text'
}) =>
  ApiService.request('get', `/attendances/export${format}`, {
    dispatch,
    params,
    responseType,
    failureCallback,
    successCallback,
    callback,
    service: 'control_clock'
  });


export const syncAttendancesRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/attendances/sync_attendances', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    service: 'control_clock'
  });


export const debounceIndexAdminAttendancesControlClockRequest = AwesomeDebouncePromise(
  indexAdminAttendancesControlClockRequest,
  300
);
