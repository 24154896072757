import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import moment from 'moment';
import { debounceTodayAbsencesReport } from '../../../requests/todayAbsences';
import Icon from '../../Icons';

const TeamAbsencesToday = ({
  employeesBelongBoss,
  successFetchEmployee,
  handleMoreInfoButton,
  showSpinnerToday,
  setShowSpinnerToday
}) => {
  const dispatch = useDispatch();
  const today = moment().format('YYYY-MM-DD');
  const { currentCompany } = useSelector(state => state.auth);
  const { attendanceManagement } = currentCompany;
  const [graphData, setGraphData] = useState({
    licenses: 0,
    unjustified: 0,
    vacations: 0,
    administrative: 0,
    permissions: 0,
    assistants: 0,
    totals: 0
  });

  const unjustified = attendanceManagement ? graphData.unjustified : 0;
  const licenses = attendanceManagement ? graphData.licenses : 0;
  const assistants = attendanceManagement ? graphData.assistants : 0;
  const absencesTypes = [
    {
      title: 'Permisos',
      value: graphData.permissions,
      color: '#FB7474',
      percentage: graphData?.totals > 0 ? Math.ceil((graphData?.permissions / graphData?.totals) * 100) : 0
    },
    {
      title: 'Días Adm.',
      value: graphData.administrative,
      color: '#674ea7',
      percentage: graphData?.totals > 0 ? Math.ceil((graphData?.administrative / graphData?.totals) * 100) : 0
    },
    {
      title: 'Vacaciones',
      value: graphData.vacations,
      color: '#17A2B8',
      percentage: graphData?.totals > 0 ? Math.ceil((graphData?.vacations / graphData?.totals) * 100) : 0
    },
    {
      title: 'Licencias',
      value: licenses,
      color: '#29B48F',
      percentage: graphData?.totals > 0 ? Math.ceil((licenses / graphData?.totals) * 100) : 0
    },
    {
      title: 'Sin Marcación',
      value: unjustified,
      color: '#FFC107',
      percentage: graphData?.totals > 0 ? Math.ceil((unjustified / graphData?.totals) * 100) : 0
    },
    {
      title: 'Asistentes',
      value: assistants,
      color: '#FFC107',
      percentage: graphData?.assistants > 0 ? Math.ceil((assistants / graphData?.totals) * 100) : 0
    }
  ];
  const windowWidth = window.innerWidth;
  const className = windowWidth > 1900 ? 'mb-2 mt-2 circular-progress' : 'mb-2 mt-2 circular-progress';
  const viewBoxSize = windowWidth > 1900 ? [100, 100] : [100, 100];
  const totalAbsences = absencesTypes?.reduce((acc, el) => el?.value + acc, 0);
  const attendanceManagementTitles = ['Licencias', 'Injustificadas', 'Asistentes'];

  const handleAttendanceReportRequest = (format = '.print') => {
    debounceTodayAbsencesReport({
      dispatch,
      params: {
        filter_by_employee: employeesBelongBoss,
        date_range: [today, today]
      },
      format,
      successCallback: response => {
        const { data } = response;
        setGraphData({
          licenses: data.filter(asItem => ['Licencia Medica', 'Prenatal'].includes(asItem.records[0]?.justification))
            .length,
          unjustified: data.filter(asItem => asItem.records[0]?.justification === 'Ausencia Injustificada').length,
          vacations: data.filter(asItem => asItem.records[0]?.justification === 'Vacaciones').length,
          administrative: data.filter(asItem => asItem.records[0]?.justification === 'Administrativos').length,
          permissions:
            data.filter(asItem => asItem.records[0]?.justification === 'Permiso con goce de Remuneraciones').length +
            data.filter(asItem => asItem.records[0]?.justification === 'Permiso sin goce de Remuneraciones').length,
          assistants: data.filter(asItem => asItem.records[0]?.attendance === 'Si').length,
          totals: data.filter(asItem => asItem.records[0]?.attendance === 'No').length
        });
      },
      callback: () => setShowSpinnerToday(false)
    });
  };

  const handleAttendanceEffect = () => {
    if (successFetchEmployee) {
      if (employeesBelongBoss && employeesBelongBoss.length > 0) {
        handleAttendanceReportRequest();
      } else {
        setShowSpinnerToday(false);
      }
    }
  };
  useEffect(handleAttendanceEffect, [employeesBelongBoss, successFetchEmployee]);

  return (
    <Card className="mb-1">
      <Col className="top-header-green">
        <Col className="card-header-title">
          {attendanceManagement ? 'ASISTENCIAS/AUSENCIAS DE HOY' : 'AUSENCIAS DE HOY'}
        </Col>
      </Col>
      {showSpinnerToday === true ? (
        <Col className="mt-5">
          <div className="containerSpinnerLoadDash mb-5">
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <>
          <Col className="div-content">
            <Row style={{ paddingLeft: 20, height: 140 }}>
              <Col sm={12} md={4} xs={12} className={className} style={{ display: 'table' }}>
                <div
                  style={{ position: 'relative', textAlign: 'center', width: 100, height: 80 }}
                  className="mb-2 mt-2 circular-progress"
                >
                  <PieChart data={absencesTypes} lineWidth={15} viewBoxSize={viewBoxSize} animate />
                  <div
                    style={{
                      position: 'absolute',
                      fontSize: 20,
                      color: '#64bdb4g',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    {totalAbsences}
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} xs={12} className="mb-2 mt-2">
                {absencesTypes
                  ?.filter(el => !attendanceManagementTitles.includes(el.title))
                  .map(el => (
                    <div key={el.title}>
                      <p className="alert-p" style={{ marginBottom: '3px' }}>
                        <span className="dot" style={{ backgroundColor: el.color }} />{' '}
                        <b>
                          {el.value} {el.title}
                        </b>
                      </p>
                    </div>
                  ))}
                {attendanceManagement && (
                  <div>
                    {absencesTypes
                      ?.filter(el => attendanceManagementTitles.includes(el.title))
                      .map(el => (
                        <div key={el.title}>
                          <p className="alert-p" style={{ marginBottom: '3px' }}>
                            <span className={`dot ${el.title === 'Licencias' ? 'dot-green' : 'dot-warning'}`} />{' '}
                            <b>
                              {el.value} {el.title}
                            </b>
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </>
      )}
      <button
        type="button"
        onClick={handleMoreInfoButton}
        style={{
          border: '1px solid #65b68f',
          backgroundColor: '#65b68f',
          position: 'absolute',
          right: 20,
          bottom: 20
        }}
      >
        <Icon icon="search" height="25px" width="16px" color="white" />
      </button>
    </Card>
  );
};

export default TeamAbsencesToday;
