import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useSetTab } from '../../../services/hooks';
import { Icon } from '../../../components';
import AdvancePaymentIndex from '../AdvancePayment/AdvancePaymentIndex';
import LoanIndex from '../Loan/LoanIndex';
import VacationIndex from '../Vacation/VacationIndex';
import OvertimeIndex from '../Overtime/OvertimeIndex';
import PermissionIndex from '../Permission/PermissionIndex';
import ShiftChangeIndex from '../ShiftChange/ShiftChangeIndex';
import { AbilityContext } from '../../../config/abilityContext';
import OvertimeCompensationIndex from '../OvertimeCompensation/OvertimeCompensationIndex';

const RequestsIndex = () => {
  const ability = useAbility(AbilityContext);
  const { advanceSettings } = useSelector(state => state.utils);
  const { currentEmployee } = useSelector(state => state.auth);
  const location = useLocation();
  const [moreData, setMoreData] = useState(false);
  const vacationsTab = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const loanTab = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue === 'true');
  const advancePaymentsTab = advanceSettings
    .filter(asItem => asItem.code === 'advance_payment_request')
    .some(x => x.textValue === 'true');
  const permissionsTab = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave', 'administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const shiftChangeTab = advanceSettings
    .filter(asItem => asItem.code === 'shift_change')
    .some(x => x.textValue === 'true');

  const OvertimeTab = advanceSettings
    .filter(asItem => asItem.code === 'overtime_requests')
    .some(x => x.textValue === 'true');

  const overtimeCompensationVacationTab = advanceSettings
    .filter(asItem => asItem.code === 'overtime_compensation_vacation_request')
    .some(x => x.textValue === 'true');

  const defaultKey = () => {
    if (advancePaymentsTab) return 'advance_payments';
    if (loanTab) return 'loans';
    if (vacationsTab) return 'vacations';
    if (permissionsTab) return 'permissions';
    if (shiftChangeTab) return 'shift_change';
    if (OvertimeTab) return 'overtimes';
    return 'advance_payments';
  };

  const checkTab = () => {
    const tab = new URLSearchParams(location.search).get('tab');
    if (tab === 'advance_payments' && advancePaymentsTab) return 'advance_payments';
    if (tab === 'loans' && loanTab) return 'loans';
    if (tab === 'vacations' && vacationsTab) return 'vacations';
    if (tab === 'permissions' && permissionsTab) return 'permissions';
    if (tab === 'shift_change' && shiftChangeTab) return 'shift_change';
    if (tab === 'overtimes' && OvertimeTab) return 'overtimes';
    return defaultKey();
  };

  const requestsAbility = [
    ability.can('index', 'AdvancePayment'),
    ability.can('index', 'Permission'),
    ability.can('index', 'Vacation'),
    ability.can('index', 'Loan'),
    ability.can('index', 'ShiftChangeRequest'),
    ability.can('index', 'Overtime'),
    ability.can('index', 'OvertimeCompensation')
  ];

  const [key, setKey] = useSetTab(defaultKey(), location);

  const updateDefaultKey = () => setKey(checkTab());

  useEffect(updateDefaultKey, [advanceSettings]);
  return (
    <>
      <h2 className="m-top mb-3">Mis Solicitudes</h2>
      <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {advancePaymentsTab && requestsAbility[0] && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="advance_payments">
                <Icon icon="user-check" />
                Anticipos
              </Nav.Link>
            </Nav.Item>
          )}
          {loanTab && requestsAbility[3] && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="loans">
                <Icon icon="wallet" />
                Préstamos
              </Nav.Link>
            </Nav.Item>
          )}
          {vacationsTab && requestsAbility[2] && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="vacations">
                <Icon icon="sunny" />
                Vacaciones
              </Nav.Link>
            </Nav.Item>
          )}
          {permissionsTab && requestsAbility[1] && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="permissions">
                <Icon icon="user-check" />
                Permisos
              </Nav.Link>
            </Nav.Item>
          )}
          {shiftChangeTab && requestsAbility[4] && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="shift_change">
                <Icon icon="calendar" />
                Cambio de Turno
              </Nav.Link>
            </Nav.Item>
          )}
          {OvertimeTab && requestsAbility[5] && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="overtimes">
                <Icon icon="user-check" />
                Horas Extras
              </Nav.Link>
            </Nav.Item>
          )}
          {overtimeCompensationVacationTab && requestsAbility[6] && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="overtime_compensation">
                <Icon icon="clock2" />
                Días por Horas Extras
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="advance_payments">
            {advancePaymentsTab && requestsAbility[0] && (
              <AdvancePaymentIndex currentEmployee={currentEmployee} moreData={moreData} setMoreData={setMoreData} />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="loans">
            {loanTab && requestsAbility[3] && <LoanIndex moreData={moreData} setMoreData={setMoreData} rrhh={false} />}
          </Tab.Pane>
          <Tab.Pane eventKey="vacations">
            {vacationsTab && requestsAbility[2] && <VacationIndex currentEmployee={currentEmployee} />}
          </Tab.Pane>
          <Tab.Pane eventKey="permissions">
            {permissionsTab && requestsAbility[1] && <PermissionIndex currentEmployee={currentEmployee} />}
          </Tab.Pane>
          <Tab.Pane eventKey="shift_change">
            {shiftChangeTab && requestsAbility[4] && (
              <ShiftChangeIndex moreData={moreData} setMoreData={setMoreData} currentEmployee={currentEmployee} />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="overtimes">
            {OvertimeTab && requestsAbility[5] && (
              <OvertimeIndex moreData={moreData} setMoreData={setMoreData} currentEmployee={currentEmployee} />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="overtime_compensation">
            {overtimeCompensationVacationTab && requestsAbility[6] && (
              <OvertimeCompensationIndex
                currentEmployee={currentEmployee}
                moreData={moreData}
                setMoreData={setMoreData}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default RequestsIndex;
