import React from 'react';
import { useDispatch } from 'react-redux';
import { indexEmployeesRequest } from '../../../requests/employees';

const PrintFunctions = () => {
  const dispatch = useDispatch();

  const getEmployeeIds = async params => {
    return new Promise(res => {
      indexEmployeesRequest({
        dispatch,
        params: {
          ...params,
          paginate: false,
          active: true,
          is_dt: false,
          filter_work_schedules: 'full_time,part_time,article_22',
          sort_direction: 'asc',
          sort_column: 'name'
        },
        responseType: 'json',
        successCallback: response => {
          const { data } = response;
          res(data.data);
        },
        callback: () => res([])
      });
    });
  };

  const getEmployeeInfo = employee => {
    return (
      <>
        <p style={{ marginTop: '12px' }}>{employee.full_name}</p>
        <p>RUT: {employee.national_identification}</p>
        <p>Lugar de Prestación de Servicio: {employee.branch_offices_name}</p>
        <p>Banda Horaria: {employee.is_article_27}</p>
      </>
    );
  };

  const getCompanyInfo = data => {
    return (
      <>
        <p>Empresa: {data.company_name}</p>
        <p>RUT: {data.company_national_id}</p>
        <p>
          Rango seleccionado de fechas: {data.date_from} - {data.date_to}
        </p>
      </>
    );
  };

  return {
    getEmployeeIds,
    getCompanyInfo,
    getEmployeeInfo
  };
};

export default PrintFunctions;
