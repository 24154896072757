import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Spinner } from 'react-bootstrap';
import { legalreportOffdaysReport } from '../../requests/legalreports';
import AttendanceProofRender from "./AttendanceProofRender";

const OffdaysFilterDataTable = ({ customParams }) => {
  const [offdays, setOffdays] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();

  const styleWihtOutShift = {
    backgroundColor: 'rgba(255, 160, 122, 0.9)',
    color: 'white'
  };

  const styleOutShift = {
    backgroundColor: 'rgba(248, 148, 6, 0.9)',
    color: 'white'
  };

  const totalsStyle = {
    backgroundColor: '#e6e6e6'
  };

  const handleSuccessIndex = response => {
    setOffdays(response.data);
    setOnRequest(false);
  };

  const handleOffdayReportRequest = () => {
    if (offdays.length > 0) {
      return;
    }
    setOnRequest(true);
    const request = async () =>
      legalreportOffdaysReport({
        dispatch,
        params: { ...customParams },
        successCallback: handleSuccessIndex
      });
    request();
  };

  useEffect(handleOffdayReportRequest, [customParams.filter_by_employee]);

  const renderResult = offday => {
    let styles = null;
    if (offday.observation && offday.observation.includes('Marcación fuera de turno')) {
      styles = styleOutShift;
    }

    if (offday.observation && offday.observation.includes('No tiene turno asignado')) {
      styles = styleWihtOutShift;
    }

    if (offday.default_no.includes('Total')) {
      styles = totalsStyle;
    }

    return (
      <tr style={styles}>
        <td className="text-center align-middle">{offday.default_no}</td>
        <td className="text-center align-middle">{offday.date}</td>
        <td className="text-center align-middle">{offday.attendance}</td>
        <td className="text-center align-middle">{offday.absence}</td>
        <td className="text-center align-middle">
          {offday.observation}
          {<AttendanceProofRender item={{ ...offday, justification: '' }} />}
        </td>
      </tr>
    );
  };

  const tableHeader = () => {
    return ['Descanso Dominical Adicional', 'FECHA', 'ASISTENCIA', 'AUSENCIA', 'OBSERVACIONES'];
  };

  const renderData = () => {
    if (onRequest) {
      return (
        <div className="containerSpinnerLoad position-absolute h-100 w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }

    if (offdays.length === 0) {
      return (
        <div className="text-center pt-3 pb-3">
          <p className="text-muted" style={{ fontFamily: 'Arial', fontSize: '8px' }}>
            La jornada de este trabajador no incluye domingo y Festivos
          </p>
        </div>
      );
    }

    return (
      <>
        <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
          <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
            <tr className="rdt_TableCol">
              {tableHeader().map(itemHeader => (
                <th className="text-center align-middle" key={itemHeader}>
                  {itemHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{offdays.map(offday => renderResult(offday))}</tbody>
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default OffdaysFilterDataTable;
