import React from 'react';
import memoize from 'memoize-one';
import { ActiveDot, DatatableEmployeeName, Icon, BasicDropdown } from '../../components';

const items = (clickHandler, item, employeeMenu, showEditButton) => {
  const actions = [];

  if (employeeMenu[1]) {
    actions.push({
      key: 'send_activation_email',
      title: 'Enviar correo de enrolamiento',
      icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'send_activation_email'),
        disabled: item.enrolled
      }
    });
  }

  if (employeeMenu[2]) {
    actions.push({
      key: 'show',
      title: `Ver`,
      icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'show')
      }
    });
  }
  if (employeeMenu[3] && showEditButton) {
    actions.push({
      key: 'edit',
      title: `Editar`,
      icon: <Icon icon="pencil" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'edit')
      }
    });
  }
  if (employeeMenu[5]) {
    actions.push({
      key: 'archive',
      title: item.active ? 'Desactivar' : 'Activar',
      icon: <Icon icon="archive" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'archive')
      }
    });
  }
  if (employeeMenu[4]) {
    actions.push({
      key: 'destroy',
      title: 'Eliminar',
      icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,

      props: {
        onClick: () => clickHandler(item, 'destroy'),
        disabled: item.enrolled_or_contract
      }
    });
  }

  return actions;
};

const columns = memoize((clickHandler, employeeMenu, isMobile, showEditButton) => {
  if (isMobile) {
    return [
      {
        name: 'Nº',
        selector: 'correlative_identification',
        sortable: true,
        grow: '0.1',
        center: true
      },
      {
        name: 'RUT Y NOMBRE',
        selector: 'name',
        cell: item => <DatatableEmployeeName item={item} onClick={() => clickHandler(item, 'show')} />,
        sortable: true,
        minWidth: '185px',
        grow: '0.1'
      }
    ];
  }
  return [
    {
      name: 'Nº',
      selector: 'correlative_identification',
      sortable: true,
      grow: '0.1',
      center: true
    },
    {
      name: 'RUT Y NOMBRE',
      selector: 'name',
      cell: item => <DatatableEmployeeName item={item} onClick={() => clickHandler(item, 'show')} />,
      sortable: true,
      minWidth: '185px',
      grow: '2'
    },
    {
      name: 'ÁREA',
      selector: 'job_management',
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'CARGO',
      selector: 'job_title',
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'INICIO CONTRATO',
      selector: 'start_date',
      cell: item => (item.start_date ? item.start_date : item.last_contract_start_date),
      sortable: true,
      grow: '0.5',
      center: true
    },
    {
      name: 'FIN CONTRATO',
      selector: 'end_date',
      cell: item => (item.end_date ? item.end_date : item.last_contract_end_date),
      sortable: true,
      grow: '0.5',
      center: true
    },
    {
      name: 'ESTADO',
      selector: 'collaborator',
      cell: item => (item.collaborator ? <ActiveDot item={item} type="text" /> : ''),
      sortable: true,
      grow: '0.5',
      center: true,
      ignoreRowClick: true
    },
    {
      name: 'ACCIONES',
      cell: item => (
        <>
          {employeeMenu.some(row => row) && (
            <BasicDropdown
              variant="outline-primary"
              noArrow
              block
              right
              titleDrop={
                <span className="align-middle">
                  Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
                </span>
              }
              items={items(clickHandler, item, employeeMenu, showEditButton)}
            />
          )}
        </>
      ),
      ignoreRowClick: true,
      grow: '1',
      minWidth: '140px',
      center: true
    }
  ];
});

export default columns;
