import ApiService from '../services/apiService';

export const fetchOvertimeCompensationsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/overtime_compensations', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createOvertimeCompensationRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/overtime_compensations', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const exportOvertimeCompensationRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/overtime_compensations/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const deleteOvertimeCompensationRequest = ({ dispatch, id, failureCallback, successCallback }) => {
  ApiService.request('delete', `/overtime_compensations/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const preImportOvertimeCompensationRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/overtime_compensations/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importOvertimeCompensationRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/overtime_compensations/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateOvertimeCompensationRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/overtime_compensations/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};
