import React, { useEffect } from 'react'
import DailyAttendanceDataTable from './DailyAttendanceDataTable';

const DailyAttendanceIndex = ({ rrhh }) => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <DailyAttendanceDataTable rrhh={rrhh} />
    </>
  );
}

export default DailyAttendanceIndex
