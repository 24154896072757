import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';

import { FormikRangePicker } from '../../../components';
import { workdaysBetweenHolidayRequest } from '../../../requests/holidays';

function OvertimeCompensationForm(props) {
  const dispatch = useDispatch();
  const { errors, isSubmitting, setFieldValue, touched, values, availableDays } = props;
  const { startDate, endDate } = values.overtimeCompensation;

  useEffect(() => {
    if (startDate && endDate) {
      workdaysBetweenHolidayRequest({
        dispatch,
        params: {
          start_date: startDate,
          end_date: endDate
        },
        successCallback: response => {
          const totalDays = response.data;
          setFieldValue('overtimeCompensation.totalDays', totalDays > 0 ? totalDays : 0);
        },
        errorCallback: error => {
          console.error('Error calculando los días hábiles:', error);
          setFieldValue('overtimeCompensation.totalDays', 0);
        }
      });
    } else {
      setFieldValue('overtimeCompensation.totalDays', 0);
    }
  }, [dispatch, startDate, endDate, setFieldValue]);

  return (
    <Form>
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-3">
        <Col md={6}>
          <div className="mb-3">
            <strong>Cantidad de Días disponibles:</strong> {availableDays?.days || 0} días,{' '}
            {availableDays?.hours?.toFixed(1) || 0} horas
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={10}>
          <Field name="rangeDate">
            {({ field }) => (
              <FormikRangePicker
                name={field.name}
                abbr
                minCurrentDate={false}
                startDateName="overtimeCompensation.startDate"
                endDateName="overtimeCompensation.endDate"
                startDate={startDate}
                endDate={endDate}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col md={2}>
          <Field name="overtimeCompensation.totalDays">
            {({ field, form }) => (
              <div className="form-group mb-5">
                <label htmlFor="overtimeCompensation-totalDays">
                  Total Días
                  <input
                    id="overtimeCompensation-totalDays"
                    type="number"
                    className={`form-control ${getIn(form.errors, field.name) ? 'is-invalid' : ''}`}
                    disabled
                    value={values.overtimeCompensation.totalDays || 0}
                    {...field}
                  />
                </label>
                {getIn(form.errors, field.name) && (
                  <div className="invalid-feedback">{getIn(form.errors, field.name)}</div>
                )}
              </div>
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mt-4">
        <Col md={4}>
          <Button block type="submit" variant="primary" disabled={isSubmitting}>
            Solicitar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const setInitialValues = () => ({
  overtimeCompensation: {
    startDate: '',
    endDate: '',
    totalDays: 0
  },
  rangeDate: []
});

const validationSchema = Yup.object().shape({
  rangeDate: Yup.array().rangedate(true),
  overtimeCompensation: Yup.object().shape({
    startDate: Yup.date().formatdate(),
    endDate: Yup.date().formatdate(),
    totalDays: Yup.number().test(
      'is-valid-total-days',
      'El total de días debe ser menor o igual a los días disponibles',
      function isValidTotalDays(value) {
        const { availableDays } = this.options.context || {};
        return !availableDays || value <= availableDays.days;
      }
    )
  })
});

const validate = (values, props) => {
  const errors = {};
  const { totalDays } = values.overtimeCompensation;
  const availableDays = props.availableDays?.days || 0;
  if (totalDays > availableDays) {
    errors.overtimeCompensation = {
      totalDays: 'El total de días debe ser menor o igual a los días disponibles'
    };
  }
  return errors;
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  setSubmitting(true);
  formRequest(values.overtimeCompensation, setSubmitting);
};

const OvertimeCompensationNew = withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  validate,
  handleSubmit,
  enableReinitialize: true
})(OvertimeCompensationForm);

export default OvertimeCompensationNew;
