import React, { useState } from 'react';
import { ButtonTooltip } from '../Utils/Tooltips';
import AttendanceProof from './AttendanceProof';
import Icon from '../Icons';
import { SimpleCenteredModal } from '..';

const styleButtonTooltip = {
  width: 'auto',
  height: 'auto',
  borderRadius: '10%',
  display: 'initial'
};

const renderObservation = observation => (
  <div dangerouslySetInnerHTML={{ __html: observation.replace(/\n/g, '<br>') }} />
);

const AttendanceProofRender = ({ item }) => {
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const attendanceProof = item.attendance_proof;

  return (
    <div style={{ display: 'block' }}>
      {attendanceProof && (
        <div style={{ marginBottom: '10px' }}>
          <ButtonTooltip
            onClick={() => {
              setDocumentModalBody(<AttendanceProof data={attendanceProof} />);
              setDocumentModalShow(true);
            }}
            variant="circle-info"
            className="arial-dt"
            style={styleButtonTooltip}
            toolbarVariant="justify-content-center"
            size="sm"
            text="Ver Permiso"
            disabledTooltip
          >
            <Icon icon="profile" /> Ver Comprovante Marcación
          </ButtonTooltip>
        </div>
      )}

      <div>{renderObservation(item.justification)}</div>

      <SimpleCenteredModal
        body={documentModalBody}
        onHide={() => setDocumentModalShow(false)}
        show={documentModalShow}
      />
    </div>
  );
};

export default AttendanceProofRender;
