import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { SimpleCenteredModal } from '../../../components';
import OvertimeCompensationDatatable from './OvertimeCompensationDatatable';
import { OvertimeSummaryCard, OvertimeEquivalenceCard } from './OvertimeCompensationCards';
import OvertimeCompensationNew from './OvertimeCompensationNew';
import columns from './Columns';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import {
  createOvertimeCompensationRequest,
  fetchOvertimeCompensationsRequest
} from '../../../requests/overtimeCompensations';

const OvertimeCompensationIndex = ({ currentEmployee, moreData, setMoreData }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [overtimeData, setOvertimeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalRemainingBalance, setTotalRemainingBalance] = useState(0);
  const [overtimeEquivalence, setOvertimeEquivalence] = useState({ days: 0, hours: 0, totalOvertime: 0 });

  const dispatch = useDispatch();

  const handleModalClose = () => setModalShow(false);
  const dailyWorkingHours = overtimeData?.data?.[0]?.dailyWorkingHours;

  const fetchDashboardCards = params => {
    setLoading(true);
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const sixMonthsAgo = new Date(firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() - 6));
    const formattedDateFrom = sixMonthsAgo.toISOString().split('T')[0];

    const sendParams = {
      ...params,
      filter_employee: currentEmployee,
      date_from: formattedDateFrom
    };
    fetchOvertimeCompensationsRequest({
      dispatch,
      params: sendParams,
      successCallback: response => {
        const formattedData = camelCaseEmptyStringRecursive(response.data);
        setOvertimeData(formattedData);
        setLoading(false);
      },
      failureCallback: () => {
        setLoading(false);
      }
    });
  };

  useEffect(fetchDashboardCards, [moreData]);

  const handleCreateCompensation = (compensation, setSubmitting) => {
    createOvertimeCompensationRequest({
      dispatch,
      params: { overtime_compensation: compensation },
      successCallback: () => {
        setSubmitting(false);
        setMoreData(!moreData);
        setModalShow(false);
      },
      failureCallback: error => {
        console.error('Error al crear la solicitud:', error);
        setSubmitting(false);
      }
    });
  };

  const handleOpenModal = () => {
    setModalTitle('Solicitar Días por Horas Extras');
    setModalBody(
      <OvertimeCompensationNew
        formRequest={handleCreateCompensation}
        totalRemainingBalance={totalRemainingBalance}
        dailyWorkingHours={dailyWorkingHours}
        availableDays={overtimeEquivalence}
      />
    );
    setModalShow(true);
  };

  useEffect(() => {
    if (overtimeData?.data) {
      const total = overtimeData.data.reduce((sum, row) => sum + (parseFloat(row.remainingExtraHours) || 0), 0);
      setTotalRemainingBalance(total);

      const extraHoursPerDay = dailyWorkingHours / 1.5;
      const days = Math.floor(total / extraHoursPerDay);
      const hours = total % extraHoursPerDay;

      setOvertimeEquivalence({ days, hours, totalOvertime: total });
    }
  }, [overtimeData, dailyWorkingHours]);

  return (
    <Row className="mt-2 mb-3">
      <Col>
        <Row>
          <Col md={{ span: 3, offset: 9 }}>
            <Button variant="primary" className="d-none d-md-block mt-0" block onClick={handleOpenModal}>
              Nuevo
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 overtime-container align-items-start">
          <Col md={8}>
            {!loading && overtimeData?.data && (
              <OvertimeSummaryCard overtimeData={overtimeData.data} totalRemainingBalance={totalRemainingBalance} />
            )}
          </Col>
          <Col md={4} className="d-flex flex-column">
            {!loading && overtimeData?.data && (
              <OvertimeEquivalenceCard overtimeData={overtimeData.data} overtimeEquivalence={overtimeEquivalence} />
            )}
          </Col>
        </Row>

        <OvertimeCompensationDatatable
          columns={columns}
          moreData={moreData}
          setMoreData={setMoreData}
          currentEmployee={currentEmployee}
        />
        <SimpleCenteredModal title={modalTitle} size="lg" body={modalBody} show={modalShow} onHide={handleModalClose} />
      </Col>
    </Row>
  );
};

export default OvertimeCompensationIndex;
