export default {
  active: true,
  address: '',
  addressApt: '',
  addressNumber: '',
  birthdate: '',
  careerIds: [],
  careers: [],
  civilState: '',
  collaborator: true,
  communeId: '',
  communeName: '',
  company: {},
  companyId: '',
  correlativeIdentification: '',
  countryId: '',
  educationLevel: '',
  email: '',
  emergencyContactCountryCode: '+56',
  emergencyContactName: '',
  emergencyContactPhone: '',
  emergencyContactRelation: '',
  employeeCard: {
    fileInfo: {}
  },
  fileInfo: {},
  firstLastName: '',
  fullName: '',
  genderId: '',
  id: '',
  identificationType: '',
  languageIds: [],
  languages: [],
  name: '',
  nationalIdExpirationDate: '',
  phone: '',
  phoneCountryCode: '+56',
  postTitle: '',
  profileIds: [],
  profiles: [],
  secondLastName: '',
  senceLevel: '',
  signSubstituteId: '',
  singSubstitute: {},
  userId: '',
  workEmail: '',
  workPhone: '',
  workPhoneCountryCode: '+56',
  contract: {
    acumulativeAdditionalHolidays: false,
    additionalHolidays: '',
    additionalHolidaysStartDate: '',
    administrativeStartDate: '',
    acumulativeAdministrative: false,
    administrativeDays: '',
    bankAccount: '',
    bankAccountType: '',
    bankId: '',
    branchOffices: [],
    branchOfficeIds: [],
    checkAssistance: false,
    vacationCalculationArticle67: false,
    workScheduleArt27: false,
    dailyWorkingHours: '',
    telecommuting: false,
    contractBalancesAttributes: [],
    contractBalancesRetentionsAttributes: [],
    contractRetentionsAttributes: [],
    contractType: '',
    costCenterId: '',
    disability: '',
    disabilityCertificate: {},
    disabilityFileInfo: {},
    disabilityNationalIdentification: '',
    documentTemplateId: '',
    endDate: '',
    friday: false,
    familyGroupsAttributes: [],
    gratificationCondition: '',
    gratificationPeriod: '',
    gratificationType: '',
    jacketSize: '',
    jobManagementId: '',
    jobTitleId: '',
    markingType: '',
    monday: false,
    outSourced: false,
    outSourceAddress: '',
    outSourceAddressFlat: '',
    outSourceAddressNumber: '',
    outSourceAddressOffice: '',
    outSourceBusinessName: '',
    outSourceCommuneId: '',
    outSourceEstablishmentName: '',
    outSourceExceptionalSystem: false,
    outSourceExceptionalSystemNumber: '',
    outSourceNationalIdentification: '',
    outSourceRegionId: '',
    pantsSize: '',
    parentId: '',
    payFirstLicenseDays: false,
    paymentSchedule: '',
    paymentType: '',
    presenceCheckAssistance: false,
    progressiveFileInfo: {},
    progressiveVacationsCertificate: {},
    recognizedMonths: '',
    recognizedMonthsFrom: '',
    salary: '',
    saturday: false,
    shiftIds: [],
    shirtSize: '',
    shoesSize: '',
    startDate: '',
    sunday: false,
    syndicateId: '',
    tShirtSize: '',
    thursday: false,
    tuesday: false,
    wednesday: false,
    weeklyHours: '',
    workEndTime: '18:00',
    workSchedule: '',
    workStartTime: '09:00',
    workerQuality: '',
    employeePrevisionAttributes: {
      agreedQuantity: '0',
      firstAgreedAmount: '0',
      secondAgreedAmount: '0',
      familyAllowanceSection: 'no_family_allowance',
      healthPrevisionId: '',
      heavyLaborAdditional: 'no_heavy_labor',
      heavyLaborStation: '',
      ineCode: '',
      ipsType: '',
      pensionFundId: '',
      pensionScheme: '',
      pensionSchemeRate: '',
      evictionContributionRate: '',
      pensioner: '',
      unemploymentInsurance: true,
      unemploymentInsuranceReason: '',
      unitOfAccount: '',
      voluntaryPrevisionsAttributes: [],
      youthAllowance: false
    }
  },
  employeeCertificationsAttributes: []
};
